import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CrossIcon from 'components/Icons/gen/Cross';
import CheckIcon from 'components/Icons/gen/Check';
import Player from '../../../Player';

const useStyles = makeStyles((theme) => ({
  inputComponent: {
    border: 'none',
    height: '33px',
    outline: 'none',
    '&:before': {
      content: '""',
      width: 0,
    },
    background: '#EDF0F2',
    borderRadius: theme.spacing(0.5),
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: 'Outfit,sans-serif',
    color: '#353535',
    textAlign: 'center',
    padding: 0,
    width: '170px',
  },
  select: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  selectIcon: {
    color: theme.palette.primary.main,
  },
  inputContainer: {
    background: '#EDF0F2',
    borderRadius: 3,
    height: '33px',
    display: 'inline-flex',
    alignItems: 'baseline',
    margin: '4px 8px',
  },
  answerContainer: {
    background: '#EDF0F2',
    borderRadius: 3,
    // height: '33px',
    display: 'inline-flex',
    alignItems: 'baseline',
    margin: '4px 8px',
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  answer: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    fontSize: '18px',
    fontWeight: 600,
    width: '140px',
  },
  wrongAnswer: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#FF3C3C',
    // width: '140px',
  },
  itemText: {
    lineHeight: 2,
    fontSize: '18px',
    fontWeight: 400,
    color: '#353535',
  },
  playerContainer: {
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
}));

const ListeningFillInTheBlanks = ({
  t,
  question,
  onAnswered,
  answer,
  questionAnswer,
  submittedQuestion,
  mockAnswer,
  useragent,
  pausePlay,
  mock,
  playerStartAfter,
  showStartTimer,
  setIsStopNextQuestion,
  resultComponent
}) => {
  const classes = useStyles();
  const [practiceAnswer, setAnswer] = useState(answer || []);
  const [startTimer, setStartTimer] = useState(false);
  const [waitingForPlayer, setWaitingForPlayer] = useState(true);
  const [splitted, setSplitted] = useState([]);
  const theme = useTheme();

  const onAnswerChange = (index, e) => {
    const { value } = e.target;
    const preAnswer = [...practiceAnswer];
    preAnswer[index] = value;
    setAnswer(preAnswer);
  };

  useEffect(() => {
    const filledAnswer = [];
    if (splitted) {
      for (let i = 0; i < splitted.length - 1; i++) {
        if (!practiceAnswer[i]) filledAnswer.push('');
        else filledAnswer.push(practiceAnswer[i].toLowerCase());
      }
    }
    filledAnswer.splice(6, 1);
    onAnswered(filledAnswer);
  }, [practiceAnswer]);

  useEffect(() => {
    if (mockAnswer && answer && answer.length > 0) setAnswer(answer);
  }, [answer]);

  useEffect(() => {
    const value = [];
    question.question_data.text.split('<blank/>').forEach((item) => {
      value.push(item);
    });
    setSplitted(value);
  }, [question]);
  return (
    <Grid item xs={12}>
      <Grid item lg={5} md={6} sm={12} xs={12} style={{ marginBottom: '15px' }} className={!mockAnswer ? classes.playerContainer : null}>
        <Player
          currentStatus={true}
          setIsStopNextQuestion={setIsStopNextQuestion}
          isAnswer={false}
          t={t}
          src={question.question_media ? Object.values(question.question_media.audio)[Object.values(question.question_media.audio).length - 1] : null}
          autoplayAfter={playerStartAfter}
          onPlayStart={() => { setWaitingForPlayer(false); }}
          onPlayFinish={() => { setStartTimer(true); }}
          pausePlay={pausePlay}
          useragent={useragent}
          mock={mock}
          showStartTimer={showStartTimer}
          justifyContentPlayer={resultComponent ? 'start' : null}
        />
      </Grid>

      {splitted.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Typography variant="body1" display="inline" className={classes.itemText}>{item}</Typography>
            {index !== splitted.length - 1 && (
              <span className={classes.inputContainer}>
                {submittedQuestion &&
                  (answer &&
                    questionAnswer &&
                    questionAnswer['acceptable-answers'] &&
                    questionAnswer['acceptable-answers'].data &&
                    questionAnswer['acceptable-answers'].data[0] &&
                    answer[index]?.replace(/[^\w\s]/gi, '').toLowerCase() === questionAnswer['acceptable-answers'].data[0].answer[index]?.replace(/[^\w\s]/gi, '').toLowerCase() ?

                    <CheckIcon color={theme.palette.primary.main} className={classes.icon} />
                    :
                    <CrossIcon color="#f00" className={classes.icon} />
                  )}
                {submittedQuestion ? (
                  <span
                    className={clsx(classes.answerContainer, {
                      [classes.answer]:
                        questionAnswer &&
                        questionAnswer['acceptable-answers'] &&
                        questionAnswer['acceptable-answers'].data &&
                        questionAnswer['acceptable-answers'].data[0] &&
                        answer[index]
                          ?.replace(/[^\w\s]/gi, '')
                          .toLowerCase() ===
                        questionAnswer['acceptable-answers'].data[0].answer[
                          index
                        ]
                          ?.replace(/[^\w\s]/gi, '')
                          .toLowerCase(),
                      [classes.wrongAnswer]:
                        questionAnswer &&
                        questionAnswer['acceptable-answers'] &&
                        questionAnswer['acceptable-answers'].data &&
                        questionAnswer['acceptable-answers'].data[0] &&
                        answer[index]
                          ?.replace(/[^\w\s]/gi, '')
                          .toLowerCase() !==
                        questionAnswer['acceptable-answers'].data[0].answer[
                          index
                        ]
                          ?.replace(/[^\w\s]/gi, '')
                          .toLowerCase(),
                    })}>
                    {practiceAnswer[index] || '---'}
                  </span>
                ) : (
                  <input
                    value={practiceAnswer[index]}
                    onChange={(e) => onAnswerChange(index, e)}
                    className={clsx(classes.inputComponent, {
                      [classes.answer]:
                        questionAnswer &&
                        questionAnswer['acceptable-answers'] &&
                        questionAnswer['acceptable-answers'].data &&
                        questionAnswer['acceptable-answers'].data[0] &&
                        answer[index]
                          ?.replace(/[^\w\s]/gi, '')
                          .toLowerCase() ===
                        questionAnswer['acceptable-answers'].data[0].answer[
                          index
                        ]
                          ?.replace(/[^\w\s]/gi, '')
                          .toLowerCase(),
                      [classes.wrongAnswer]:
                        questionAnswer &&
                        questionAnswer['acceptable-answers'] &&
                        questionAnswer['acceptable-answers'].data &&
                        questionAnswer['acceptable-answers'].data[0] &&
                        answer[index]
                          ?.replace(/[^\w\s]/gi, '')
                          .toLowerCase() !==
                        questionAnswer['acceptable-answers'].data[0].answer[
                          index
                        ]
                          ?.replace(/[^\w\s]/gi, '')
                          .toLowerCase(),
                    })}
                    disabled={submittedQuestion}
                  />
                )}
              </span>
            )}
            {submittedQuestion && (
              <Typography
                className={classes.answer}
                variant="body1"
                color="primary"
                display="inline">
                {questionAnswer &&
                  questionAnswer['acceptable-answers'] &&
                  questionAnswer['acceptable-answers'].data &&
                  questionAnswer['acceptable-answers'].data[0] &&
                  questionAnswer['acceptable-answers'].data[0].answer[index]}
              </Typography>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

ListeningFillInTheBlanks.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_type: PropTypes.string.isRequired,
    question_media: PropTypes.any,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.array,
  submittedQuestion: PropTypes.bool.isRequired,
  questionAnswer: PropTypes.shape(),
  mockAnswer: PropTypes.bool,
  useragent: PropTypes.object,
  pausePlay: PropTypes.bool,
  mock: PropTypes.bool,
};
ListeningFillInTheBlanks.defaultProps = {
  answer: [],
  questionAnswer: null,
  mockAnswer: false,
  useragent: {},
  pausePlay: false,
  mock: false,
};

export default ListeningFillInTheBlanks;
