import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import noStudyPlan from '../../assets/images/noStudyPlan.svg';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '20px 0px',
    width: '100%',
  },
  text: {
    color: '#303030',
    fontWeight: 500,
    fontSize: 16,
  },
}));

const NoStudyPlan = () => {
  const classes = useStyle();

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.root}>
        <Grid container item xs={3}>
          <img src={noStudyPlan} style={{width: '100%'}} />
        </Grid>
        <Grid>
          <Typography variant="subtitle1" className={classes.text}>
            You do not have any study plan yet
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default NoStudyPlan;
