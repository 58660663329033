import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setShowupModalAction } from 'core/lib/adapters/redux/actions';
import { useTranslation } from 'react-i18next';
import { getProfile } from 'core/lib/adapters/redux/actions/authActions';

const useStepLoadingStyles = makeStyles(() => ({
  barContainer: {
    width: '100%',
    // height: '10px',
    // borderRadius: '4px',
    background: '#C4C4C4',
  },
  bar: {
    height: '10px',
    borderRadius: '4px',
    animation: `$myEffect 6s ease-in-out`,
    background: '#0F4C8F',
  },
  text: {
    marginTop: '10px',
    textAlign: 'center',
  },
  full: {
    height: '300px',
  },
  '@keyframes myEffect': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  },
}));

function StepLoading({ closeModal }) {
  const { t } = useTranslation();
  const classes = useStepLoadingStyles();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(0);

  const studyPlan = useSelector((state) => state.studyPlan.studyPlan);
  const isSuccess = useSelector((state) => state.studyPlan.isSuccess);
  const studyPlanError = useSelector((state) => state.studyPlan.error);
  const studyPlanErrorMessage = useSelector(
    (state) => state.studyPlan.errorMessage,
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [studyPlan]);

  useEffect(() => {
    if (studyPlanError || studyPlanErrorMessage) {
      enqueueSnackbar(studyPlanErrorMessage, {
        variant: 'error',
      });
      dispatch(setShowupModalAction({ statuse: false }));
      dispatch({ type: 'RESET_STUDY_PLAN' });
      closeModal(1);
    }
  }, [studyPlanErrorMessage, studyPlanError]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t('notif:studyPlanCreatedSuccessfully'), {
        variant: 'success',
      });
      dispatch(setShowupModalAction({ statuse: false }));
      dispatch(getProfile());
      dispatch({ type: 'RESET_STUDY_PLAN' });
      closeModal(0);
    }
  }, [isSuccess]);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.full}>
      <Grid item>
        <div className={classes.barContainer}>
          {/* <div className={classes.bar}></div> */}
          <LinearProgress variant="determinate" value={progress} />
        </div>
        <div className={classes.text}>Preparing your study plan</div>
      </Grid>
    </Grid>
  );
}

export default StepLoading;
