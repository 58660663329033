import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {useRouter} from 'next/router';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Practice from './practice';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  weekSelect: {
    width: '93px',
    height: '31px',
    color: '#767676',
    fontSize: '14px',
    fontWeight: 600,
    background: '#F4F4F4',
    border: 'none',
    outline: 'none',
  },

  mobileRoot: {
    padding: '20px 0px',
    width: '100%',
    overflowX: 'auto',
    background: '#fff',
  },

  skillContainerMobile: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
  skillBox: {
    padding: '10px 0px',
    border: '1px solid #E5E5E5',
    cursor: 'pointer',
    fontSize: 13,
    width: '22%',
    display: 'flex',
  },

  skillBoxMobile: {
    padding: '10px 10px',
    border: '1px solid #E5E5E5',
    // marginRight: 8,
    cursor: 'pointer',
    fontSize: 14,
    width: "22%"
  },

  suggestedText: {
    color: '#808080',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
  },
  gotoOtherPage: {
    color: '#0F4C8F',
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 600,
  },
  studyPlanHeader: {
    backgroundColor: '#fff',
    padding: '35px 10px',
    borderTop: '2px solid #0F4C8F',
  },
  mainBoxTitle: {
    fontWeight: 800,
    fontSize: 17,
    color: '#303030',
  },
}));

const PracticeTab = ({useragent, isInDashboard = false}) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState('Training');
  const [page, setPage] = useState(1);
  const [userQuery, setUserQuery] = useState('');
  const [week, setWeek] = useState(1);

  const [activeSkill, setActiveSkill] = useState(0);

  const {questions, sessions, userSessions} = useSelector((state) => ({
    questions: state.questions.questionList.webList,
    sessions: state.session.sessions,
    userSessions: state.session.userSessions,
  }));

  const changeActiveTabHandler = (event, newValue, useragentStatuse) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      setActiveTabName('Training');
    }
    if (newValue === 1) {
      setActiveTabName('Practice Pathway');
    }
  };

  const getQuestionListHandler = () => {
    if (activeTabName === 'Training') {
    } else if (activeTabName === 'Practice Pathway') {
    }
  };

  const redirectToNewPage = (e, href) => {
    e.preventDefault();
    router.push(href);
  };

  const Range = (start, end) => {
    const list = [];
    for (let i = start; i <= end; i++) {
      list.push(i);
    }
    return list;
  };

  useEffect(() => {
    getQuestionListHandler();
  }, [page, activeTabName, userQuery]);

  useEffect(() => {
    changeActiveTabHandler('e', 0, useragent.isMobile || useragent.isTablet);
  }, []);

  return (
    <>
      <Grid className={classes.root}>
        <Grid
          xs={12}
          className={classes.studyPlanHeader}
          container
          justify="space-between"
          alignItems="center">
          <Typography variant="subtitle1" className={classes.mainBoxTitle}>
            Practice Questions
          </Typography>
          <Grid
            // className={classes.gotoOtherPage}
            item
            direction="row"
            justify="flex-end"
            alignItems="center">
            <Grid
              container
              justify="flex-end"
              onClick={(e) => redirectToNewPage(e, '/mock')}>
              <Typography variant="subtitle1" className={classes.gotoOtherPage}>
                Mock Tests
              </Typography>
              <ArrowForwardIcon style={{color: '#0F4C8F'}} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.mobileRoot}>
          <Grid
            className={classes.skillContainerMobile}
            container
            justify="space-around"
            alignItems="center">
            <Grid
              container
              item
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.skillBoxMobile}
              style={{
                borderBottom: activeSkill === 0 ? '3px solid #0F4C8F' : '',
              }}
              onClick={() => setActiveSkill(0)}
              >
              Speaking
            </Grid>

            <Grid
              container
              item
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.skillBoxMobile}
              style={{
                borderBottom: activeSkill === 1 ? '3px solid #0F4C8F' : '',
              }}
              onClick={() => setActiveSkill(1)}
              >
              Writing
            </Grid>

            <Grid
              container
              item
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.skillBoxMobile}
              style={{
                borderBottom: activeSkill === 2 ? '3px solid #0F4C8F' : '',
              }}
              onClick={() => setActiveSkill(2)}
              >
              Reading
            </Grid>

            <Grid
              container
              item
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.skillBoxMobile}
              style={{
                borderBottom: activeSkill === 3 ? '3px solid #0F4C8F' : '',
              }}
              onClick={() => setActiveSkill(3)}
              >
              Listening
            </Grid>
          </Grid>
        </Grid>

        <Practice useragent={useragent} skill={activeSkill} />
      </Grid>
    </>
  );
};

PracticeTab.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  t: PropTypes.func,
  onQuestionChange: PropTypes.func,
  useragent: PropTypes.object,
  sidebar: PropTypes.bool,
};

PracticeTab.defaultProps = {
  t: () => null,
  onQuestionChange: () => {},
  useragent: {},
  sidebar: false,
};

export default PracticeTab;
