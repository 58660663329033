import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box } from '@material-ui/core';
import freeTestTag from "../../../assets/icons/freeTestTag.svg"
import RecentEvent1 from '../../../assets/icons/RecentEvent1.svg'
import RecentEvent2 from '../../../assets/icons/RecentEvent2.svg'
import RecentEvent3 from '../../../assets/icons/RecentEvent3.svg'
import RecentEvent4 from '../../../assets/icons/RecentEvent4.svg'
import Slider from 'react-slick';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: '0 -6px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 -4px',
        },
        '& .slick-slide': {
            padding: '0 6px',
        },
    },
    boxStudy: {
        backgroundColor: '#ffffff',
        borderRadius: "12px",
        padding: "12px"
    },
    titleText: {
        fontSize: '18px',
        fontWeight: '600',
        marginTop: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
}));

const RecentEvents = ({ }) => {
    const classes = useStyles();

    const data = [
        { img: RecentEvent1, },
        { img: RecentEvent2, },
        { img: RecentEvent3, },
        { img: RecentEvent4, },
    ];
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: true,
                    centerPadding: '33.33%',
                    slidesToShow: 0.5
                },
            },
        ],
    };
    return (
        <>
            <Typography variant="h6" className={classes.titleText}  >Recent Events</Typography>
            {/* <Grid container spacing={2} style={{ marginTop: "2px", marginBottom: "12px" }} > */}
            <Box className={classes.main}>
                <Slider {...settings}>
                    {data.map((item, index) => (
                        // <Grid item xl={3} lg={3} md={3} sm={6} xs={12} key={index}>
                        <a href="" target="_blank">
                            <img src={item.img} alt={item.img} width={'100%'} height={'auto'} />
                        </a>
                        // </Grid>
                    ))}
                </Slider>
            </Box>
            {/* </Grid> */}
        </>
    );
};

RecentEvents.propTypes = {
    useragent: PropTypes.object,
    isInDashboard: PropTypes.bool,
};

RecentEvents.defaultProps = {
    useragent: {},
    isInDashboard: false,
};

export default RecentEvents;
