import * as React from 'react'

function SvgTemplates(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7h18a3 3 0 003-3V3a3 3 0 00-3-3H3a3 3 0 00-3 3v1a3 3 0 003 3zM2 3a1 1 0 011-1h18a1 1 0 011 1v1a1 1 0 01-1 1H3a1 1 0 01-1-1V3zM17.48 24H21a3 3 0 003-3v-9a3 3 0 00-3-3h-6.29a3 3 0 00-2.87 3.88l2.77 9A3 3 0 0017.48 24zM13.9 11.41a1 1 0 01.81-.41H21a1 1 0 011 1v9a1 1 0 01-1 1h-3.48a1 1 0 01-1-.71l-2.77-9a1 1 0 01.15-.88zM3 24h6.29a3 3 0 002.87-3.88l-2.77-9A3 3 0 006.52 9H3a3 3 0 00-3 3v9a3 3 0 003 3zM2 12a1 1 0 011-1h3.48a1 1 0 011 .71l2.77 9A1 1 0 019.29 22H3a1 1 0 01-1-1v-9z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgTemplates
