import * as React from 'react';

function SvgArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C11.4477 20 11 19.5523 11 19L11 5C11 4.44771 11.4477 4 12 4C12.5523 4 13 4.44771 13 5L13 19C13 19.5523 12.5523 20 12 20Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7071 12.7071C19.3166 13.0976 18.6834 13.0976 18.2929 12.7071L12 6.41421L5.70711 12.7071C5.31658 13.0976 4.68342 13.0976 4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071Z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgArrowRight;
