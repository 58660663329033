import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'components/Icons/gen/Cross';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ReorderParagraphItem from './ReorderParagraphItem';
import {CarretLeft, CarretRight} from '../Icons/gen';
import ParagraphItem from './ParagraphItem';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto 10px',
  },
  rightNav: {
    background: '#FF3C3C',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  leftNav: {
    background: '#0F4C8F',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    textAlign: 'center',
    color: 'white',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1.5),
    background: 'white',
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    padding: 0,
    '&:hover': {
      background: 'white',
    },
  },
  board: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    maxWidth: '44%',
    height: '100%',
  },
  sourceHeaderContainer: {
    background: '#0F4C8F',
    textAlign: 'center',
    height: theme.spacing(5),
    color: 'white',
    flex: 1,
    width: '100%',
    maxHeight: theme.spacing(6),
    borderRadius: '3px 3px 0 0',
  },
  targetHeaderContainer: {
    background: '#FF3C3C',
    textAlign: 'center',
    height: theme.spacing(5),
    color: 'white',
    flex: 1,
    width: '100%',
    // maxHeight: theme.spacing(6),
    maxHeight: 46,
    borderRadius: '3px 3px 0 0',
  },
  answerSectionContainer: {
    borderTop: `2px solid #0F4C8F`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    background: '#EDF0F2',
    height: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
  },
  viewParagraphButton: {
    marginLeft: theme.spacing(3),
  },
  list: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const ReorderParagraphDesktop = ({
  onAnswer,
  t,
  options,
  showCorrectOrder,
  correctOrder,
  showCorrectAnswer,
}) => {
  const backendColumns = {
    source: {
      name: t('source'),
      items: options,
    },
    target: {
      name: t('target'),
      items: [],
    },
  };
  const classes = useStyles();
  const [showParagraph, setShowParagraph] = useState(true);
  const [showAnswerDialog, setShowAnswerDialog] = useState(false);
  const [stateColumns, setColumnsState] = useState(backendColumns);
  const [answerColumns, setAnswerColumns] = useState();

  const transferSourceToTargetHandler = () => {
    const copyStateColumn = {
      source: {
        name: t('source'),
        items: [],
      },
      target: {
        name: t('target'),
        items: [...stateColumns.target.items, ...stateColumns.source.items],
      },
    };
    setColumnsState(copyStateColumn);
    setAnswerColumns(copyStateColumn);
  };

  const transferTargetToSourcetHandler = () => {
    const copyStateColumn = {
      source: {
        name: t('source'),
        items: [...stateColumns.source.items, ...stateColumns.target.items],
      },
      target: {
        name: t('target'),
        items: [],
      },
    };
    setColumnsState(copyStateColumn);
    setAnswerColumns(copyStateColumn);
  };

  const onDragHandler = (result) => {
    const {source, destination} = result;
    const prevPracticeAnswer = JSON.parse(JSON.stringify(stateColumns));
    if (!destination) return;
    if (showCorrectOrder) return;
    if (
      source &&
      destination &&
      source.droppableId === destination.droppableId
    ) {
      prevPracticeAnswer[destination.droppableId].items.splice(source.index, 1);
      prevPracticeAnswer[destination.droppableId].items.splice(
        destination.index,
        0,
        stateColumns[destination.droppableId].items[source.index],
      );
    }
    if (destination && source.droppableId !== destination.droppableId) {
      prevPracticeAnswer[source.droppableId].items.splice(source.index, 1);
      prevPracticeAnswer[destination.droppableId].items.splice(
        destination.index,
        0,
        stateColumns[source.droppableId].items[source.index],
      );
    }
    setColumnsState(prevPracticeAnswer);
    setAnswerColumns(prevPracticeAnswer);
  };

  useEffect(() => {
    if (answerColumns) onAnswer(answerColumns);
  }, [answerColumns]);

  return (
    <Hidden mdDown>
      <Dialog
        onClose={() => setShowAnswerDialog(false)}
        aria-labelledby="correctDialog"
        open={showAnswerDialog}
        maxWidth="lg"
        fullWidth>
        <DialogTitle id="correctDialogTitle" className={classes.dialogTitle}>
          <Typography variant="body1"> {t('yourOrder')}</Typography>
          <IconButton
            onClick={() => setShowAnswerDialog(false)}
            className={classes.closeIcon}>
            <CloseIcon color="#f00" width={35} height={35} />
          </IconButton>
        </DialogTitle>
        <List className={classes.list}>
          {stateColumns &&
            stateColumns.target &&
            stateColumns.target.items.map((item, index) => {
              return (
                <Grid item key={index} style={{padding: '0 8px'}}>
                  <ParagraphItem
                    item={item}
                    optionIndex={options.indexOf(item) + 1}
                  />
                </Grid>
              );
            })}
        </List>
      </Dialog>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        className={classes.container}>
        <DragDropContext onDragEnd={onDragHandler}>
          <Paper className={classes.board} elevation={0}>
            <div className={classes.sourceHeaderContainer}>
              <h2 style={{margin: '8px 0'}}>
                {stateColumns &&
                  stateColumns.source &&
                  stateColumns.source.name}
              </h2>
            </div>
            <div
              style={{
                margin: 0,
                maxWidth: '100%',
                width: '100%',
                border: '1px solid #C4C4C4',
                borderTop: 'none',
              }}>
              <Droppable droppableId="source" key="source">
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? 'lightblue'
                          : 'white',
                        padding: 4,
                        minHeight: 500,
                        width: '100%',
                      }}>
                      {stateColumns &&
                        stateColumns.source &&
                        stateColumns.source.items.map((item, index) => {
                          return (
                            <ReorderParagraphItem
                              key={item}
                              index={index}
                              optionIndex={options.indexOf(item)}
                              item={item}
                            />
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </Paper>
          <span className={classes.navigationContainer}>
            <CarretRight
              color="#fff"
              width={48}
              height={48}
              style={{cursor: 'pointer'}}
              className={classes.rightNav}
              onClick={transferSourceToTargetHandler}
            />
            <CarretLeft
              color="#fff"
              width={48}
              height={48}
              style={{cursor: 'pointer'}}
              className={classes.leftNav}
              onClick={transferTargetToSourcetHandler}
            />
          </span>
          <Paper className={classes.board} elevation={0}>
            <div className={classes.targetHeaderContainer}>
              <h2 style={{margin: '8px 0'}}>
                {stateColumns &&
                  stateColumns.target &&
                  stateColumns.target.name}
              </h2>
            </div>
            <div
              style={{
                margin: 0,
                maxWidth: '100%',
                width: '100%',
                border: '1px solid #C4C4C4',
                borderTop: 'none',
              }}>
              <Droppable droppableId="target" key="target">
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? 'lightblue'
                          : 'white',
                        padding: 4,
                        minHeight: 500,
                        width: '100%',
                      }}>
                      {stateColumns &&
                        stateColumns.target &&
                        stateColumns.target.items.map((item, index) => {
                          return (
                            <ReorderParagraphItem
                              key={item}
                              index={index}
                              item={item}
                              optionIndex={options.indexOf(item)}
                            />
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </Paper>
        </DragDropContext>
      </Grid>
      {stateColumns &&
        stateColumns.target &&
        stateColumns.target.items.length > 0 && (
          <Grid
            container
            justify="space-between"
            className={classes.answerSectionContainer}>
            <Grid item>
              {t('yourOrder')}:
              {stateColumns.target.items.map((item, index) => {
                if (index + 1 === stateColumns.target.items.length) {
                  return <span key={index}> {options.indexOf(item) + 1}</span>;
                }
                return (
                  <span key={index}>{` ${options.indexOf(item) + 1}  `}</span>
                );
              })}
              <Button
                color="primary"
                variant="contained"
                className={classes.viewParagraphButton}
                onClick={() => setShowAnswerDialog((prev) => !prev)}>
                {!showParagraph ? t('viewBoard') : t('viewParagraph')}
              </Button>
            </Grid>
            {showCorrectOrder ? (
              <Grid item>
                {t('correctOrder')}:
                {correctOrder.map((item, index) => {
                  if (index + 1 === correctOrder.length) {
                    return <span key={index}>{item}</span>;
                  }
                  return <span key={index}>{` ${item} `}</span>;
                })}
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.viewParagraphButton}
                  onClick={showCorrectAnswer}>
                  {t('viewCorrectOrder')}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        )}
    </Hidden>
  );
};

ReorderParagraphDesktop.propTypes = {
  stateColumns: PropTypes.shape().isRequired,
  onAnswer: PropTypes.func.isRequired,
  setColumnsState: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  showCorrectOrder: PropTypes.bool,
  correctOrder: PropTypes.array,
  showCorrectAnswer: PropTypes.func,
};

ReorderParagraphDesktop.defaultProps = {
  showCorrectOrder: false,
  correctOrder: [],
  showCorrectAnswer: () => {},
};

export default ReorderParagraphDesktop;
