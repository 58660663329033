export function reorderSkill(skill, data = []) {
  switch (skill) {
    case 'Speaking':
      return reorderSpeaking(data);
    case 'Writing':
      return reorderWriting(data);
    case 'Reading':
      return reorderReading(data);
    case 'Listening':
      return reorderListening(data);
  }
}

function reorderSpeaking(data = []) {
  var newData = [];
  data.forEach((i) => {
    switch (i.type) {
      case 'ReadAloud':
        newData[0] = i;
        break;
      case 'RepeatSentence':
        newData[1] = i;
        break;
      case 'DescribeImage':
        newData[2] = i;
        break;
      case 'RetellLecture':
        newData[3] = i;
        break;
      case 'AnswerShortQuestion':
        newData[4] = i;
        break;
    }
  });
  return newData;
}

function reorderWriting(data) {
  var newData = [];
  data.forEach((i) => {
    switch (i.type) {
      case 'SummarizeWrittenText':
        newData[0] = i;
        break;
      case 'WriteEssay':
        newData[1] = i;
        break;
    }
  });
  return newData;
}
function reorderReading(data) {
  var newData = [];
  data.forEach((i) => {
    switch (i.type) {
      case 'ReadingMultipleChoiceSingleAnswer':
        newData[0] = i;
        break;
      case 'ReadingMultipleChoiceMultipleAnswer':
        newData[1] = i;
        break;
      case 'ReorderParagraph':
        newData[2] = i;
        break;
      case 'ReadingAndWritingFillInTheBlanks':
        newData[3] = i;
        break;
      case 'ReadingFillInTheBlanks':
        newData[4] = i;
        break;
    }
  });
  return newData;
}
function reorderListening(data) {
  var newData = [];
  data.forEach((i) => {
    switch (i.type) {
      case 'SummarizeSpokenText':
        newData[0] = i;
        break;
      case 'ListeningMultipleChoiceSingleAnswer':
        newData[1] = i;
        break;
      case 'ListeningMultipleChoiceMultipleAnswer':
        newData[2] = i;
        break;
      case 'ListeningFillInTheBlanks':
        newData[3] = i;
        break;
      case 'HighlightCorrectSummary':
        newData[4] = i;
        break;
      case 'SelectMissingWord':
        newData[5] = i;
        break;
      case 'HighlightIncorrectWords':
        newData[6] = i;
        break;
      case 'WriteFromDictation':
        newData[7] = i;
        break;
    }
  });
  return newData;
}

export function reorderSkills(data) {
  var newData = [];
  data.forEach((i) => {
    switch (i.type) {
      case 'ReadAloud':
        newData[0] = i;
        break;
      case 'RepeatSentence':
        newData[1] = i;
        break;
      case 'DescribeImage':
        newData[2] = i;
        break;
      case 'RetellLecture':
        newData[3] = i;
        break;
      case 'AnswerShortQuestion':
        newData[4] = i;
        break;
      case 'SummarizeWrittenText':
        newData[5] = i;
        break;
      case 'WriteEssay':
        newData[6] = i;
        break;
      case 'ReadingMultipleChoiceSingleAnswer':
        newData[7] = i;
        break;
      case 'ReadingMultipleChoiceMultipleAnswer':
        newData[8] = i;
        break;
      case 'ReorderParagraph':
        newData[9] = i;
        break;
      case 'ReadingAndWritingFillInTheBlanks':
        newData[10] = i;
        break;
      case 'ReadingFillInTheBlanks':
        newData[11] = i;
        break;
      case 'SummarizeSpokenText':
        newData[12] = i;
        break;
      case 'ListeningMultipleChoiceSingleAnswer':
        newData[13] = i;
        break;
      case 'ListeningMultipleChoiceMultipleAnswer':
        newData[14] = i;
        break;
      case 'ListeningFillInTheBlanks':
        newData[15] = i;
        break;
      case 'HighlightCorrectSummary':
        newData[16] = i;
        break;
      case 'SelectMissingWord':
        newData[17] = i;
        break;
      case 'HighlightIncorrectWords':
        newData[18] = i;
        break;
      case 'WriteFromDictation':
        newData[19] = i;
        break;
    }
  });
  return newData;
}
