import * as React from 'react'

function Circle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
      <circle cx="4.5" cy="4.5" r="4.5" fill={props.color} />
    </svg>
  )
}

export default Circle;
