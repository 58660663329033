import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';

import AppTab from './Tabs/AppTab';
import AppTabs from './Tabs/AppTabs';
import Training from './Training';
import PracticePathway from './PracticePathway';
import {fetchStudyPlanAction} from 'core/lib/adapters/redux/actions';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '20px 0',
  },
}));

const StudyPlan = ({useragent, isInDashboard = false}) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState('Training');
  const [page, setPage] = useState(1);
  const [userQuery, setUserQuery] = useState('');
  const {questions, sessions, userSessions} = useSelector((state) => ({
    questions: state.questions.questionList.webList,
    sessions: state.session.sessions,
    userSessions: state.session.userSessions,
  }));

  const changeActiveTabHandler = (event, newValue, useragentStatuse) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      setActiveTabName('Training');
    }
    if (newValue === 1) {
      setActiveTabName('Practice Pathway');
      dispatch(fetchStudyPlanAction());
    }
  };

  const getQuestionListHandler = () => {
    if (activeTabName === 'Training') {
    } else if (activeTabName === 'Practice Pathway') {
    }
  };

  useEffect(() => {
    getQuestionListHandler();
  }, [page, activeTabName, userQuery]);

  useEffect(() => {
    changeActiveTabHandler('e', 0, useragent.isMobile || useragent.isTablet);
  }, []);

  return (
    <>
      <Grid className={classes.root}>
        <Grid container>
          <AppTabs
            value={activeTab}
            onChange={(e, newValue) =>
              changeActiveTabHandler(
                e,
                newValue,
                useragent.isMobile || useragent.isTablet,
              )
            }
            indicatorColor="primary"
            textColor="primary"
            // centered
          >
            <AppTab label={'Training'} />
            <AppTab label={'Practice Pathway'} />
          </AppTabs>
        </Grid>

        {activeTabName === 'Training' ? (
          <>
            <Training useragent={useragent} isInDashboard={isInDashboard} />
          </>
        ) : (
          <>
            <PracticePathway useragent={useragent} />
          </>
        )}
      </Grid>
    </>
  );
};

StudyPlan.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  t: PropTypes.func,
  onQuestionChange: PropTypes.func,
  useragent: PropTypes.object,
  sidebar: PropTypes.bool,
};

StudyPlan.defaultProps = {
  t: () => null,
  onQuestionChange: () => {},
  useragent: {},
  sidebar: false,
};

export default StudyPlan;
