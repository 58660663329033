import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  getCoursesAction,
  clearCoursesAction,
} from 'core/lib/adapters/redux/actions/pte-course';
import CourseBoxItem from './CourseBoxItem';
import ProgressBar from './ProgressBar';
import SvgPlayCircle from '../Icons/gen/PlayCircle';
import SvgBookIcon from '../Icons/gen/Book';
import SvgMinusCircle from '../Icons/gen/MinusCircle';
import SvgPlusCircle from '../Icons/gen/PlusCircle';
import SvgHelpMark from '../Icons/gen/HelpMark';
import SvgFolder from '../Icons/gen/Folder';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBlock: '20px',
  },
  header: {
    background: '#EDF0F2',
    padding: '10px 20px',
    cursor: 'pointer',
  },
  headerMobile: {
    padding: '20px 5px',
  },
  toggleIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),

    background: '#EDF0F2',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  toggleIconMobile: {
    marginRight: 0,
  },
  mockIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),

    background: '#EDF0F2',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  mockIconMobile: {
    marginRight: 0,
  },
  content: {
    background: '#F9F9F9',
    padding: '12px',
  },
  gridItem: {
    padding: 7.5,
  },
  gridItemMobile: {
    padding: '7.5px 0',
  },
  pagination: {
    margin: '35px 0',
  },
  noItem: {
    textAlign: 'center',
    width: '100%',
    fontSize: '18px',
    margin: '40px 0',
  },
  title: {
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: 600,
  },
  titleMobile: {
    fontSize: 14,
  },
  xsCenter: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

const customTheme = createMuiTheme({
  overrides: {
    MuiPagination: {
      ul: {
        // background: 'white',
        color: 'rgba(2,117,216,1)',
        '& li button': {
          background: 'white',
          margin: '0 0',
          color: '#0275D8',
          borderRadius: '0',
          width: '41px',
          height: '34px',
        },
        '& .Mui-selected': {
          background: '#0F4C8F',
          color: 'white',
          // fontWeight: 'bold'
        },
      },
    },
  },
});

const ChooseHeaderIcon = ({type}) => {
  const theme = useTheme();
  if (type.toLowerCase() === 'training videos') {
    return <SvgPlayCircle color={theme.palette.primary.main} />;
  }
  if (type.toLowerCase() === 'knowledge tests') {
    return <SvgBookIcon color={theme.palette.primary.main} />;
  }
  if (type.toLowerCase() === 'practice questions') {
    return <SvgHelpMark color={theme.palette.primary.main} />;
  }
  if (type.toLowerCase() === 'learning resources') {
    return <SvgFolder color={theme.palette.primary.main} />;
  }
  return <SvgFolder color={theme.palette.primary.main} />;
};

const CourseBox = ({ua, ...props}) => {
  const styles = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(false);
  const courses = useSelector((state) => state.course.courses) || {
    [props.subCatId]: {loading: true},
  };

  const clearContent = () => {
    if (showContent) {
      dispatch(clearCoursesAction({id: props.subCatId}));
    }
  };

  useEffect(() => {
    return () => {
      // when changing tabs for first while you haven't open any dropdown yet
      // it will throw bcz state.course is null and it can't clearCourses, so we check
      // todo: fix this doens't work in some cases
      if (courses[props.subCatId] && courses[props.subCatId].data) {
        dispatch(clearCoursesAction({id: props.subCatId, perPage: 9}));
      }
    };
  }, []);

  const toggleContent = () => {
    clearContent();
    setShowContent(!showContent);
  };

  useEffect(() => {
    // if we are trying to hide content no need to dispatch the event
    if (!showContent) {
      return () => {};
    }
    dispatch(getCoursesAction({id: props.subCatId, perPage: 9}));
  }, [showContent, props.subCatId]);

  const paginate = (page) => {
    dispatch(getCoursesAction({id: props.subCatId, page, perPage: 9}));
  };

  return (
    <div className={styles.root}>
      <Grid
        container
        alignItems="center"
        className={`${styles.header} ${ua.isMobile ? styles.headerMobile : ''}`}
        onClick={toggleContent}>
        <Grid item container xs={7} sm={4} alignItems="center">
          <Avatar
            className={`${styles.mockIcon} ${
              ua.isMobile ? styles.mockIconMobile : ''
            }`}>
            <ChooseHeaderIcon type={props.type} />
          </Avatar>
          <Typography
            variant="h6"
            display="inline"
            color="primary"
            className={`${styles.title} ${
              ua.isMobile ? styles.titleMobile : ''
            }`}>
            {props.type}
          </Typography>
        </Grid>

        <Grid item xs={3} sm={4}>
          <ProgressBar
            ua={ua}
            total={props.totalCourses}
            rate={props.totalViewdCourses}
          />
        </Grid>

        <Grid
          item
          container
          justify="flex-end"
          className={styles.xsCenter}
          xs={2}
          sm={4}>
          <Avatar
            className={`${styles.toggleIcon} ${
              ua.isMobile ? styles.toggleIconMobile : ''
            }`}>
            {showContent ? (
              <SvgMinusCircle color={theme.palette.primary.main} />
            ) : (
              <SvgPlusCircle color={theme.palette.primary.main} />
            )}
          </Avatar>
        </Grid>
      </Grid>
      {showContent && (
        <Grid item xs={12} container className={styles.content}>
          <Grid container>
            {courses[props.subCatId] && courses[props.subCatId].loading ? (
              <Grid
                container
                justify="center"
                style={{height: 50, display: 'flex', alignItems: 'center'}}>
                <CircularProgress size={25} />
              </Grid>
            ) : props.subCatId in courses &&
              courses[props?.subCatId]?.data?.length === 0 ? (
              <div className={styles.noItem}>
                {' '}
                No course available for this category{' '}
              </div>
            ) : (
              props.subCatId in courses &&
              courses[props?.subCatId].data.map((item, index) => (
                <Grid
                  key={item.id}
                  item
                  sm={6}
                  md={4}
                  xs={12}
                  className={`${styles.gridItem} ${
                    ua.isMobile ? styles.gridItemMobile : ''
                  }`}>
                  <CourseBoxItem
                    title={item.title}
                    categoryType={props.type}
                    id={item.id}
                    billId={item.billable_id}
                    status={item.last_viewed_at}
                    // Since it can be pdf or video or question
                    // questionsOrData={item.questions.data}
                    type={item.is_free}
                    paidStatus={item.paid_status}
                    ua={ua}
                    userPackagesData={props.userPackagesData}
                    mainCategory={props.mainCategory}
                    subCategory={props.subCategory}
                    indexCourse={index + 1}
                  />
                </Grid>
              ))
            )}
          </Grid>
          {courses[props.subCatId] &&
            courses[props.subCatId].meta &&
            courses[props.subCatId].meta.pagination.total_pages > 1 && (
              <Grid container justify="center" className={styles.pagination}>
                <ThemeProvider theme={customTheme}>
                  <Pagination
                    count={courses[props.subCatId].meta.pagination.total_pages}
                    variant="outlined"
                    boundaryCount={3}
                    color="primary"
                    page={courses[props.subCatId].meta.pagination.current_page}
                    onChange={(e, page) => paginate(page)}
                    shape="rounded"
                  />
                </ThemeProvider>
              </Grid>
            )}
        </Grid>
      )}
    </div>
  );
};

CourseBox.propTypes = {
  type: PropTypes.string.isRequired,
  subCatId: PropTypes.number.isRequired,
  ua: PropTypes.object,
  totalCourses: PropTypes.number.isRequired,
  totalViewdCourses: PropTypes.number.isRequired,
};

export default CourseBox;
