import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AnalyticsInDashboard from 'components/Analytics/AnalyticsInDashboard';
import {
  setShowupSmartAdvisorModalAction,
  getVideosPostListAction,
} from 'core/lib/adapters/redux/actions';
import Modals from './Modals';
import StudyTour from '../StudyTour';
import AppTab from './Tabs/AppTab';
import AppTabs from './Tabs/AppTabs';
import { parseVideoUrlToIframe } from '../../../utils/utils';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '20px 0px',
    width: '100%',
  },
  mobileRoot: {
    padding: '10px',
    width: '100%',
  },
  adviceText: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: 30,
  },
  adviceBtn: {
    marginTop: 15,
    color: '#fff',
    fontSize: 14,
    fontWeight: 600,
    padding: '10px 30px',
    textTransform: 'capitalize',
    boxShadow: 'none',
  },
  practiceAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: '#EDF0F2',
    padding: theme.spacing(0.7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  },
  type: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  videoBox: {
    position: 'relative',
    marginTop: 15,
    paddingTop: '56.25%',
  },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  videoTag: {
    width: '100%',
    marginTop: 20,
  },
}));

const VideoGuides = ({ t, useragent }) => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const { videosPosts } = useSelector((state) => ({
    videosPosts: state.post.videosPosts,
  }));

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState('Smart Advisor');

  const changeActiveTabHandler = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      setActiveTabName('Smart Advisor');
    }
    if (newValue === 1) {
      setActiveTabName('Study Tour');
    }
    if (newValue === 2) {
      setActiveTabName('Progress');
    }
  };

  useEffect(() => {
    changeActiveTabHandler('e', 0, useragent.isMobile || useragent.isTablet);
    dispatch(getVideosPostListAction({ category: 43, per_page: 1000 }));
  }, []);

  const html = videosPosts && videosPosts.data && parseVideoUrlToIframe(videosPosts.data.find((i) => i.is_featured === 1)?.text);
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  const link = tempElement.textContent;

  return (
    <>
      {useragent.isMobile || useragent.isTablet ? (
        <>
          <Grid className={classes.mobileRoot}>
            <Grid container>
              <AppTabs
                value={activeTab}
                onChange={(e, newValue) =>
                  changeActiveTabHandler(
                    e,
                    newValue,
                    useragent.isMobile || useragent.isTablet,
                  )
                }
                indicatorColor="primary"
                textColor="primary"
                centered
                variant="scrollable"
                scrollButtons="auto"
                aria-label="ant example">
                <AppTab label="Smart Advisor" />
                <AppTab label="Study Tour" />
                <AppTab label="Progress" />
              </AppTabs>
            </Grid>

            {activeTabName === 'Smart Advisor' && (
              <Grid container spacing={0} justify="center">
                <Grid item xs={12}>
                  <div className={classes.videoBox}>
                    <video
                      className={classes.videoIframe}
                      src={link}
                      title="Video Player"
                      frameBorder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </Grid>
                <div style={{ borderBottom: '1px solid #E4E4E4' }} />

                <Typography variant="subtitle1" className={classes.adviceText}>
                  Do you seek smart advice on your past scores in the PTE?
                </Typography>
                <Button
                  variant="contained"
                  className={classes.adviceBtn}
                  elevation={0}
                  onClick={() =>
                    dispatch(setShowupSmartAdvisorModalAction({ statuse: true }))
                  }
                  color="primary">
                  Submit Score
                </Button>
                <Modals useragent={useragent} />
              </Grid>
            )}

            {activeTabName === 'Study Tour' && <StudyTour />}

            {activeTabName === 'Progress' && (
              <AnalyticsInDashboard
                t={t}
                type="ss"
                typeId="eee"
                useragent={useragent}
              />
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid className={classes.root}>
            <Grid container>
              <AppTabs
                value={activeTab}
                onChange={(e, newValue) =>
                  changeActiveTabHandler(
                    e,
                    newValue,
                    useragent.isMobile || useragent.isTablet,
                  )
                }
                indicatorColor="primary"
                textColor="primary"
              >
                <AppTab label="Smart Advisor" />
                <AppTab label="Study Tour" />
              </AppTabs>
            </Grid>

            {activeTabName === 'Smart Advisor' && (
              <Grid container spacing={0} justify="center">
                <Grid item xs={12}>
                  <div className={classes.videoBox}>
                    <video
                      className={classes.videoIframe}
                      src={link}
                      title="Video Player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      controls
                    />
                  </div>
                </Grid>
                <div style={{ borderBottom: '1px solid #E4E4E4' }} />
                <Typography variant="subtitle1" className={classes.adviceText}>
                  Do you seek smart advice on your past scores in the PTE?
                </Typography>
                <Button
                  variant="contained"
                  className={classes.adviceBtn}
                  elevation={0}
                  onClick={() =>
                    dispatch(setShowupSmartAdvisorModalAction({ statuse: true }))
                  }
                  color="primary">
                  Submit Score
                </Button>
                <Modals useragent={useragent} />
              </Grid>
            )}

            {activeTabName === 'Study Tour' && <StudyTour />}
          </Grid>
        </>
      )}
    </>
  );
};

VideoGuides.propTypes = {
  t: PropTypes.func,
  onQuestionChange: PropTypes.func,
  useragent: PropTypes.object,
  sidebar: PropTypes.bool,
};

VideoGuides.defaultProps = {
  t: () => null,
  onQuestionChange: () => { },
  useragent: {},
  sidebar: false,
};

export default VideoGuides;
