import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  navigator: {
    background: theme.palette.primary.main,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '60px',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
  },
  navigatorItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    outline: 'none',
    color: '#fff',
    backgroundColor: 'transparent',
  },
  newBtn: {
    backgroundColor: '#fff',
    borderRadius: 3,
    padding: '10px 30px',
    color: '#0F4C8F',
    fontWeight: 600,
    fontSize: '0.8em',
    marginRight: 10,
  },
  newBtnInSQ: {
    backgroundColor: '#fff',
    borderRadius: 3,
    padding: '5px 15px',
    color: '#0F4C8F',
    fontWeight: 600,
    fontSize: '0.8em',
    marginRight: 10,
  },
  disabledItem: {
    // opacity: 0.5,
    // backgroundColor: 'transparent !important',
    color: '#aaa',
  },
}));

const CourseNavigatorM = ({
  t,
  navType,
  onExit,
  onRedo,
  onNext,
  onPrev,
  onAI,
  onAnswer,
  answerDisabled,
  prevDisabled,
  aiCorrector,
  aiLoading,
  aiDisabled,
}) => {
  const classes = useStyles();
  const [exitDisabled, setExitDisabled] = useState(false);

  if (navType === 'singleQuestion') {
    return (
      <Grid container item xs={12} className={classes.navigator}>
        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtnInSQ, {
            [classes.disabledItem]: exitDisabled,
          })}
          onClick={() => {
            setExitDisabled(true);
            onExit();
          }}
          disabled={exitDisabled}>
          {/* <ExitIcon color="white" style={{transform: 'rotate(180deg)'}} /> */}
          {t('exit')}
        </button>

        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtnInSQ, {
            [classes.disabledItem]: prevDisabled,
          })}
          onClick={onAnswer}
          disabled={answerDisabled}>
          {/* <CheckIcon color="white" /> */}
          {t('Submit')}
        </button>
        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtnInSQ, {
            [classes.disabledItem]: aiDisabled || aiLoading,
          })}
          onClick={onAI}
          disabled={aiDisabled || aiLoading}>
          {aiLoading ? (
            <CircularProgress color="white" size={24} />
          ) : (
            <span style={{fontSize: '0.8em'}}>
              {aiCorrector ? t('aiScore') : t('score')}
            </span>
          )}
        </button>
        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtnInSQ, {
            [classes.disabledItem]: exitDisabled,
          })}
          onClick={onRedo}
          disabled={exitDisabled}>
          {/* <ExitIcon color="white" style={{transform: 'rotate(180deg)'}} /> */}
          {t('redo')}
        </button>
      </Grid>
    );
  }
  if (navType === 'multipleQuestion') {
    return (
      <Grid container item xs={12} className={classes.navigator}>
        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtn, {
            [classes.disabledItem]: exitDisabled,
          })}
          onClick={() => {
            setExitDisabled(true);
            onExit();
          }}
          disabled={exitDisabled}>
          {/* <ExitIcon color="white" style={{transform: 'rotate(180deg)'}} /> */}
          {/* {t('Save And Exit')} */}
          {t('exit')}
        </button>
        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtn)}
          onClick={onPrev}
          disabled={prevDisabled}>
          {/* <CheckIcon color="white" /> */}
          {t('Prev')}
        </button>
        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtn)}
          onClick={onNext}
          disabled={answerDisabled}>
          {/* <CheckIcon color="white" /> */}
          {t('Next')}
        </button>
      </Grid>
    );
  }
  if (navType === 'multipleQuestionLastPage') {
    return (
      <Grid container item xs={12} className={classes.navigator}>
        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtn)}
          onClick={() => {
            onPrev();
            onAnswer();
          }}
          disabled={prevDisabled}>
          {/* <CheckIcon color="white" /> */}
          {t('Prev')}
        </button>
        <button
          type="button"
          className={clsx(classes.navigatorItem, classes.newBtn)}
          onClick={() => {
            onNext();
            onAnswer();
          }}
          disabled={answerDisabled}>
          {/* <CheckIcon color="white" /> */}
          {t('Submit')}
        </button>
      </Grid>
    );
  }
};

CourseNavigatorM.propTypes = {
  t: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onRedo: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onAI: PropTypes.func.isRequired,
  onAnswer: PropTypes.func.isRequired,
  answerDisabled: PropTypes.bool.isRequired,
  submittingAnswer: PropTypes.bool.isRequired,
  navigating: PropTypes.bool.isRequired,
  prevDisabled: PropTypes.bool.isRequired,
  nextDisabled: PropTypes.bool.isRequired,
  showShareResponse: PropTypes.bool.isRequired,
  shareResponse: PropTypes.func.isRequired,
  shareResponseDisabled: PropTypes.bool,
  aiCorrector: PropTypes.bool,
  navType: PropTypes.string.isRequired,
};

export default CourseNavigatorM;
