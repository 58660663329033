import {useState, useEffect} from 'react';
import {makeStyles, styled} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const useStepThreeStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 'normal',
  },
  placeholder: {
    display: 'inline-block',
    width: '25px',
  },
  detail: {
    marginTop: '35px',
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: '700',
    textDecoration: 'underline',
  },
}));

const Number = styled(TextField)({
  width: '95%',
  '& input': {
    padding: '10px',
  },
  marginBottom: 15,
});

function StepThree({studyPlanData = {}, sendData}) {
  const classes = useStepThreeStyles();
  const [studyPlanInfo, setStudyPlanInfo] = useState(studyPlanData);

  useEffect(() => {
    sendData(studyPlanInfo);
  }, [studyPlanInfo]);

  return (
    <>
      <h4 className={classes.title}>What scores do you need?</h4>

      <Grid container>
        <Grid container item xs={6} lg={4}>
          <Number
            id="standard-number"
            label="Speaking"
            InputProps={{inputProps: {min: 23, max: 90}}}
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanInfo((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    target_s: value,
                    target_sError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, target_s: value, target_sError: null};
                else return {target_s: value};
              });
            }}
            error={studyPlanData?.target_sError}
            helperText={studyPlanData?.target_sError}
            type="number"
            value={studyPlanInfo?.target_s}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={classes.placeholder} />
        </Grid>

        <Grid container item xs={6} lg={4}>
          <Number
            id="standard-number"
            label="Writing"
            InputProps={{inputProps: {min: 23, max: 90}}}
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanInfo((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    target_w: value,
                    target_wError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, target_w: value, target_wError: null};
                else return {target_w: value};
              });
            }}
            error={studyPlanData?.target_wError}
            helperText={studyPlanData?.target_wError}
            type="number"
            value={studyPlanInfo?.target_w}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={classes.placeholder} />
        </Grid>

        <Grid container item xs={6} lg={4}>
          <Number
            id="standard-number"
            label="Reading"
            InputProps={{inputProps: {min: 23, max: 90}}}
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanInfo((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    target_r: value,
                    target_rError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, target_r: value, target_rError: null};
                else return {target_r: value};
              });
            }}
            error={studyPlanData?.target_rError}
            helperText={studyPlanData?.target_rError}
            type="number"
            value={studyPlanInfo?.target_r}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={classes.placeholder} />
        </Grid>

        <Grid container item xs={6} lg={4}>
          <Number
            id="standard-number"
            label="Listening"
            InputProps={{inputProps: {min: 23, max: 90}}}
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanInfo((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    target_l: value,
                    target_lError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, target_l: value, target_lError: null};
                else return {target_l: value};
              });
            }}
            error={studyPlanData?.target_lError}
            helperText={studyPlanData?.target_lError}
            type="number"
            value={studyPlanInfo?.target_l}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <div className={classes.detail}>
        Check PTE - IELTS Score Calculator{' '}
        <a
          className={classes.link}
          href="https://edubenchmark.com/blog/pte-score-calculator-ielts-nursing/#precal1"
          target="_blank">
          Here
        </a>
      </div>
    </>
  );
}

export default StepThree;
