import * as React from 'react'

function SvgCarretDown(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12.277 14.752l-3.826-4.251A.3.3 0 018.674 10h7.652a.3.3 0 01.223.5l-3.826 4.252a.3.3 0 01-.446 0z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgCarretDown
