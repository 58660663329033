import * as React from 'react'

function SvgStats(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M23 22H1a1 1 0 01-1-1V3a1 1 0 012 0v17h21a1 1 0 110 2z"
        fill={props.color}
      />
      <path
        d="M4 16a1 1 0 01-.71-1.71l6-6a1 1 0 011.42 0l3.29 3.3 6.29-6.3a1.004 1.004 0 011.42 1.42l-7 7a1 1 0 01-1.42 0L10 10.41l-5.29 5.3A1 1 0 014 16z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgStats
