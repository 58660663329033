import {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Letter from 'components/Icons/gen/Letter';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStepOneStyles = makeStyles((theme) => ({
  mainBox:{
    marginTop: "50%"
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    marginTop: 20,
  },
}));
const StepEnd = ({closeModal}) => {
  const classes = useStepOneStyles();

  useEffect(()=>{
    setTimeout(()=>{
      closeModal(3)
    },3000)
  },[])



  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.mainBox}>
      <Letter width={89} height={59} />
      <Typography variant="subtitle1" className={classes.title}>
        Please check your Inbox / Spam / Junk folder
      </Typography>
    </Grid>
  );
};

export default StepEnd;
