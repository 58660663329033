import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  checkCreditAction,
  bookingSessionAction,
  useCreditAction,
} from 'core/lib/adapters/redux/actions';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useMediaQuery} from '@material-ui/core';
import UserPackageList from '../../../components/userPackageList';
import {pascalCaseConvertor} from '../../../../../utils/utils';
import Watch from '../../../../Icons/gen/Watch';
import ArrowRight from '../../../../Icons/gen/ArrowRight';

const useStyle = makeStyles((theme) => ({
  paginationUl: {
    '&>li>button': {
      padding: 0,
      margin: 0,
      borderRadius: 0,
    },
    '&>li:first-child>button': {
      borderRadius: `${theme.spacing(0.5)}px 0 0 ${theme.spacing(0.5)}px`,
    },
    '&>li:last-child>button': {
      borderRadius: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    },
  },
  paginationRoot: {
    marginTop: theme.spacing(2),
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'baseline',
    boxShadow: '0px -2px 10px rgba(50, 50, 71, 0.1)',
    borderRadius: 12,
    padding: '23px 30px',
    margin: '15px 0',
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  sessionTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  sessionSubTitle: {
    fontWeight: 600,
    fontSize: 14,
    // lineHeight: '19.5px',
  },
  anchor: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
  },
  tagBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tagBoxText: {
    fontWeight: 700,
    fontSize: 11,
  },
  setsTasgs: {
    width: 100,
    height: 26,
    backgroundColor: 'rgba(15,76,143,.1)',
  },
  setsTasgsText: {
    color: '#0F4C8F',
  },
  freeTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(66, 255, 0, 0.1)',
  },
  freeTagText: {
    color: '#3E9D03',
  },
  upgradeTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(255, 230, 0, 0.1)',
  },
  upgradeTagText: {
    color: '#DDC80C',
    fontSize: 11,
  },
  paidTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(255, 60, 60, 0.08)',
  },
  paidTagText: {
    color: '#FF3C3C',
  },
  tableCell: {
    width: '30%',
    verticalAlign: 'baseline',
    border: 'none',
    padding: 0,
  },
  watchCell: {
    verticalAlign: 'baseline',
    paddingTop: 22,
    width: '20%',
  },
  watchBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#353535',
  },
  watchBoxSVG: {
    paddingRight: theme.spacing(1),
  },
  descriptionCell: {
    verticalAlign: 'baseline',
    width: '30%',
    // paddingTop: 22,
  },
  dialogContainer: {
    margin: 0,
    width: '100%',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: 16,
    color: '#353535',
  },
  dialogSubTitle: {
    fontWeight: 500,
    fontSize: 14,
    color: '#353535',
    marginTop: 15,
  },
  dialogTagSubTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  dialogContent: {
    fontWeight: 400,
    fontSize: 18,
    color: '#303030',
    maxHeight: 400,
    overflow: 'scroll',
    marginTop: theme.spacing(2),
  },
  dialogFooter: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dialogFooterBtn: {
    fontWeight: 600,
    fontSize: 16,
    outline: 'none',
    border: 'none',
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    borderRadius: 3,
  },
  dialogFooterBtnJoin: {
    color: 'white',
    backgroundColor: '#0F4C8F',
  },
  dialogFooterBtnCancel: {
    color: '#808080',
    backgroundColor: '#DADADA',
  },
  joinBtn: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    fontSize: 12,
    fontWeight: 700,
    width: 52,
    justifyContent: 'space-between',
    '&:hover': {
      background: 'transparent',
      border: 'none',
      outline: 'none',
    },
  },
  cancelBtn: {
    width: 97,
    height: 34,
    background: '#DADADA',
    color: '#808080',
    fontSize: 16,
    fontWeight: 700,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    marginLeft: 22,
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
  },
}));

const QuestionTable = ({
  t,
  sessions,
  changePage,
  questionType,
  onQuestionChange,
  useragent,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {data, meta} = sessions || {};
  const [page, setPage] = useState(1);
  const {enqueueSnackbar} = useSnackbar();

  const [sessionModal, setSessionModal] = useState(false);
  const [sessionInModal, setSessionInModal] = useState({});

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const [userPackageListModal, setUserPackageListModal] = useState(false);
  const [sessionBillableId, setSessionBillableId] = useState();
  const checkCredit = useSelector((state) => state.package.checkCredit);

  const sessionError = useSelector((state) => state.session.error);
  const sessionErrorMessage = useSelector(
    (state) => state.session.errorMessage,
  );
  const sessionBooking = useSelector((state) => state.session.sessionBooking);

  const useCredit = useSelector((state) => state.package.useCredit);
  const packageError = useSelector((state) => state.package.error);
  const packageErrorMessage = useSelector(
    (state) => state.package.errorMessage,
  );

  const onUpgradeModalCreditSuccess = () => {
    setUserPackageListModal(false);
    enqueueSnackbar(t('notif:upgradedSuccessfully'), {
      key: 'course-loading',
      variant: 'success',
      // persist: true,
    });
    dispatch({type: 'RESET_CHECK_CREDIT'});
  };

  const badgeBoxClass = (type, paidStatus) => {
    if (type === 1) return classes.freeTag;
    if (!type && paidStatus === 'available') return classes.upgradeTag;
    return classes.paidTag;
  };

  const badgeTextClass = (type, paidStatus) => {
    if (type === 1) return classes.freeTagText;
    if (!type && paidStatus === 'available') return classes.upgradeTagText;
    return classes.paidTagText;
  };
  const badgeName = (type, paidStatus) => {
    if (type === 1) return 'Free';
    if (!type && paidStatus === 'available') return 'Available';
    return 'Paid';
  };

  useEffect(() => {
    changePage(page);
  }, [page]);

  useEffect(() => {
    if (packageError || packageErrorMessage) {
      enqueueSnackbar(packageErrorMessage, {
        variant: 'error',
      });
      dispatch({type: 'RESET_USE_CREDIT'});
    }
  }, [packageErrorMessage, packageError]);

  useEffect(() => {
    if (useCredit && useCredit.isSuccess) {
      enqueueSnackbar(t('notif:bookingSuccessfullyDone'), {
        variant: 'success',
      });
      dispatch({type: 'RESET_USE_CREDIT'});
    }
  }, [useCredit]);

  useEffect(() => {
    if (sessionError || sessionErrorMessage) {
      enqueueSnackbar(sessionErrorMessage, {
        variant: 'error',
      });
      dispatch({type: 'RESET_SESSION_BOOKING'});
    }
  }, [sessionErrorMessage, sessionError]);

  useEffect(() => {
    if (sessionBooking) {
      enqueueSnackbar(t('notif:bookingSuccessfullyDone'), {
        variant: 'success',
      });
      dispatch({type: 'RESET_SESSION_BOOKING'});
    }
  }, [sessionBooking]);

  useEffect(() => {
    if (checkCredit?.data && checkCredit?.data.length !== 0) {
      dispatch(
        useCreditAction({
          packageId: checkCredit?.data[0].id,
          entityId: sessionBillableId,
        }),
      );
      // setUserPackageListModal(true);
    } else if (checkCredit?.data && checkCredit?.data.length === 0) {
      enqueueSnackbar(
        t('notif:yourPackageDoseNotContainThisSessionPleaseUpgrade'),
        {
          variant: 'error',
        },
      );
    }
    dispatch({type: 'RESET_CHECK_CREDIT'});
  }, [checkCredit]);

  return (
    <>
      {!data || !meta ? (
        <Grid
          container
          className={classes.root}
          direction="column"
          alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container>
          <Table aria-label="simple table">
            <TableBody>
              {data &&
                data.map((session) => (
                  <TableRow
                    key={session.id}
                    onClick={(e) => {
                      onQuestionChange(e);
                      setSessionInModal(session);
                      setSessionModal(true);
                    }}>
                    <TableCell classes={{root: classes.tableCell}}>
                      <div className={classes.itemContainer}>
                        <div className={classes.col}>
                          <Typography
                            variant="subtitle1"
                            classes={{subtitle1: classes.sessionTitle}}>
                            {pascalCaseConvertor(session.name)}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            className={classes.sessionSubTitle}>
                            {`${moment
                              .utc(session.started_at)
                              .tz(moment.tz.guess())
                              .format(
                                'dddd Do MMMM, YYYY, LT',
                              )} - ${moment.tz.guess()}`}
                          </Typography>
                          <Grid container justify="space-between">
                            <div className={classes.row}>
                              <div
                                className={`${classes.tagBox} ${classes.setsTasgs}`}>
                                <span
                                  className={`${classes.tagBoxText} ${classes.setsTasgsText}`}>
                                  {`${
                                    session.capacity - (session.submits || 0)
                                  } Seats Left`}
                                </span>
                              </div>
                              <div
                                className={`${classes.tagBox} ${badgeBoxClass(
                                  session.is_free,
                                  session.paid_status,
                                )}`}>
                                <span
                                  className={`${
                                    classes.tagBoxText
                                  } ${badgeTextClass(
                                    session.is_free,
                                    session.paid_status,
                                  )}`}>
                                  {badgeName(
                                    session.is_free,
                                    session.paid_status,
                                  )}
                                </span>
                              </div>
                            </div>
                            <button
                              type="button"
                              className={classes.joinBtn}
                              onClick={(e) => {
                                onQuestionChange(e);
                                setSessionInModal(session);
                                setSessionModal(true);
                              }}>
                              Join
                              <ArrowRight
                                color={theme.palette.primary.main}
                                width={10}
                              />
                            </button>
                          </Grid>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Grid container justify="center" alignItems="center">
            <Pagination
              count={meta && meta.pagination && meta.pagination.total_pages}
              variant="outlined"
              shape="rounded"
              color="primary"
              boundaryCount={3}
              page={meta && meta.pagination && meta.pagination.current_page}
              onChange={(e, selectedPage) => setPage(selectedPage)}
              size="large"
              classes={{
                root: classes.paginationRoot,
                ul: classes.paginationUl,
              }}
            />
          </Grid>
        </Grid>
      )}
      <Dialog
        classes={{paper: classes.dialogContainer}}
        open={sessionModal}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setSessionModal(!sessionModal);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogContent style={{width: '100%'}}>
          <DialogContentText>
            <SessionDetail
              session={sessionInModal || {}}
              setSessionBillableId={setSessionBillableId}
              setSessionModal={setSessionModal}
              onCancel={() => setSessionModal(false)}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen={isXs}
        open={userPackageListModal}
        onClose={() => {
          setUserPackageListModal(false);
          // dispatch({type: 'RESET_SESSION_BOOKING'});
          dispatch({type: 'RESET_CHECK_CREDIT'});
          dispatch({type: 'RESET_PACKAGE'});
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          select your package
        </DialogTitle>
        <DialogContent>
          <UserPackageList
            packages={checkCredit?.data}
            sessionBillId={sessionBillableId}
            onCreditSuccess={onUpgradeModalCreditSuccess}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

QuestionTable.propTypes = {
  t: PropTypes.func,
  sessions: PropTypes.array,
  changePage: PropTypes.func,
  questionType: PropTypes.string,
  onQuestionChange: PropTypes.func,
  useragent: PropTypes.object,
};

QuestionTable.defaultProps = {
  t: () => null,
  sessions: [],
  changePage: () => null,
  questionType: '',
  onQuestionChange: () => {},
  useragent: {},
};

export default QuestionTable;

const SessionDetail = ({
  session = {},
  setSessionBillableId,
  setSessionModal,
  onCancel,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {user} = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual,
  );

  const getDuration = (mins) => {
    const h = parseInt(mins / 60, 10) || 0;
    const m = mins % 60 || 0;
    if (h === 0 && m !== 0) return `${m} min`;
    if (m === 0 && h !== 0) return `${h} hrs`;
    return `${h} h ${m} m`;
  };

  const badgeBoxClass = (type, paidStatus) => {
    if (type === 1) return classes.freeTag;
    if (!type && paidStatus === 'available') return classes.upgradeTag;
    return classes.paidTag;
  };

  const badgeTextClass = (type, paidStatus) => {
    if (type === 1) return classes.freeTagText;
    if (!type && paidStatus === 'available') return classes.upgradeTagText;
    return classes.paidTagText;
  };
  const badgeName = (type, paidStatus) => {
    if (type === 1) return 'Free';
    if (!type && paidStatus === 'available') return 'Available';
    return 'Paid';
  };

  return (
    <div style={{width: '100%'}}>
      <div className={classes.dialogTitle}>{session.name}</div>
      <div className={classes.dialogSubTitle}>{`${moment(
        session.started_at,
      ).fromNow()}`}</div>
      <div className={classes.dialogTagSubTitle}>
        <div className={classes.tagContainer}>
          <div className={`${classes.tagBox} ${classes.setsTasgs}`}>
            <span className={`${classes.tagBoxText} ${classes.setsTasgsText}`}>
              {`${session.capacity} Seats Left`}
            </span>
          </div>
          <div
            className={`${classes.tagBox} ${badgeBoxClass(
              session.is_free,
              session.paid_status,
            )}`}>
            <span
              className={`${classes.tagBoxText} ${badgeTextClass(
                session.is_free,
                session.paid_status,
              )}`}>
              {badgeName(session.is_free, session.paid_status)}
            </span>
          </div>
        </div>

        <div className={classes.watchBox}>
          <Watch />{' '}
          <span
            style={{
              paddingLeft: 8,
            }}>
            {getDuration(session.duration)}
          </span>
        </div>
      </div>
      <div className={classes.dialogContent}>
        <SmartText text={session.description} length={100} />
      </div>
      <div className={classes.dialogFooter}>
        <button
          type="button"
          className={`${classes.dialogFooterBtn} ${classes.dialogFooterBtnJoin}`}
          disabled={session.capacity == session.submits}
          onClick={() => {
            if (session.is_free)
              dispatch(bookingSessionAction({sessionId: session.id}));
            else
              dispatch(
                checkCreditAction({
                  id: session.billable_id,
                }),
              );

            setSessionBillableId(session.billable_id);
            setSessionModal(false);
          }}>
          {session.capacity !== session.submits ? 'Join' : 'Booked Out'}
        </button>
        <button type="button" className={classes.cancelBtn} onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

const SmartText = ({text, length = 220}) => {
  const [showLess, setShowLess] = useState(true);

  if (text.length < length) {
    return <p>{text}</p>;
  }

  return (
    <div>
      <div>
        {showLess ? (
          <div
            dangerouslySetInnerHTML={{__html: `${text.slice(0, length)}...`}}
          />
        ) : (
          <div dangerouslySetInnerHTML={{__html: text}} />
        )}
      </div>
      <a
        style={{color: '#0F4C8F', cursor: 'pointer', fontWeight: 600}}
        onClick={() => setShowLess(!showLess)}>
        See {showLess ? 'More' : 'Less'}
      </a>
    </div>
  );
};
