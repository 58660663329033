import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  detail: {
    color: '#808080',
    fontSize: '16px',
  },
  tableContainer: {
    width: '424px',
    marginTop: '20px',
    '@media (max-width: 650px)': {
      width: 'auto',
    },
  },
  tableHeader: {
    fontSize: '16px',
    fontWeight: 700,
  },
}));
const StyledHeaderRow = withStyles((theme) => ({
  root: {
    background: 'rgba(237, 240, 242, 0.2)',
  },
}))(TableRow);

const StyledRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#FAFBFD',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
    '& td': {
      fontWeight: 500,
      fontSize: '16px',
    },
  },
}))(TableRow);

const MockTest = ({useragent, data = []}) => {
  const classes = useStyles();
  return (
    <div>
      <TableContainer component="div" className={classes.tableContainer}>
        <Table aria-label="spanning table">
          <TableHead>
            <StyledHeaderRow>
              <TableCell className={classes.tableHeader} align="left">
                Weeks{' '}
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                No. of Mock Tests
              </TableCell>
            </StyledHeaderRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <StyledRow>
                <TableCell align="left">Week {index + 1}</TableCell>
                <TableCell align="center">{item.rec.charAt(25)}</TableCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

MockTest.propTypes = {};

export default MockTest;
