import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {getStatisticAction} from 'core/lib/adapters/redux/actions';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import Table from './Table';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  mobileRoot: {
    width: '100%',
    overflowX: 'auto',
  },

  skillContainerMobile: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
  skillBox: {
    padding: '20px 15px',
    border: '1px solid #E5E5E5',
    marginRight: 15,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
  },

  suggestedText: {
    color: '#808080',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
  },
}));

const Practice = ({useragent = null, skill = 0}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [modifiedData, setModifiedData] = useState([]);
  const categorized = useSelector((state) => state.questions.categorized) || {};
  const studyPlan = useSelector((state) => state.studyPlan.studyPlan) || {};
  const filtered = useSelector((state) => state.statistic.filtered) || {};
  const recommendedPlan = studyPlan?.Recommended;

  const mapped = {
    ReadAloud: 'Speaking',
    DescribeImage: 'Speaking',
    RepeatSentence: 'Speaking',
    RetellLecture: 'Speaking',
    AnswerShortQuestion: 'Speaking',
    SummarizeWrittenText: 'Writing',
    WriteEssay: 'Writing',
    ReadingMultipleChoiceSingleAnswer: 'Reading',
    ReadingMultipleChoiceMultipleAnswer: 'Reading',
    ReorderParagraph: 'Reading',
    ReadingFillInTheBlanks: 'Reading',
    ReadingAndWritingFillInTheBlanks: 'Reading',
    SummarizeSpokenText: 'Listening',
    ListeningMultipleChoiceSingleAnswer: 'Listening',
    ListeningMultipleChoiceMultipleAnswer: 'Listening',
    ListeningFillInTheBlanks: 'Listening',
    HighlightIncorrectWords: 'Listening',
    HighlightCorrectSummary: 'Listening',
    SelectMissingWord: 'Listening',
    WriteFromDictation: 'Listening',
  };

  useEffect(() => {
    const filterData = filtered['questions-by-type']?.results?.map((i) => ({
      type: i.question_type,
      count: i.count,
      skill: mapped[Object.keys(mapped).find((t) => t === i.question_type)],
    }));
    const practicedData = filtered['practiced-questions-by-type']?.results
      ?.map((i) => ({
        type: i.question_type,
        count: i.count,
        skill: mapped[Object.keys(mapped).find((t) => t === i.question_type)],
      }))
      .reduce((state, item) => {
        state[item.type] = item.count;
        return state;
      }, {});
    const result =
      filterData &&
      practicedData &&
      filterData.map((i) => ({...i, practiced: practicedData[i.type]}));
    if (filterData) setModifiedData(result);
  }, [filtered]);

  useEffect(() => {
    dispatch(
      getStatisticAction({
        type: 'count',
        params: {
          blocks: 'questions-by-type,practiced-questions-by-type',
          period: '',
          start: '',
          end: '',
        },
      }),
    );
  }, []);

  return (
    <Grid className={classes.mobileRoot}>
      <Grid container item xs={12}>
        <Table
          useragent={useragent}
          data={
            modifiedData &&
            modifiedData.filter((i) => {
              if (skill === 0) return i.skill === 'Speaking';
              if (skill === 1) return i.skill === 'Writing';
              if (skill === 2) return i.skill === 'Reading';
              if (skill === 3) return i.skill === 'Listening';
              return i.skill === 'Speaking';
            })
          }
        />
      </Grid>
    </Grid>
  );
};

Practice.propTypes = {
  useragent: PropTypes.object,
};

Practice.defaultProps = {
  t: () => null,
  onQuestionChange: () => {},
  useragent: {},
  sidebar: false,
};

export default Practice;
