import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useRouter} from 'next/router';
import LoadingIndicator from './LoadingIndicator';

const WithAuthHoc = ({children}) => {
  const auth = useSelector((state) => state.auth);
  const [canProceed, setCanProceed] = useState(auth.isAuth);
  const router = useRouter();
  useEffect(() => {
    setCanProceed(auth.isAuth);
    if (!auth.isAuth) {
      if (router.asPath) router.push(`/auth/login?return=${router.asPath}`);
      else router.push('/auth/login');
    }
  }, [auth]);
  if (canProceed) return <>{children}</>;
  return (
    <>
      <LoadingIndicator />
    </>
  );
};

WithAuthHoc.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export default WithAuthHoc;
