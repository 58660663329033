import {
  VERIFY_EMAIL_ACTION,
  LOGIN_ACTION,
  FORGET_PASSWORD_ACTION,
  RESET_PASSWORD_ACTION,
  REGISTER_ACTION,
  LOG_OUT_ACTION,
  LOGIN_ACTION_SUCCESS,
  CLEAR_AUTH_ERROR,
  GET_PROFILE_ACTION,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  SET_DEVICE_TOKEN,
} from './actions'

export const verifyEmailAction = ({email, token}) => ({
  type: VERIFY_EMAIL_ACTION,
  payload: {
    email,
    token,
  },
});

export const registerAction = ({email, name, password}) => ({
  type: REGISTER_ACTION,
  payload: {
    email,
    name,
    password,
  },
});

export const loginAction = ({email, password}) => ({
  type: LOGIN_ACTION,
  payload: {
    email,
    password,
  },
});

export const loginSucessAction = (payload) => ({
  type: LOGIN_ACTION_SUCCESS,
  payload,
});

export const forgetPasswordAction = ({email}) => ({
  type: FORGET_PASSWORD_ACTION,
  payload: {
    email,
  },
});

export const resetPasswordAction = ({email, password, verificationCode}) => ({
  type: RESET_PASSWORD_ACTION,
  payload: {
    email,
    password,
    verificationCode,
  },
});

export const logoutAction = () => ({
  type: LOG_OUT_ACTION,
});

export const clearAuthError = () => ({
  type: CLEAR_AUTH_ERROR,
});

export const getProfile = () => ({
  type: GET_PROFILE_ACTION
});

export const updateProfile = (payload) => ({
  type: UPDATE_PROFILE,
  payload,
});

export const changePasswordAction = (payload) => ({
  type: CHANGE_PASSWORD,
  payload,
});

export const setDeviceTokenAction = (payload) => ({
  type: SET_DEVICE_TOKEN,
  payload,
});
