import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 120,
    minHeight: 38,
    textTransform: 'none',
  },
  disabled: {
    background: 'transparent',
  },
}));
const LoadingButton = ({
  type,
  loading,
  message,
  className,
  onClick,
  disabledClassName,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      type={type}
      disabled={loading || disabled}
      className={clsx(classes.button, className)}
      classes={{
        disabled: disabledClassName || classes.disabled,
      }}
      onClick={onClick}>
      {!loading && message}
      {loading && <CircularProgress size={24} />}
    </Button>
  );
};

LoadingButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  message: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  disabledClassName: PropTypes.string,
};
LoadingButton.defaultProps = {
  type: 'button',
  onClick: null,
  className: null,
  disabledClassName: null,
  disabled: false,
};

export default LoadingButton;
