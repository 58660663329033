import * as React from 'react'

function SvgAiAnalysisImage(props) {
  return (
    <svg width={72} height={72} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#F0FAFD" fillRule="nonzero" cx={36} cy={36} r={36} />
        <path
          d="M22.38 16.714h24.668a5.709 5.709 0 015.667 5.751v27.07a5.71 5.71 0 01-5.667 5.75H22.38a5.709 5.709 0 01-5.666-5.75v-27.07a5.71 5.71 0 015.665-5.75h.001z"
          opacity={0.9}
          fill="#57D6F8"
          fillRule="nonzero"
        />
        <g transform="translate(19.158 19.162)" fillRule="nonzero">
          <rect
            fill="#FFF"
            x={20.406}
            y={22.731}
            width={9.055}
            height={1.286}
            rx={0.354}
          />
          <rect
            fill="#FFF"
            opacity={0.98}
            x={0.126}
            y={0.123}
            width={30.857}
            height={33.429}
            rx={3}
          />
          <rect
            fill="#65D2F0"
            x={21.671}
            y={3.416}
            width={7.019}
            height={8.212}
            rx={1}
          />
          <rect
            fill="#67D2F0"
            opacity={0.95}
            x={2.814}
            y={3.416}
            width={16.079}
            height={3.303}
            rx={1}
          />
          <rect
            fill="#B3E8F7"
            opacity={0.95}
            x={2.814}
            y={9.085}
            width={12.684}
            height={2.415}
            rx={0.939}
          />
          <rect
            fill="#F5F6F7"
            opacity={0.95}
            x={2.813}
            y={19.781}
            width={15.101}
            height={2.415}
            rx={0.939}
          />
          <rect
            fill="#F5F6F7"
            opacity={0.95}
            x={2.813}
            y={25.03}
            width={11.479}
            height={2.415}
            rx={0.939}
          />
          <rect
            fill="#F5F6F7"
            opacity={0.95}
            x={2.813}
            y={14.638}
            width={17.672}
            height={2.415}
            rx={0.939}
          />
        </g>
        <path
          d="M43.457 36.97a2.571 2.571 0 014.372 0l8.889 14.393a2.571 2.571 0 01-2.186 3.923H36.753a2.571 2.571 0 01-2.185-3.923l8.89-14.392z"
          fill="#FFCA27"
          fillRule="nonzero"
        />
        <text
          transform="translate(42.429 36)"
          fill="#FFF"
          fontFamily="ArialRoundedMTBold, Arial Rounded MT Bold"
          fontSize={14}
        >
          <tspan x={0} y={15.286}>
            {'!'}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default SvgAiAnalysisImage
