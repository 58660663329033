import * as React from 'react'

function SvgSpeaking(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.172 1.172A4 4 0 0116 4v8a4 4 0 11-8 0V4a4 4 0 011.172-2.828zM12 2a2 2 0 00-2 2v8a2 2 0 004 0V4a2 2 0 00-2-2z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9a1 1 0 011 1v2a6 6 0 1012 0v-2a1 1 0 112 0v2a8 8 0 01-16 0v-2a1 1 0 011-1z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 23a1 1 0 011-1h8a1 1 0 110 2H8a1 1 0 01-1-1z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgSpeaking
