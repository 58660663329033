import * as React from 'react'

function SvgTwitter(props) {
  return (
    <svg width="1em" height="1em" fill="currentColor" {...props}>
      <g fill="#A2A2A3">
        <path d="M27.934 16.2v-.533c.854-.7 1.616-1.507 2.267-2.4a7.892 7.892 0 01-2.533.667 4.775 4.775 0 002-2.533 7.182 7.182 0 01-2.8 1.067 4.259 4.259 0 00-3.2-1.467 4.5 4.5 0 00-4.4 4.667c-.022.36.023.723.133 1.067a12.151 12.151 0 01-9.067-5.067 4.566 4.566 0 00-.533 2.4 5.118 5.118 0 002 4 3.592 3.592 0 01-2-.667 4.622 4.622 0 003.6 4.667c-.39.11-.795.155-1.2.133a1.691 1.691 0 01-.8-.133 4.566 4.566 0 004.133 3.333 8.865 8.865 0 01-5.467 2A2.936 2.936 0 019 27.268a12.09 12.09 0 006.8 2.133c7.734-.001 12.134-7.068 12.134-13.201z" />
        <path d="M20 40A19.988 19.988 0 1120.025.024 19.988 19.988 0 0120 40zm0-38.72C9.661 1.28 1.28 9.661 1.28 20c0 10.339 8.381 18.72 18.72 18.72 10.339 0 18.72-8.381 18.72-18.72C38.72 9.661 30.339 1.28 20 1.28z" />
      </g>
    </svg>
  )
}

export default SvgTwitter
