import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Button } from '@material-ui/core';
import note from '../../../assets/icons/note.svg';
import reward from '../../../assets/icons/reward.svg';
import dateCal from '../../../assets/icons/dateCal.svg';
import target from '../../../assets/icons/target.svg';
import Modals from 'components/StudyPlan/Modals';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    targetBox: {
        backgroundColor: "#ffffff",
        borderRadius: "50px",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "background-color 0.2s",
        '&:hover': {
            '& $iconBg': {
                backgroundColor: theme.palette.primary.main,
                color: '#FFFFFF',
            },
            '& $commanIconFilter': {
                filter: 'brightness(100)'
            },
            '& $arrowIcon': {
                opacity: '1',
                transform: 'translateX(-10px)',
                transition: 'transform 0.5s, marginLeft 0.5s',
                marginRight: '3px',
            },
        },
    },
    buttonAni: {
        // margin: '100px',
        // border: '1px solid black',
        // borderRadius: '3px',
        // width: '100px',
        // height: '30px',
        // display: 'block',
        background: 'linear-gradient(to right, #fff 50%, white 50%)',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all .5s ease-out',
        '&:hover': {
            backgroundPosition: 'left bottom',
        },
    },
    targetBoxFinal: {
        borderRadius: "50px",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: 'linear-gradient(to right, #ffffff 50%, #0F4C8F 50%)',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all .5s ease-out',
        cursor: 'pointer',
        '&:hover': {
            backgroundPosition: 'left bottom',
            '& $finalIconbg': {
                backgroundColor: theme.palette.primary.main,
                color: '#FFFFFF',
            },
            '& $commanIconFilter': {
                filter: 'brightness(100)'
            },
            '& $finalBoxtTitle': {
                color: theme.palette.primary.main
            }
        },
    },
    arrowIcon: {
        opacity: '0',
        fontSize: "12px",
        marginLeft: 0,
        transform: 'translateX(0px)',
        transition: "all 1s",
    },
    commanIconFilter: {
        filter: 'brightness(100%)',
        width: '22px',
        height: '22px',
        margin: '20px',
        '@media (max-width: 1700px)': {
            width: '18px',
            height: '18px',
        }
    },
    // iconFilter: {
    //     filter: 'brightness(100%)',
    //     width: '22px',
    //     height: '22px',
    //     '@media (max-width: 1700px)': {
    //         width: '18px',
    //         height: '18px',
    //     }
    // },
    // finalIconFilter: {
    //     filter: 'brightness(100%)',
    //     width: '22px',
    //     height: '22px',
    //     margin: '20px',
    //     '@media (max-width: 1700px)': {
    //         width: '18px',
    //         height: '18px',
    //     }
    // },
    iconBg: {
        backgroundColor: "#e7eef4",
        //  height: "74px",
        // width: "74px",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        // transition: "background-color 0.2s",
        // '@media (max-width: 568px)': {
        //     maxHeight: "50px",
        //     maxWidth: "50px",
        // },
    },
    commanIconBg: {
        borderRadius: "50%",
        maxHeight: "74px",
        maxWidth: "74px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "background-color 0.2s",
        '@media (max-width: 1700px)': {
            height: "54px",
            width: "54px",
        },
        '@media (max-width: 568px)': {
            maxHeight: "50px",
            maxWidth: "50px",
        },
    },
    finalIconbg: {
        backgroundColor: "#e7eef4",
    },
    body2Typography: {
        fontWeight: "600",
        color: '#808080',
        lineHeight: 'normal',
        '@media (max-width: 568px)': {
            fontSize: '13px',
        },
    },
    titleTypography: {
        fontWeight: "600",
        color: theme.palette.primary.main,
        '@media (max-width: 1700px)': {
            fontSize: '16px',
        },
        '@media (max-width: 568px)': {
            fontSize: '13px',
        },
    },
    finalBoxtTitle: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#fff',
        '@media (max-width: 1700px)': {
            fontSize: '16px',
        },
        '@media (max-width: 568px)': {
            fontSize: '13px',
        },
    },

}));


const ExamTarget = ({ renderStudyPlanBtn, setShowupModalAction, useragent, isInDashboard = false }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const modalIsShow = useSelector((state) => state.studyPlan.modalIsShow);
    const handleClick = () => {
        dispatch(setShowupModalAction({ statuse: true }));
    };
    console.log('modalIsShow :>> ', modalIsShow);
    const data = [
        { icon: note, label: 'Today Practiced', value: user?.today_practiced },
        { icon: reward, label: 'Total Practiced', value: user?.total_practiced },
        { icon: dateCal, label: 'Practice Days', value: user?.practice_days},
        { icon: target, onClick: handleClick, value: user.has_study_plan ? 'Reset Your Exam Goal' : 'Set Your Exam Goal', finalBox: true },
    ];
    return (
        <>
            <Grid container spacing={2} style={{ marginTop: "10px", marginBottom: "12px" }} >
                {data.map((item, index) => (
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6} key={index} style={{ padding: '6px' }}>
                        <Box className={item?.finalBox ? classes.targetBoxFinal : classes.targetBox}
                            onClick={item.onClick} >
                            <Box style={{ display: "flex", gap: "6px", alignItems: "center" }}>
                                <Box
                                    className={clsx(classes.commanIconBg, item?.finalBox ? classes.finalIconbg : classes.iconBg)}
                                // className={item?.finalBox ? classes.finalIconbg : classes.iconBg} 
                                >
                                    <img src={item?.icon} alt='icon' className={clsx(classes.commanIconFilter, item?.finalBox ? classes.finalIconFilter : classes.iconFilter)} />
                                </Box>
                                <Box>
                                    <Typography variant='body2' className={classes.body2Typography}>{item?.label}</Typography>
                                    <Typography variant='h5' className={item?.finalBox ? classes.finalBoxtTitle : classes.titleTypography}>{item?.value}</Typography>
                                </Box>
                            </Box>
                            {!item?.finalBox && <ArrowForwardIosIcon className={classes.arrowIcon} />}
                        </Box>
                    </Grid>
                ))}
            </Grid>
            {/* <Button
                variant="contained"
                className={classes.recreateBtn}
                onClick={() => dispatch(setShowupModalAction({ statuse: true }))}
                elevation={0}>
                {renderStudyPlanBtn()}
            </Button> */}
            <Modals />
        </>
    );
};

ExamTarget.propTypes = {
    useragent: PropTypes.object,
    isInDashboard: PropTypes.bool,
};

ExamTarget.defaultProps = {
    useragent: {},
    isInDashboard: false,
};

export default ExamTarget;
