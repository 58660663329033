import * as React from 'react';

function SvgCircleCheck(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.596436 11C0.596436 17.0751 5.4781 22 11.4999 22C14.3917 22 17.1651 20.8411 19.2099 18.7782C21.2547 16.7153 22.4035 13.9174 22.4035 11C22.4035 4.92487 17.5218 0 11.4999 0C5.4781 0 0.596436 4.92487 0.596436 11ZM2.57889 11C2.57889 6.02944 6.57298 2 11.4999 2C16.4269 2 20.421 6.02944 20.421 11C20.421 15.9706 16.4269 20 11.4999 20C6.57298 20 2.57889 15.9706 2.57889 11Z"
        fill={props.color}
      />
      <path
        d="M9.51743 16C9.25396 16.0015 9.00072 15.8972 8.81366 15.71L5.83997 12.71C5.45129 12.3179 5.45129 11.6821 5.83997 11.29C6.22866 10.8979 6.85884 10.8979 7.24752 11.29L9.51743 13.59L15.2566 7.79C15.6453 7.39788 16.2755 7.39788 16.6642 7.79C17.0529 8.18212 17.0529 8.81788 16.6642 9.21L10.2212 15.71C10.0341 15.8972 9.7809 16.0015 9.51743 16Z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgCircleCheck;
