import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {useSnackbar} from 'notistack';
import {useRouter} from 'next/router';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  getCourseAction,
  checkCreditAction,
  useCreditAction,
} from 'core/lib/adapters/redux/actions';
import {useTranslation} from 'react-i18next';
import ResourceModal from './Modals/ResourceModal';
import SingleQuestionModal from './Modals/SingleQuestionModal';
import MultipleQuestionsModal from './Modals/MultipleQuestionsModal';
import VideoModal from './Modals/VideoModal';

const useStyles = makeStyles(() => ({
  root: {
    padding: 15,
    background: '#E5E5E5',
    borderRadius: '10px',
    cursor: 'pointer',
    minHeight: 110,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  head: {
    fontSize: 14,
    fontWeight: 600,
    margin: '30px 0 20px 0',
    overflowWrap: 'break-word',
    // textTransform: 'capitalize',
  },
  content: {
    fontSize: 12,
    fontWeight: 600,
    color: '#636363',
  },
  badgeRoot: {
    width: '100%',
  },
  badge: {
    float: 'right',
    padding: '5px 10px',
    borderRadius: '6px',
    color: 'white',
    display: 'flex',
    height: '22px',
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
  },
  greenBadge: {
    background: '#00AC5A',
  },
  blueBadge: {
    background: '#0275D8',
  },
  yellowBadge: {
    background: '#B6A82D',
  },
}));

const CourseBoxItem = (props) => {
  const {t} = useTranslation();
  const router = useRouter();
  const styles = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const courses = useSelector((state) => state.course.course) || {
    [props.id]: {loading: true},
  };
  const course = useSelector((state) => state.course);
  // used this only to check loading state
  const packages = useSelector((state) => state.package.checkCredit);
  const checkCreditSuccess = useSelector(
    (state) => state.package.checkCreditSuccess,
  );
  const [showModal, setShowModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [clickedStep3, setClickedStep3] = useState(false);

  const badgeClass = (type, paidStatus) => {
    if (type === 1) return styles.greenBadge;
    if (!type && paidStatus === 'available') return styles.blueBadge;
    return styles.yellowBadge;
  };
  const badgeName = (type, paidStatus) => {
    if (type === 1) return 'Free';
    if (type === 0 && paidStatus === 'available') return 'Available';
    return 'Upgrade';
  };

  // STEP 1
  const handleClick = () => {
    enqueueSnackbar(t('notif:loading'), {
      key: 'course-loading',
      variant: 'info',
      // persist: true,
    });
    dispatch(getCourseAction({id: props.id}));
    setClicked(true);
  };

  // STEP 2
  // check if the course is free or not
  useEffect(() => {
    if (clicked && course && course.course) {
      if (course.errorMessage && course.errorMessage === 'Unauthorized.') {
        dispatch(checkCreditAction({id: props.billId}));
        closeSnackbar('course-loading');
        setClicked(false);

        setClickedStep3(true);
      } else if (course.course[props.id] && !course.course[props.id].loading) {
        if (
          !course.course[props.id].loading &&
          course.course[props.id]?.category?.name === 'Knowledge Tests' &&
          course.course[props.id]?.questions?.data?.length === 0
        ) {
          closeSnackbar('course-loading');
          setClicked(false);

          enqueueSnackbar(t('notif:thisCourseDontHaveAnyQuestions'), {
            variant: 'error',
            // persist: true,
          });
        } else {
          closeSnackbar('course-loading');
          setClicked(false);

          if (props.categoryType === 'Training Videos') {
            setVideoModal(true);
          } else {
            setShowModal(true);
          }
        }
      }

      return () => {
        setClicked(false);
        closeSnackbar('course-loading');
      };
    }
  }, [course, clicked]);

  // STEP 3
  // if course is not free then:
  useEffect(() => {
    if (
      clickedStep3 &&
      packages &&
      packages.data &&
      checkCreditSuccess !== null
    ) {
      // if she has no package alert her to buy packages
      if (packages.data.length === 0) {
        enqueueSnackbar(
          t('notif:toAccessToThisCoursePleaseUpgradeYourPackage'),
          {
            variant: 'error',
          },
        );
        router.push('/subscribe');
      } else {
        dispatch(
          useCreditAction({
            packageId: packages.data[0].id,
            entityId: props.billId,
          }),
        );
        enqueueSnackbar(t('notif:upgradedSuccessfully'), {
          variant: 'success',
        });
        handleClick();
        // TODO success check
      }

      setClickedStep3(false);

      return () => {
        setClickedStep3(false);
      };
    }
  }, [packages, checkCreditSuccess, clickedStep3]);

  return (
    <>
      <div className={styles.root} onClick={handleClick}>
        <div>
          <div className={styles.badgeRoot}>
            <span
              className={`${styles.badge} ${badgeClass(
                props.type,
                props.paidStatus,
              )}`}>
              {badgeName(props.type, props.paidStatus)}
            </span>
          </div>
          <div className={styles.head}>{props.title}</div>
        </div>

        <div className={styles.content}>
          {props.status
            ? `Completed on ${moment(props.status).format('DD-MM-YYYY')}`
            : 'Not Attempted Yet'}
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        fullScreen={
          (props.ua.isMobile || props.ua.isTablet) &&
          (props.categoryType === 'Knowledge Tests' ||
            props.categoryType === 'Practice Questions')
            ? fullScreen
            : false
        }
        open={showModal}
        onClose={() => setShowModal(false)}>
        {props.categoryType === 'Practice Questions' &&
          courses[props.id] &&
          !courses[props.id].loading && (
            <SingleQuestionModal
              question={courses[props.id].questions.data}
              onExit={() => setShowModal(false)}
              ua={props.ua}
              indexCourse={props.indexCourse}
            />
          )}
        {props.categoryType === 'Learning Resources' &&
          courses[props.id] &&
          !courses[props.id].loading && (
            <ResourceModal
              content={courses[props.id].content}
              title={courses[props.id].title}
              useragent={props.ua}
            />
          )}
        {props.categoryType === 'Knowledge Tests' &&
          courses[props.id] &&
          !courses[props.id].loading && (
            <MultipleQuestionsModal
              title={courses[props.id]?.title}
              questions={courses[props.id].questions.data}
              onExit={() => setShowModal(false)}
              ua={props.ua}
              mainCategory={props.mainCategory}
              subCategory={props.subCategory}
            />
          )}
        {props.categoryType === 'Training Videos' && (
          <VideoModal
            onClose={() => setShowModal(false)}
            ua={props.ua}
            data={courses[props.id]}
          />
        )}
      </Dialog>
      <Dialog
        // style={!props.ua.isDesktop && {height: 400}}
        fullWidth
        maxWidth="md"
        fullScreen={false}
        open={videoModal}
        onClose={() => setVideoModal(false)}>
        <VideoModal
          onClose={() => setVideoModal(false)}
          ua={props.ua}
          data={courses[props.id]}
        />
      </Dialog>
    </>
  );
};

CourseBoxItem.propTypes = {
  type: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  billId: PropTypes.number.isRequired,
  status: PropTypes.string,
  categoryType: PropTypes.string.isRequired,
  ua: PropTypes.object.isRequired,
  paidStatus: PropTypes.string,
};

export default CourseBoxItem;
