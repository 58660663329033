import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import {useSnackbar} from 'notistack';
import LoadingButton from '../../LoadingButton';
import LoadingIndicator from '../../LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  navigator: {
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.main,
    background: '#fff !important',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14.4px',
    height: '38px',
    '&:hover': {
      background: '#fff',
    },
  },
  aiScore: {
    width: 120,
    textTransform: 'none',
    fontWeight: 600,
  },
}));
const CourseNavigator = ({
  t,
  onExit,
  onRedo,
  onNext,
  onPrev,
  onAI,
  onAnswer,
  answerDisabled,
  submittingAnswer,
  navigating,
  navType,
  nextDisabled,
  prevDisabled,
  showShareResponse,
  saveAndExit,
  shareResponse,
  shareResponseDisabled,
  aiCorrector,
  mockAnswer,
  aiLoading,
  aiDisabled,
}) => {
  const classes = useStyles();
  const [exitDisabled, setExitDisabled] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  // const shareResponseHandler = () => {
  //   if (shareResponseDisabled) {
  //     enqueueSnackbar('Please First Submit Your Answer', {variant: 'error'})
  //   } else shareResponse();
  // };

  if (navType === 'singleQuestion') {
    return (
      <Grid container item xs={12} className={classes.navigator}>
        <Grid item xs container justify="flex-start">
          {saveAndExit && (
            <Button className={classes.button} onClick={onRedo}>
              {t('Exit')}
            </Button>
          )}
          {!mockAnswer ? (
            <Button className={classes.button} onClick={onRedo}>
              {t('redo')}
            </Button>
          ) : null}
          {showShareResponse ? (
            <Button className={classes.button} onClick={shareResponseHandler}>
              {t('shareResponse')}
            </Button>
          ) : null}
        </Grid>
        <Grid item xs container justify="center">
          {!mockAnswer ? (
            <LoadingButton
              onClick={onAnswer}
              className={classes.button}
              loading={submittingAnswer}
              disabled={answerDisabled}
              disabledClassName={classes.button}
              message={t('submit')}
            />
          ) : null}
          <LoadingButton
            className={clsx(classes.button, classes.aiScore)}
            onClick={onAI}
            message={aiCorrector ? t('aiScore') : t('score')}
            loading={aiLoading}
            disabled={aiDisabled}
          />
        </Grid>
        <Grid item xs container justify="flex-end">
          <Button
            className={classes.button}
            onClick={() => {
              setExitDisabled(true);
              onExit();
            }}
            disabled={exitDisabled}>
            {t('exit')}
          </Button>
        </Grid>
      </Grid>
    );
  }
  if (navType === 'multipleQuestion') {
    return (
      <Grid container item xs={12} className={classes.navigator}>
        <Grid item xs container justify="flex-start">
          <Button
            className={classes.button}
            onClick={() => {
              setExitDisabled(true);
              onExit();
            }}>
            {t('exit')}
          </Button>
        </Grid>
        <Grid item xs container justify="center" />
        <Grid item xs container justify="flex-end">
          <LoadingButton
            className={classes.button}
            message={t('prev')}
            loading={navigating}
            onClick={onPrev}
            disabled={prevDisabled}
          />
          <LoadingButton
            message={t('next')}
            className={classes.button}
            // loading={navigating}
            loading={submittingAnswer}
            onClick={onNext}
            disabled={nextDisabled}
            disabledClassName={classes.button}
          />
        </Grid>
      </Grid>
    );
  }
  if (navType === 'multipleQuestionLastPage') {
    return (
      <Grid container item xs={12} className={classes.navigator}>
        <Grid item xs container justify="flex-start" />
        <Grid item xs container justify="center" />
        <Grid item xs container justify="flex-end">
          <LoadingButton
            className={classes.button}
            message={t('prev')}
            loading={navigating}
            onClick={onPrev}
            disabled={prevDisabled}
          />
          <LoadingButton
            onClick={() => {
              onNext();
              onAnswer();
            }}
            className={classes.button}
            loading={submittingAnswer}
            disabled={answerDisabled}
            disabledClassName={classes.button}
            message={t('submit')}
          />
        </Grid>
      </Grid>
    );
  }
};

CourseNavigator.propTypes = {
  t: PropTypes.func.isRequired,
  onExit: PropTypes.func,
  onRedo: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onAI: PropTypes.func,
  onAnswer: PropTypes.func,
  answerDisabled: PropTypes.bool,
  submittingAnswer: PropTypes.bool,
  navigating: PropTypes.bool,
  prevDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  saveAndExit: PropTypes.bool,
  showShareResponse: PropTypes.bool,
  shareResponse: PropTypes.func,
  shareResponseDisabled: PropTypes.bool,
  aiCorrector: PropTypes.bool,
  mockAnswer: PropTypes.bool,
  navType: PropTypes.string,
};

CourseNavigator.defaultProps = {
  mockAnswer: false,
  aiCorrector: false,
  shareResponseDisabled: false,
  shareResponse: () => {},
  showShareResponse: false,
  nextDisabled: false,
  prevDisabled: false,
  navigating: false,
  saveAndExit: false,
  submittingAnswer: false,
  answerDisabled: false,
  onAnswer: () => {},
  onAI: () => {},
  onRedo: () => {},
  onPrev: () => {},
  onNext: () => {},
  onExit: () => {},
  navType: 'singleQuestion',
};

export default CourseNavigator;
