import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles, Grid, CircularProgress} from '@material-ui/core';
import {getPackageIndexAction} from 'core/lib/adapters/redux/actions';
import PackageItem from './BasePackage';
import AiPackage from './AiPackage';

const useStyles = makeStyles((theme) => ({
  desktopContainer: {
    padding: '20px 15px',
    width: '100%',
  },
  mobileHeader: {
    width: '100%',
    background: '#d2d0d0',
    padding: '15px 15px',
  },
  tabButton: {
    height: 37,
    background: '#fff',
    fontSize: 15,
    fontWeight: 500,
    color: '#6B7280',
    padding: '7px 4px',
    margin: '8px 6px',
    textAlign: 'center',
    border: 'none',
    outline: 'none',
  },
  activeTab: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  loadingContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Subscriptions = ({useragent, onSelectPackage}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [basicSelectedPackage, setBasicSelectedPackage] = useState(null);
  const [extraSelectedPackage, setExtraSelectedPackage] = useState(null);
  const [basicPackage, setBasicPackage] = useState([]);
  const [coursePackage, setCoursePackage] = useState([]);
  const [mockPackage, setMockPackage] = useState([]);
  const [aiPackage, setAiPackage] = useState([]);
  const packageList = useSelector((state) => state.package.packages) || {};
  const data = packageList?.data || [];

  useEffect(() => {
    if (data) {
      const base = data.filter((item) => item.is_base === 1);
      setBasicPackage(base);
      setBasicSelectedPackage(base[0]);
      const course = data.filter(
        (item) => item.is_base !== 1 && item.tags.includes('course-package'),
      );
      setCoursePackage(course);
      const mock = data.filter(
        (item) => item.is_base !== 1 && item.tags.includes('mock-package'),
      );
      setMockPackage(mock);
      const ai = data.filter(
        (item) => item.is_base !== 1 && item.tags.includes('ai-package'),
      );
      setAiPackage(ai);
      setExtraSelectedPackage(course[0]);
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      getPackageIndexAction({
        page: 1,
        per_page: 30,
      }),
    );
  }, []);

  if (packageList && packageList.loading) {
    return (
      <Grid className={classes.loadingContainer}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {useragent.isDesktop ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.desktopContainer}>
          <Grid container spacing={3}>
            {basicPackage.map((item) => (
              <Grid item md={3} xs={12}>
                <PackageItem
                  data={item}
                  bookPackage={(info) => onSelectPackage(info)}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container style={{marginTop: 30}} spacing={3}>
            {coursePackage.map((item) => (
              <Grid item md={3} xs={12}>
                <PackageItem
                  data={item}
                  bookPackage={(info) => onSelectPackage(info)}
                />
              </Grid>
            ))}
            {mockPackage.map((item) => (
              <Grid item md={3} xs={12}>
                <PackageItem
                  data={item}
                  bookPackage={(info) => onSelectPackage(info)}
                />
              </Grid>
            ))}
            <Grid item md={3} xs={12}>
              {aiPackage && aiPackage.length > 0 && (
                <AiPackage
                  bookPackage={(info) => onSelectPackage(info)}
                  data={aiPackage}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} container className={classes.mobileHeader}>
            {basicPackage.map((item) => (
              <button
                type="button"
                className={clsx(classes.tabButton, {
                  [classes.activeTab]: basicSelectedPackage?.name === item.name,
                })}
                onClick={() => setBasicSelectedPackage(item)}>
                {item.name}
              </button>
            ))}
          </Grid>
          {basicSelectedPackage && (
            <PackageItem
              data={basicSelectedPackage}
              bookPackage={(info) => onSelectPackage(info)}
            />
          )}
          <Grid item xs={12} container className={classes.mobileHeader}>
            {[...coursePackage, ...mockPackage].map((item) => (
              <button
                type="button"
                className={clsx(classes.tabButton, {
                  [classes.activeTab]: extraSelectedPackage?.name === item.name,
                })}
                onClick={() => setExtraSelectedPackage(item)}>
                {item.name}
              </button>
            ))}
            <button
              type="button"
              className={clsx(classes.tabButton, {
                [classes.activeTab]: extraSelectedPackage === 'ai',
              })}
              onClick={() => setExtraSelectedPackage('ai')}>
              AI Tokens
            </button>
          </Grid>
          {extraSelectedPackage && extraSelectedPackage !== 'ai' ? (
            <PackageItem
              bookPackage={(info) => onSelectPackage(info)}
              data={extraSelectedPackage}
            />
          ) : (
            <>
              {aiPackage && aiPackage.length > 0 && (
                <AiPackage
                  bookPackage={(info) => onSelectPackage(info)}
                  data={aiPackage}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Subscriptions.propTypes = {
  onSelectPackage: PropTypes.func.isRequired,
  useragent: PropTypes.object,
};

export default Subscriptions;
