import React from 'react';
import Head from 'next/head';

const EduBenchmarkHoc = ({ title,description, children }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="og:description"
          content={description}
        />
        <meta
          property="og:title"
          content={title}
        />
      </Head>
      {children}
    </>
  );
};
export default EduBenchmarkHoc;
