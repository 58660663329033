import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {getStudyPathwayAction} from 'core/lib/adapters/redux/actions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import NoStudyPlan from 'components/StudyPlan/noStudyPlan';
import Skill from '../SubCatagoryContents/Skill';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '20px 0px',
    width: '100%',
  },
  mobileRoot: {
    padding: '20px 0px',
    width: '100%',
    // overflowX: 'auto',
  },

  skillContainerMobile: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
  skillBox: {
    padding: '10px 15px',
    border: '1px solid #E5E5E5',
    marginRight: 15,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
  },
  skillBoxMobile: {
    padding: '10px 10px',
    border: '1px solid #E5E5E5',
    marginRight: 8,
    cursor: 'pointer',
    fontSize: 10,
  },
  suggestedText: {
    color: '#808080',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
  },
  suggestedTextMo: {
    color: '#808080',
    fontSize: '0.9em',
    fontWeight: 500,
    marginTop: 20,
  },
}));

const Training = ({useragent = null, isInDashboard = false}) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const studyPathway =
    useSelector((state) => state.studyPlan.studyPathway) || {};
  const data = (studyPathway && studyPathway.study_pathway) || [];
  const [speaking, setSpeaking] = useState([]);
  const [reading, setReading] = useState([]);
  const [writing, setWriting] = useState([]);
  const [listening, setListening] = useState([]);

  const [activeSkill, setActiveSkill] = useState('Speaking');
  const [activePath, setActivePath] = useState();

  useEffect(() => {
    if (data && data.length > 0) {
      data.forEach((element) => {
        if (Object.keys(element)[0] === 'Speaking')
          setSpeaking((prev) => [...prev, element.Speaking]);
        else if (Object.keys(element)[0] === 'Writing')
          setWriting((prev) => [...prev, element.Writing]);
        else if (Object.keys(element)[0] === 'Listening')
          setListening((prev) => [...prev, element.Listening]);
        else if (Object.keys(element)[0] === 'Reading')
          setReading((prev) => [...prev, element.Reading]);
      });
    }
  }, [data]);

  useEffect(() => {
    dispatch(getStudyPathwayAction());
  }, []);

  useEffect(() => {
    if (activeSkill === 'Speaking') setActivePath(speaking);
    else if (activeSkill === 'Writing') setActivePath(writing);
    else if (activeSkill === 'Reading') setActivePath(reading);
    else if (activeSkill === 'Listening') setActivePath(listening);
  }, [activeSkill, speaking, writing, reading, listening]);

  if (!data || !data.length) {
    return <NoStudyPlan />;
  }

  return (
    <>
      {useragent.isMobile || useragent.isTablet ? (
        <>
          <Grid className={classes.mobileRoot}>
            <Grid
              className={classes.skillContainerMobile}
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start">
              <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBoxMobile}
                style={{
                  borderBottom:
                    activeSkill === 'Speaking' ? '3px solid #0F4C8F' : '',
                }}
                onClick={() => setActiveSkill('Speaking')}
                xs={2}>
                Speaking
              </Grid>
              <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBoxMobile}
                style={{
                  borderBottom:
                    activeSkill === 'Writing' ? '3px solid #0F4C8F' : '',
                }}
                onClick={() => setActiveSkill('Writing')}
                xs={2}>
                Writing
              </Grid>
              <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBoxMobile}
                style={{
                  borderBottom:
                    activeSkill === 'Reading' ? '3px solid #0F4C8F' : '',
                }}
                onClick={() => setActiveSkill('Reading')}
                xs={2}>
                Reading
              </Grid>
              <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBoxMobile}
                style={{
                  borderBottom:
                    activeSkill === 'Listening' ? '3px solid #0F4C8F' : '',
                }}
                onClick={() => setActiveSkill('Listening')}
                xs={2}>
                Listening
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Typography
                variant="subtitle1"
                style={{textAlign: useragent.isMobile ? 'start' : 'center'}}
                className={classes.suggestedTextMo}>
                Suggested tasks to perform in a chronological order.
              </Typography>
            </Grid>

            <Grid container item xs={12}>
            {activePath &&
                activePath.length > 0 &&
                activePath.map((item) => (
                  <Skill
                    useragent={useragent}
                    isInDashboard={isInDashboard}
                    data={item}
                  />
                ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid className={classes.root}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start">
              <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBox}
                style={{
                  borderBottom:
                    activeSkill === 'Speaking' ? '3px solid #0F4C8F' : '',
                }}
                onClick={() => setActiveSkill('Speaking')}
                xs={2}>
                Speaking
              </Grid>
              <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBox}
                style={{
                  borderBottom:
                    activeSkill === 'Writing' ? '3px solid #0F4C8F' : '',
                }}
                onClick={() => setActiveSkill('Writing')}
                xs={2}>
                Writing
              </Grid>
              <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBox}
                style={{
                  borderBottom:
                    activeSkill === 'Reading' ? '3px solid #0F4C8F' : '',
                }}
                onClick={() => setActiveSkill('Reading')}
                xs={2}>
                Reading
              </Grid>
              <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBox}
                style={{
                  borderBottom:
                    activeSkill === 'Listening' ? '3px solid #0F4C8F' : '',
                }}
                onClick={() => setActiveSkill('Listening')}
                xs={2}>
                Listening
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Typography variant="subtitle1" className={classes.suggestedText}>
                Suggested tasks to perform in a chronological order.
              </Typography>
            </Grid>

            <Grid container item xs={12}>
              {activePath &&
                activePath.length > 0 &&
                activePath.map((item) => (
                  <Skill
                    useragent={useragent}
                    isInDashboard={isInDashboard}
                    data={item}
                  />
                ))}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Training.propTypes = {
  useragent: PropTypes.object,
};

Training.defaultProps = {
  t: () => null,
  onQuestionChange: () => {},
  useragent: {},
  sidebar: false,
};

export default Training;
