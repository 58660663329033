import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'auto',
        minHeight:'380px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    loading: {
        marginTop: theme.spacing(2),
    },
    loadingReason:{
        fontSize:'20px'
    }
}))
const LoadingAIIndicator = ({padding}) => {
    const classes = useStyles()
    return (
        <div className={classes.root} style={{padding :padding ?? 0}}>
            <CircularProgress size={48} />
            <Typography className={classes.loading} variant="subtitle1">
                Just a sec...
            </Typography>
            <Typography className={classes.loadingReason} variant="subtitle1">
                We are generating your result…
            </Typography>
        </div>
    )
}

export default LoadingAIIndicator
