import * as React from 'react'

function SvgShadowingImage(props) {
  return (
    <svg width={72} height={72} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#EDFDF8" fillRule="nonzero" cx={36} cy={36} r={36} />
        <g fillRule="nonzero">
          <path
            d="M21.612 16.714h24.667a5.709 5.709 0 015.667 5.751v27.07a5.71 5.71 0 01-5.667 5.75H21.612a5.709 5.709 0 01-5.667-5.75v-27.07a5.71 5.71 0 015.665-5.75h.002z"
            opacity={0.5}
            fill="#4EDAD6"
          />
          <rect
            width={30.857}
            height={33.429}
            rx={3}
            opacity={0.95}
            transform="translate(18.517 19.287)"
            fill="#FFF"
          />
        </g>
        <path
          d="M23.13 37.02h.153c.944-.065 1.785-.87 2.5-2.398a22.267 22.267 0 001.432-4.685 44.73 44.73 0 011.078-4.349c.597-1.836 1.116-2.423 1.448-2.593.15-.077.392-.144.782.147.505.376 1.293 1.414 2.178 4.22a46.31 46.31 0 011.144 4.565c.027.162.064.323.11.48a.543.543 0 00.744.404.79.79 0 00.321-.933l-.064-.309a48.537 48.537 0 00-1.19-4.739c-.805-2.552-1.7-4.21-2.658-4.924a1.614 1.614 0 00-1.8-.242c-1.711.876-2.572 4.939-3.199 7.902-1.088 5.135-2.274 5.97-2.886 6.011a1.597 1.597 0 01-1.339-.643 5.643 5.643 0 01-.98-3.523.658.658 0 00-.57-.725.658.658 0 00-.575.725 7.14 7.14 0 001.338 4.565 2.672 2.672 0 002.033 1.044"
          fill="#CFD7E1"
          fillRule="nonzero"
          opacity={0.7}
        />
        <g fillRule="nonzero">
          <path
            d="M45.708 46.91a6.825 6.825 0 01-6.818-6.818V29.963a6.825 6.825 0 016.818-6.818 6.825 6.825 0 016.817 6.818v10.13a6.825 6.825 0 01-6.817 6.817z"
            fill="#4CDAD6"
          />
          <path
            d="M45.708 45.624a5.538 5.538 0 005.531-5.532V29.963a5.538 5.538 0 00-5.531-5.532 5.538 5.538 0 00-5.532 5.532v10.13a5.538 5.538 0 005.532 5.531m0 2.571a8.103 8.103 0 01-8.103-8.103V29.963a8.103 8.103 0 1116.205 0v10.13a8.103 8.103 0 01-8.102 8.102z"
            fill="#4ADAD6"
          />
        </g>
        <path
          d="M53.954 30.736h-9.403m9.403 4.178h-9.403m9.403 4.179h-9.403"
          strokeLinecap="round"
          stroke="#2CC3BE"
          strokeWidth={2}
        />
        <g transform="translate(32.143 33.805)">
          <g transform="translate(6.857 19.454)">
            <rect
              fill="#CDD6E0"
              fillRule="nonzero"
              width={14.18}
              height={2.026}
              rx={0.788}
            />
            <rect
              stroke="#CFD7E1"
              x={0.643}
              y={0.643}
              width={12.894}
              height={1.286}
              rx={0.288}
            />
          </g>
          <path
            fill="#CFD6E0"
            fillRule="nonzero"
            d="M11.035 16.416h6.078v3.038h-6.078z"
          />
          <path
            d="M13.44 18.441C6.732 18.433 1.295 12.997 1.287 6.287c0-.508.031-1.015.094-1.52h1.928v.885h.02a10.13 10.13 0 1020.22 0h.02v-.885h1.928c.062.505.094 1.012.094 1.52-.009 6.707-5.442 12.143-12.15 12.154z"
            fill="#CFD7E1"
            fillRule="nonzero"
          />
          <path
            d="M25.596 6.287H23.57V2.236h2.025v4.05l.001.001zm-22.283 0H1.286V2.236h2.026v4.05l.001.001z"
            fill="#CFD7E1"
            fillRule="nonzero"
          />
          <circle
            fill="#CFD7E1"
            fillRule="nonzero"
            cx={2.295}
            cy={2.295}
            r={1.286}
          />
          <circle
            fill="#CFD7E1"
            fillRule="nonzero"
            cx={24.579}
            cy={2.295}
            r={1.286}
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgShadowingImage
