import * as React from 'react'

function SvgReading(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.879 1.879A3 3 0 016 1h8a1 1 0 01.707.293l6 6A1 1 0 0121 8v12a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 01.879-2.121zM6 3a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V8.414L13.586 3H6z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1a1 1 0 011 1v5h5a1 1 0 110 2h-6a1 1 0 01-1-1V2a1 1 0 011-1zM7 13a1 1 0 011-1h8a1 1 0 110 2H8a1 1 0 01-1-1zM7 17a1 1 0 011-1h8a1 1 0 110 2H8a1 1 0 01-1-1zM7 9a1 1 0 011-1h2a1 1 0 110 2H8a1 1 0 01-1-1z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgReading
