import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useRouter} from 'next/router';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    padding: '37px 0 0 0',
  },
  rootMobile: {
    padding: '15px 15px 0 15px',
  },
  scrollWrapper: {
    // overflowX: 'hidden',
    overflowX: 'auto',
    paddingBottom: '5px',
    scrollbarWidth: 'thin',
  },
  items: {
    // width: '600px',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  item: {
    // display: 'inline-block',
    padding: '10px 10px',
    marginRight: '15px',
    whiteSpace: 'nowrap',
    border: '1px solid #E5E5E5',
    cursor: 'pointer',
  },
  active: {
    borderBottom: '4px solid #0F4C8F',
    color: '#0F4C8F',
    fontWeight: '700',
  },
  controles: {
    position: 'absolute',
    right: '0',
    top: '5px',
  },
  iconWrapper: {
    padding: '5px',
    marginRight: '10px',
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.25)',
  },
}));

const mapped = {
  'read-aloud': 'Read Aloud',
  'describe-image': 'Describe Image',
  'repeat-sentence': 'Repeat Sentence',
  'retell-lecture': 'Retell Lecture',
  'answer-short-question': 'Answer Short Question',
  'summarize-written-text': 'Summarize Written Text',
  'write-essay': 'Write Essay',
  'reading-multiple-choice-single-answer': 'MC, Single Answer',
  'reading-multiple-choice-multiple-answer': 'MC, Multiple Answer',
  'reorder-paragraph': 'Re-order Paragraph',
  'reading-fill-in-the-blanks': 'Fill in The Blanks',
  'reading-and-writing-fill-in-the-blanks': 'R & W Fill in The Blanks',
  'summarize-spoken-text': 'Summarize Spoken Text',
  'listening-multiple-choice-single-answer': 'MC, Single Answer',
  'listening-multiple-choice-multiple-answer': 'MC, Multiple Answer',
  'listening-fill-in-the-blanks': 'Fill in the Blanks',
  'highlight-incorrect-words': 'Highlight Incorrect Words',
  'highlight-correct-summary': 'Highlight Correct Summary',
  'select-missing-word': 'Select Missing Word',
  'write-from-dictation': 'Write from Dictation',
};

const Types = ({ua, ...props}) => {
  if (props.tabs === null) {
    return null;
  }

  const styles = useStyles();
  const router = useRouter();
  const queryType = router?.query?.type;
  const tabsRef = useRef(null);
  const tabItemsRef = useRef([]);
  const [activeTab, setActiveTab] = useState(0);
  const checkActiveTab = (id) => {
    if (id === activeTab) {
      return styles.active;
    }
  };
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);

  const getRef = (el) => tabItemsRef.current.push(el);

  const updateTabScroll = () => {
    const container = tabsRef.current;
    const tabItem = tabItemsRef.current[activeTab];
    if (container && tabItem) {
      if (container.scrollLeft > tabItem.offsetLeft) {
        container.scrollLeft = tabItem.offsetLeft;
      }

      if (
        container.offsetWidth + container.scrollLeft <
        tabItem.offsetLeft + tabItem.offsetWidth
      ) {
        container.scrollLeft =
          tabItem.offsetLeft + tabItem.offsetWidth - container.offsetWidth;
      }
    }
  };

  const navigateRight = () => {
    // since using index we minused? length by 1
    if (activeTab + 1 > props.tabs?.length - 1) return;
    setActiveTab((c) => {
      props.clicked(props.tabs[c + 1].id);
      return c + 1;
    });
  };
  const navigateLeft = () => {
    if (activeTab - 1 < 0) return;
    setActiveTab((c) => {
      props.clicked(props.tabs[c - 1].id);
      return c - 1;
    });
  };

  const handleItemClick = (item, itemIndex) => {
    props.clicked(item.id);
    setActiveTab(itemIndex);
  };

  useEffect(() => {
    if (activeTab === props.tabs?.length - 1) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (activeTab === 0) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }

    updateTabScroll();
  }, [activeTab]);

  useEffect(() => {
    if (queryType && props.tabs) {
      const tabIndex = props.tabs.findIndex(
        (item) => item.name === mapped[queryType],
      );
      if (tabIndex !== -1) {
        handleItemClick(props.tabs[tabIndex], tabIndex);
      }
    }
  }, [props.tabs]);

  return (
    <div className={`${styles.root} ${!ua.isDesktop && styles.rootMobile}`}>
      <div className={styles.scrollWrapper} ref={tabsRef}>
        <div className={styles.items}>
          {props.tabs?.map((item, index) => (
            <div
              key={item.name}
              ref={getRef}
              className={`${styles.item} ${checkActiveTab(index)}`}
              onClick={() => handleItemClick(item, index)}>
              {ua.isMobile
                ? item.name
                    .split(' ')
                    .map((c) => c[0])
                    .join('')
                : item.name}
            </div>
          ))}
        </div>
      </div>
      {!ua.isDesktop && (
        <div style={{fontWeight: 700, marginTop: 20, fontSize: 18}}>
          {props.tabs && props.tabs[activeTab]?.name}
        </div>
      )}
      {!ua.isMobile && (
        <div className={styles.controles}>
          <Grid container>
            <Grid
              item
              container
              xs
              alignItems="center"
              onClick={navigateLeft}
              className={styles.iconWrapper}>
              <ArrowBack
                style={disablePrev ? {color: '#E5E5E5'} : {color: '#AAAAAA'}}
              />
            </Grid>
            <Grid
              item
              container
              xs
              alignItems="center"
              onClick={navigateRight}
              className={styles.iconWrapper}>
              <ArrowForward
                style={disableNext ? {color: '#E5E5E5'} : {color: '#AAAAAA'}}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

Types.propTypes = {
  tabs: PropTypes.array.isRequired,
  clicked: PropTypes.func.isRequired,
  ua: PropTypes.object,
};

Types.defaultProps = {
  ua: {},
};

export default Types;
