import * as React from 'react';

function SvgWatch(props) {
  return (
    <svg
      width={props.width ? props.width : "24"}
      height={props.height ? props.height : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM4 12C4 7.58172 7.58172 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
        fill="#979797"
      />
      <path
        d="M9.99994 15C9.73414 15.0015 9.47866 14.8972 9.28994 14.71C9.10063 14.5222 8.99414 14.2666 8.99414 14C8.99414 13.7334 9.10063 13.4778 9.28994 13.29L10.9999 11.59V6C10.9999 5.44772 11.4477 5 11.9999 5C12.5522 5 12.9999 5.44772 12.9999 6V12C13.0015 12.2658 12.8971 12.5213 12.7099 12.71L10.7099 14.71C10.5212 14.8972 10.2657 15.0015 9.99994 15Z"
        fill="#979797"
      />
    </svg>
  );
}

export default SvgWatch;
