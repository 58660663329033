import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {useCreditAction} from 'core/lib/adapters/redux/actions';

const usePlanStyles = makeStyles((theme) => ({
  container: {
    margin: '30px 20px 30px 0',
    boxShadow: '0px 2px 30px 0 #f4f4f4',
    cursor: 'pointer',
  },
  selectedContainer: {
    margin: '30px 20px 30px 0',
    boxShadow: '0px 2px 30px 0 #e8e8e8',
    transform: 'scale(1.08)',
    transition: 'all 0.2s',
    cursor: 'pointer',
  },
  header: {
    background: '#CBD0D5',
    textAlign: 'center',
    color: 'white',
    padding: '4px 0',
  },
  selectedHeader: {
    background: '#0F4C8F',
    textAlign: 'center',
    color: 'white',
    padding: '4px 0',
  },
  detail: {
    padding: '20px 20px',
    fontSize: '14px',
  },
}));

function Plan(props) {
  const styles = usePlanStyles();
  return (
    <div
      className={props.selected ? styles.selectedContainer : styles.container}>
      <div className={props.selected ? styles.selectedHeader : styles.header}>
        {props.title}
      </div>
      <div className={styles.detail}>Buy with {props.title}</div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 0 50px 0',
    fontSize: '20px',
  },
  header: {
    fontSize: '24px',
    fontWeight: 700,
    background: '#0F4C8F',
    color: 'white',
    width: '100%',
    textAlign: 'center',
    minHeight: '56px',
    padding: '10px 0',
  },
  detail: {
    marginTop: '20px',
    fontSize: '18px',
  },
  btn: {
    color: 'white',
    background: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14.4px',
    height: '38px',
  },
}));

function UserPackageList(props) {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [selectedPlan, setSelectedPlan] = useState(0);
  const [isBuying, setIsBuying] = useState(false);
  const buy = () => {
    setIsBuying(true);
    dispatch(
      useCreditAction({
        packageId: props.packages[selectedPlan].id,
        entityId: props.sessionBillId,
      }),
    );
  };

  const useCreditSuccess = useSelector(
    (state) => state.package.useCreditSuccess,
  );
  useEffect(() => {
    if (useCreditSuccess && isBuying) {
      props.onCreditSuccess();
      setIsBuying(false);
    }
    if (!useCreditAction && isBuying) {
      // props.onCreditFail()
      setIsBuying(false);
    }
  }, [useCreditSuccess]);

  return (
    <Paper className={styles.container}>
      <Grid container justify="center">
        <div className={styles.header}>Upgrade</div>
      </Grid>
      <Grid container justify="center">
        <div className={styles.detail}>
          Please choose one of your following plans to use the course:
        </div>
      </Grid>
      <Grid container justify="center">
        {/* OnClick did not work on the component itsef */}
        {props.packages?.map((pkg, index) => (
          <div
            onClick={() => {
              setSelectedPlan(index);
            }}>
            <Plan title={pkg.package.name} selected={selectedPlan === index} />
          </div>
        ))}
      </Grid>
      <Grid container justify="center">
        <Button variant="contained" className={styles.btn} onClick={buy}>
          Buy the Session
        </Button>
      </Grid>
    </Paper>
  );
}

UserPackageList.propTypes = {
  packages: PropTypes.object.isRequired,
  sessionBillId: PropTypes.number.isRequired,
  onCreditSuccess: PropTypes.func.isRequired,
};

export default UserPackageList;
