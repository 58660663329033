import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {useRouter} from 'next/router';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Modals from 'components/StudyPlan/Modals';
import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StudyPlan from 'components/StudyPlan/index';
import NoStudyPlan from 'components/StudyPlan/noStudyPlan';

import {setShowupModalAction} from 'core/lib/adapters/redux/actions';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#0F4C8F',
  },

  Container: {
    maxHeight: 600,
    height: 600,
    overflowY: 'auto',
    marginBottom: theme.spacing(1),
  },

  studyPlanContainer: {
    // maxHeight: 600,
    height: 'auto',
    backgroundColor: '#e5e5e5',
    [theme.breakpoints.up('lg')]: {
      paddingRight: 20,
    },
  },
  studyPlanHeader: {
    backgroundColor: '#fff',
    padding: '20px 20px',
  },
  studyPlanBody: {
    backgroundColor: '#fff',
    padding: '0px 20px 20px 20px ',
  },

  gotoOtherPage: {
    color: '#0F4C8F',
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 700,
    textDecoration: 'underline',
  },

  recreateBtn: {
    textTransform: 'capitalize',
    border: '1px solid #0F4C8F',
    padding: '10px 20px',
    backgroundColor: '#fff',
    marginTop: 20,
    color: '#0F4C8F',
    fontWeight: 600,
    borderRadius: 3,
    marginBottom: 20,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  mainBoxTitle: {
    fontWeight: 800,
    fontSize: 17,
    color: '#303030',
  },
}));

const StudyPlanCopm = ({useragent}) => {
  const {t} = useTranslation();
  const router = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();
  const redirectToNewPage = (e, href) => {
    e.preventDefault();
    router.push(href);
  };
  const {studyPlan} = useSelector((state) => ({
    studyPlan: state.studyPlan.studyPlan,
  }));

  const renderStudyPlan = () => {
    if (
      studyPlan &&
      Object.keys(studyPlan).length !== 0 &&
      studyPlan.constructor === Object
    )
      return <StudyPlan useragent={useragent} isInDashboard />;
    else if (
      studyPlan &&
      Object.keys(studyPlan).length === 0 &&
      studyPlan.constructor === Object
    )
      return <NoStudyPlan />;
    else
      return (
        <Grid container item alignItems="center" justify="center">
          <CircularProgress size={24} />
        </Grid>
      );
  };

  return (
    <Grid
      container
      item
      xs={12}
      lg={8}
      // className={`${classes.studyPlanContainer} ${classes.Container}`}> //for scroable in X
      className={`${classes.studyPlanContainer}`}>
      <Grid
        xs={12}
        className={classes.studyPlanHeader}
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end">
        <Grid
          xs={12}
          lg={4}
          // className={classes.gotoOtherPage}
          container
          item
          direction="row"
          justify="flex-end"
          alignItems="center">
          {studyPlan &&
            Object.keys(studyPlan).length !== 0 &&
            studyPlan.constructor === Object && (
              <Grid
                container
                justify="flex-end"
                onClick={() => dispatch(setShowupModalAction({statuse: true}))}>
                <Typography
                  variant="subtitle1"
                  className={classes.gotoOtherPage}>
                  Recreate Study Plan
                </Typography>
              </Grid>
            )}
        </Grid>
      </Grid>
      <Grid item xs={12} container className={classes.studyPlanBody}>
        {renderStudyPlan()}
      </Grid>

      {studyPlan &&
        Object.keys(studyPlan).length === 0 &&
        studyPlan.constructor === Object && (
          <Grid
            xs={12}
            container
            alignItems="flex-start"
            justify="center"
            style={{backgroundColor: '#fff'}}>
            <Button
              variant="contained"
              className={classes.recreateBtn}
              onClick={() => dispatch(setShowupModalAction({statuse: true}))}
              elevation={0}>
              Create Study Plan
            </Button>
          </Grid>
        )}

      <Modals useragent={useragent} />
    </Grid>
  );
};
export default StudyPlanCopm;
