import React, {useEffect, useState} from 'react';
import Hidden from '@material-ui/core/Hidden';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReorderParagraphItem from './ReorderParagraphItem';

const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: theme.spacing(4),
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightNav: {
    background: '#FF3C3C',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  leftNav: {
    background: '#0F4C8F',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
  },
  board: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    maxWidth: '100%',
  },
  sourceHeaderContainer: {
    background: '#0F4C8F',
    textAlign: 'center',
    height: theme.spacing(5),
    color: 'white',
    flex: 1,
    width: '100%',
  },
  targetHeaderContainer: {
    background: '#FF3C3C',
    textAlign: 'center',
    height: theme.spacing(5),
    color: 'white',
    flex: 1,
    width: '100%',
  },
  answerSectionContainer: {
    borderTop: `2px solid #0F4C8F`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    background: '#EDF0F2',
    height: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
  },
  viewParagraphButton: {
    marginLeft: theme.spacing(3),
  },
}));

const ReorderParagraphMobile = ({t, onAnswer, options}) => {
  const backendColumns = {
    source: {
      name: t('source'),
      items: options,
    },
    target: {
      name: t('target'),
      items: [],
    },
  };
  const classes = useStyles();
  const [stateColumns, setColumnsState] = useState(backendColumns);
  const [answerColumns, setAnswerColumns] = useState();

  const onDragHandler = (result) => {
    const {source, destination} = result;
    const prevPracticeAnswer = JSON.parse(JSON.stringify(stateColumns));
    if (!destination) return;
    if (
      source &&
      destination &&
      source.droppableId === destination.droppableId
    ) {
      prevPracticeAnswer.source.items.splice(source.index, 1);
      prevPracticeAnswer.source.items.splice(
        destination.index,
        0,
        stateColumns.source.items[source.index],
      );
    }
    prevPracticeAnswer.target.items = [...prevPracticeAnswer.source.items];
    setColumnsState(prevPracticeAnswer);
    setAnswerColumns(prevPracticeAnswer);
  };

  useEffect(() => {
    if (answerColumns) onAnswer(answerColumns);
  }, [answerColumns]);

  return (
    <Hidden lgUp>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        className={classes.container}>
        <DragDropContext onDragEnd={onDragHandler}>
          <Paper className={classes.board} elevation={0}>
            <div style={{maxWidth: '100%', width: '100%'}}>
            <Droppable droppableId="source" key="source">
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? 'lightblue'
                          : 'white',
                        padding: 4,
                        minHeight: 500,
                        width: '100%',
                      }}>
                      {stateColumns &&
                        stateColumns.source &&
                        stateColumns.source.items.map((item, index) => {
                          return (
                            <ReorderParagraphItem
                              key={item}
                              index={index}
                              optionIndex={options.indexOf(item)}
                              item={item}
                              mobile
                            />
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </Paper>
        </DragDropContext>
      </Grid>
    </Hidden>
  );
};

ReorderParagraphMobile.propTypes = {
  stateColumns: PropTypes.shape().isRequired,
  onAnswer: PropTypes.func.isRequired,
  setColumnsState: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};
export default ReorderParagraphMobile;
