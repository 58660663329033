import * as React from 'react'

function HelpMark(props) {
  return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17Z" fill="#0F4C8F"/>
            <path d="M10 14C9.44772 14 9 13.5523 9 13V11C9 10.4477 9.44772 9.99999 10 9.99999C11.1046 9.99999 12 9.10456 12 7.99999C12 6.89542 11.1046 5.99999 10 5.99999C8.89543 5.99999 8 6.89542 8 7.99999C8 8.55228 7.55229 8.99999 7 8.99999C6.44772 8.99999 6 8.55228 6 7.99999C5.99845 5.92092 7.5899 4.18729 9.66151 4.01136C11.7331 3.83542 13.5941 5.27584 13.9432 7.32539C14.2923 9.37495 13.0131 11.3502 11 11.87V13C11 13.5523 10.5523 14 10 14Z" fill="#0F4C8F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1 20H10C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10V19C0 19.5523 0.447715 20 1 20ZM10 18H2V10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18Z" fill="#0F4C8F"/>
            <path d="M22.9998 24H13.9998C10.6589 24.0027 7.53737 22.3369 5.67984 19.56C5.53562 19.3339 5.48827 19.0593 5.54844 18.7979C5.60862 18.5365 5.77126 18.3103 5.99984 18.17C6.21987 18.0211 6.49008 17.9659 6.75086 18.0166C7.01163 18.0673 7.24154 18.2196 7.38984 18.44C8.86497 20.651 11.3419 21.9851 13.9998 22H21.9998V14C21.9982 10.9803 20.2965 8.21884 17.5998 6.85999C17.2801 6.69922 17.0704 6.38008 17.0497 6.02278C17.0291 5.66548 17.2006 5.32431 17.4997 5.12778C17.7989 4.93125 18.1801 4.90922 18.4998 5.06999C21.8723 6.76936 23.9997 10.2236 23.9998 14V23C23.9998 23.5523 23.5521 24 22.9998 24Z" fill="#0F4C8F"/>
        </svg>
  )
}

export default HelpMark
