import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Grid,
  makeStyles,
  useTheme,
  Button,
  Typography,
  Tooltip,
  withStyles,
  Dialog,
  DialogContent,
  Box,
} from '@material-ui/core';
import Player from '../Player';
import Circle from '../Icons/gen/GreenCircle';
import InfoIcon from '../Icons/gen/CircleInfo';
import Skeleton from '@material-ui/lab/Skeleton';
import LoadingAIIndicator from '../LoadingAIIndicator';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#edf0f2',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const GuideTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#353535',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: '1rem 2rem',
    maxWidth: '500px',
  },
}))(Tooltip);

const scoreToFixed = (score, fractionDigits = 1) =>
  (Math.round(score * 10) / 10).toFixed(fractionDigits);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    // height: '47px',
    background: theme.palette.background.default,
    color: '#000',
    padding: '13px 81px',
    [theme.breakpoints.down('md')]: {
      padding: '13px 12px',
    }
  },
  mainHeader: {
    borderBottom: '34px solid #0F4C8F',
    gap: '12px'
  },
  headerMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '47px',
    background: theme.palette.primary.main,
    color: '#fff',
    padding: '15px',
  },
  headerText: {
    fontSize: '18px',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    }
  },
  headerTextMobile: {
    fontSize: '14px',
    fontWeight: 600,
  },
  tableHeader: {
    backgroundColor: theme.palette.background.default,
    color: '#fff',
  },
  body: {
    background: '#fff',
    padding: '48px 77px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 12px',
    }
  },
  mobileHeader: {
    height: '47px',
    background: theme.palette.primary.main,
    color: '#fff',
    padding: '15px 10px',
  },
  mobileBody: {
    background: '#fff',
    padding: '0 10px',
  },
  table: {
    width: '100%',
    borderSpacing: 0,
    background: '#fff',
    '& thead': {
      textAlign: 'left',
      '& th': {
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: 600,
        color: '#0F4C8F',
        padding: '8px 16px',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px'
        }
      },
    },
    '& tbody': {
      '& td': {
        border: '0px solid rgba(102, 102, 102, 0.08)',
        padding: '12px 23px',
        fontSize: '13px',
        fontWeight: 500,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
    },
  },
  scoreCol: {
    width: '107px',
    textAlign: 'center',
  },
  skillDescription: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '21px',
    padding: '4px 23px',
  },
  scoreSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fff',
    // padding: '12px 10px',
  },
  close: {
    width: '60px',
    height: '33.8px',
    border: 'none',
    borderRadius: '50px',
    background: '#DADADA',
    color: '#303030',
    textTransform: 'none',
  },
  byAiButton: {
    width: '126px',
    height: '33.8px',
    border: 'none',
    borderRadius: '50px',
    background: theme.palette.primary.main,
    color: '#fff',
    textTransform: 'none',
    marginRight: '21px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  byAiButtonMobile: {
    width: '100%',
    height: '41px',
    border: 'none',
    borderRadius: '3px',
    background: theme.palette.primary.main,
    color: '#fff',
    textTransform: 'none',
    marginBottom: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  itemContainer: {
    display: 'inline-flex',
    marginBottom: 10,
    '& p': {
      margin: '0 4px',
    },
  },
  statusItems: {
    display: 'flex',
    '& div': {
      margin: '0 5px',
      '& span': {
        width: '9px',
        height: '9px',
        borderRadius: '50%',
      },
    },
  },
  icon: {
    fontWeight: 600,
    fontSize: '15px',
    padding: '3px 12px 12px 0',
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  tabButton: {
    width: '100%',
    height: '53px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#DADADA',
    color: '#808080',
    fontSize: '14px',
    fontWeight: 700,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  activeTab: {
    background: '#EDF0F2',
    color: theme.palette.primary.main,
  },
  closeButton: {
    width: '100%',
    height: '41px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#DADADA',
    color: '#303030',
    fontSize: '14.4px',
    fontWeight: 500,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginBottom: '50px',
  },
  playerStyle: {
    '& div': {
      marginTop: 0,
    },
  },
}));

const AiScore = ({
  t,
  title,
  category,
  score,
  skillMaxScore,
  answer,
  transcript,
  useragent,
  onClose,
  type,
  totalAIRequests,
  remainingAIRequests,
  practiceNow,
  loading
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const [traitsTable, setTraitsTable] = useState([]);
  const [skillTable, setSkillTable] = useState([]);
  const [maxScore, setMaxScore] = useState(0);
  const [textareaInputArray, setTextareaInputArray] = useState([]);
  const [skillDescription, setSkillDescription] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [headerTitle, setHeaderTitle] = useState('');
  const [answerArray, setAnswerArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [correctionModal, setCorrectionModal] = useState(false);
  const [correctionModalContent, setCorrectionModalContent] = useState('');
  const baseClass = {
    display: 'inline-block',
    margin: '0px 2px',
  };

  const goodClass = {
    color: '#2ABA86',
    borderRadius: '4px',
  };

  const averageClass = {
    color: '#0F4C8F',
    padding: '2px',
    borderRadius: '4px',
  };

  const spellClass = {
    color: '#E7961E',
    padding: '2px',
    borderRadius: '4px',
  };

  const badClass = {
    color: '#FF3C3C',
    padding: '2px',
    borderRadius: '4px',
  };

  const checkPuasesStatus = (word) => {
    let status = '';
    score?.ai_data?.pauses?.forEach((item) => {
      if (
        item.word_pause_after.replace(/[^\w\s]/gi, '').toLowerCase() ===
        word.replace(/[^\w\s]/gi, '').toLowerCase()
      ) {
        status = true;
      }
    });
    return status;
  };

  const checkIsStatus = (word, index) => {
    let status = null;
    score?.ai_data?.word_pronunciation?.forEach((item) => {
      if (
        item.word.replace(/[^\w\s]/gi, '').toLowerCase() ===
        word.replace(/[^\w\s]/gi, '').toLowerCase()
      ) {
        if (item.pronunciation === 'GOOD') status = 'GOOD';
        else if (item.pronunciation === 'BAD') status = 'BAD';
        else if (item.pronunciation === 'AVERAGE') status = 'AVERAGE';
        // else if (item.pronunciation === 'STRUCK') status = 'STRUCK';
      }
    });
    score?.ai_data?.word_mistakes?.forEach((item) => {
      const itemExist = item.words_index.indexOf(index);
      if (itemExist !== -1) {
        status = { type: item.type, suggestion: item.suggestions.join(' ') };
      }
    });
    score?.ai_data?.word_sequence?.forEach((item) => {
      if (
        item.word.replace(/[^\w\s]/gi, '').toLowerCase() ===
        word.replace(/[^\w\s]/gi, '').toLowerCase()
      ) {
        if (item.status === 'WRITTEN_AND_EXIST') status = 'GOOD';
        if (item.status === 'MISSED') status = 'MISSED';
        if (item.status === 'WRITTEN_AND_DONT_EXIST')
          status = 'WRITTEN_AND_DONT_EXIST';
      }
    });
    return status;
  };

  useEffect(() => {
    if (
      category !== 'DescribeImage' &&
      category !== 'AnswerShortQuestion' &&
      category !== 'RetellLecture' &&
      category !== 'SummarizeSpokenText' &&
      category !== 'SummarizeWrittenText'
    ) {
      if (transcript) {
        // setTextareaInputArray(getWords(answer.question_meta.transcript));
        setTextareaInputArray(transcript?.split(/\s+/));
      } else {
        // setTextareaInputArray(getWords(answer.question_meta.transcript));
        setTextareaInputArray(answer?.split(/\s+/));
      }
    }
    if (
      category === 'SummarizeSpokenText' ||
      category === 'SummarizeWrittenText'
    ) {
      setTextareaInputArray(answer?.split(/\s+/));
    }
    if (category === 'WriteFromDictation') {
      setAnswerArray(answer?.split(/\s+/));
    }
  }, [answer]);

  useEffect(() => {
    if (
      (category === 'DescribeImage' ||
        category === 'AnswerShortQuestion' ||
        category === 'RetellLecture') &&
      score &&
      score.ai_data &&
      score.ai_data.transcribed_text
    ) {
      setTextareaInputArray(score.ai_data.transcribed_text.split(/\s+/));
    }
    if (score && score.ai_data) {
      switch (category) {
        case 'ReadAloud':
          setTraitsTable([
            {
              key: 'Content',
              value: `${scoreToFixed(score?.ai_data?.score_content)}/3.0`,
              guide:
                '<p><strong>1.</strong> Each replacement, omission or  insertion of a word counts as one error.</p>' +
                '<p><strong>2.</strong> Maximum score: depends on the length of the item prompt.</p>',
            },
            {
              key: 'Pronounciation',
              value: `${scoreToFixed(
                score?.ai_data?.score_pronunciation,
                0,
              )}/5`,
              guide:
                '<p><strong>5</strong> Native-like</p>' +
                '<p><strong>4</strong> Advanced</p>' +
                '<p><strong>3</strong> Good</p>' +
                '<p><strong>2</strong> Intermediate</p>' +
                '<p><strong>1</strong> Intrusive</p>' +
                '<p><strong>0</strong> Non-English</p>' +
                '<p>Pronunciation is the ability to produce speech sounds in a way that is easily understandable to most regular speakers of the language.</p>' +
                '<p>Regional or national pronunciation variants are considered correct to the degree that they are understandable to most regular speakers of the language.</p>',
            },
            {
              key: 'Oral Fluency',
              value: `${scoreToFixed(score?.ai_data?.score_oral_fluency, 0)}/5`,
              guide:
                '<p><strong>5</strong> Native-like</p>' +
                '<p><strong>4</strong> Advanced</p>' +
                '<p><strong>3</strong> Good</p>' +
                '<p><strong>2</strong> Intermediate</p>' +
                '<p><strong>1</strong> Limited</p>' +
                '<p><strong>0</strong> Disfluent</p>' +
                '<p>Oral fluency is smooth, effortless and natural-paced delivery of speech.</p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Reading',
              value: `${scoreToFixed(score.Reading)}/${scoreToFixed(
                skillMaxScore.Reading,
              )}`,
            },
            {
              key: 'Speaking',
              value: `${scoreToFixed(score.Speaking)}/${scoreToFixed(
                skillMaxScore.Speaking,
              )}`,
            },
          ]);
          setSkillDescription(
            'In real exam, each Read Aloud Q may contribute up to 4.7 & 3.7 marks toward Reading & Speaking respectively. (Asm: 6Q total) ',
          );
          setHeaderTitle('Read Aloud ');
          setMaxScore(13);
          break;
        case 'RepeatSentence':
          setTraitsTable([
            {
              key: 'Content',
              value: `${scoreToFixed(score?.ai_data?.score_content, 0)}/3`,
              guide:
                '<p>Errors = replacements, omissions and insertions only</p>' +
                '<p>Hesitations, filled or unfilled pauses, leading or trailing material are ignored in the scoring of content</p>' +
                '<p><strong>3</strong> All words in the response from the prompt in the correct sequence</p>' +
                '<p><strong>2</strong> At least 50% of words in the response from the prompt in the correct sequence</p>' +
                '<p><strong>1</strong> Less than 50% of words in the response from the prompt in the correct sequence</p>' +
                '<p><strong>0</strong> Almost nothing from the prompt in the response</p>',
            },
            {
              key: 'Pronounciation',
              value: `${scoreToFixed(
                score?.ai_data?.score_pronunciation,
                0,
              )}/5`,
              guide:
                '<p><strong>5</strong> Native-like</p>' +
                '<p><strong>4</strong> Advanced</p>' +
                '<p><strong>3</strong> Good</p>' +
                '<p><strong>2</strong> Intermediate</p>' +
                '<p><strong>1</strong> Intrusive</p>' +
                '<p><strong>0</strong> Non-English</p>' +
                '<p>Pronunciation is the ability to produce speech sounds in a way that is easily understandable to most regular speakers of the language.</p>' +
                '<p>Regional or national pronunciation variants are considered correct to the degree that they are understandable to most regular speakers of the language.</p>',
            },
            {
              key: 'Oral Fluency',
              value: `${scoreToFixed(score?.ai_data?.score_oral_fluency, 0)}/5`,
              guide:
                '<p><strong>5</strong> Native-like</p>' +
                '<p><strong>4</strong> Advanced</p>' +
                '<p><strong>3</strong> Good</p>' +
                '<p><strong>2</strong> Intermediate</p>' +
                '<p><strong>1</strong> Limited</p>' +
                '<p><strong>0</strong> Disfluen</p>' +
                '<p>Oral fluency is smooth, effortless and natural-paced delivery of speech.</p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Listening',
              value: `${scoreToFixed(score.Listening)}/${scoreToFixed(
                skillMaxScore.Listening,
              )}`,
            },
            {
              key: 'Speaking',
              value: `${scoreToFixed(score.Speaking)}/${scoreToFixed(
                skillMaxScore.Speaking,
              )}`,
            },
          ]);
          setSkillDescription(
            'In real exam, each Repeat sentence question may contribute up to 2.7 & 3.2 toward Listening & Speaking resp. (Asm: 10Q  total)',
          );
          setHeaderTitle('Repeat Sentence');
          setMaxScore(13);
          break;
        case 'DescribeImage':
          setTraitsTable([
            {
              key: 'Content',
              value: `${scoreToFixed(score?.ai_data?.score_content, 0)}/5`,
              guide:
                '<p><strong>5</strong> Describes all elements of the image and their relationships, possible development and conclusion or implication</p>' +
                '<p><strong>4</strong> Describes all the key elements of the image and their relations, referring to their implications or conclusions</p>' +
                '<p><strong>3</strong> Deals with most key elements of the image and refers to their implications or conclusions</p>' +
                '<p><strong>2</strong> Deals with only one key element in the image and refers to an implication or conclusion. Shows basic understanding of several core elements of the image</p>' +
                '<p><strong>1</strong> Describes some basic elements of the image, but does not make clear their interrelations or implications</p>' +
                '<p><strong>0</strong> Mentions some disjointed elements of the presentation</p>',
            },
            {
              key: 'Pronounciation',
              value: `${scoreToFixed(
                score?.ai_data?.score_pronunciation,
                0,
              )}/5`,
              guide:
                '<p><strong>5</strong> Native-like</p>' +
                '<p><strong>4</strong> Advanced</p>' +
                '<p><strong>3</strong> Good</p>' +
                '<p><strong>2</strong> Intermediate</p>' +
                '<p><strong>1</strong> Intrusive</p>' +
                '<p><strong>0</strong> Non-English</p>' +
                '<p>Pronunciation is the ability to produce speech sounds in a way that is easily understandable to most regular speakers of the language.</p>' +
                '<p>Regional or national pronunciation variants are considered correct to the degree that they are understandable to most regular speakers of the language.</p>',
            },
            {
              key: 'Oral Fluency',
              value: `${scoreToFixed(score?.ai_data?.score_oral_fluency, 0)}/5`,
              guide:
                '<p><strong>5</strong> Native-like</p>' +
                '<p><strong>4</strong> Advanced</p>' +
                '<p><strong>3</strong> Good</p>' +
                '<p><strong>2</strong> Intermediate</p>' +
                '<p><strong>1</strong> Limited</p>' +
                '<p><strong>0</strong> Disfluent</p>' +
                '<p>Oral fluency is smooth, effortless and natural-paced delivery of speech. </p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Speaking',
              value: `${scoreToFixed(score.Speaking)}/${scoreToFixed(
                skillMaxScore.Speaking,
              )}`,
            },
          ]);
          setSkillDescription(
            'In real exam, each Describe Image question may contribute up to 3.7 marks towards speaking. (Asm: 6Q total)',
          );
          setHeaderTitle('Describe Image');
          setMaxScore(15);
          break;
        case 'RetellLecture':
          setTraitsTable([
            {
              key: 'Content',
              value: `${scoreToFixed(score?.ai_data?.score_content, 0)}/5`,
              guide:
                '<p><strong>5</strong> Re-tells all points of the presentation and describes characters, aspects and actions, their relationships, the underlying development, implications and conclusions</p>' +
                '<p><strong>4</strong> Describes all key points of the presentation and their relations, referring to their implications and conclusions</p>' +
                '<p><strong>3</strong> Deals with most points in the presentation and refers to their implications and conclusions</p>' +
                '<p><strong>2</strong> Deals with only one key point and refers to an implication or conclusion. Shows basic understanding of several core elements of the presentation</p>' +
                '<p><strong>1</strong> Describes some basic elements of the presentation but does not make clear their interrelations or implications</p>' +
                '<p><strong>0</strong> Mentions some disjointed elements of the presentation</p>',
            },
            {
              key: 'Pronounciation',
              value: `${scoreToFixed(
                score?.ai_data?.score_pronunciation,
                0,
              )}/5`,
              guide:
                '<p><strong>5</strong> Native-like</p>' +
                '<p><strong>4</strong> Advanced</p>' +
                '<p><strong>3</strong> Good</p>' +
                '<p><strong>2</strong> Intermediate</p>' +
                '<p><strong>1</strong> Intrusive</p>' +
                '<p><strong>0</strong> Non-English</p>' +
                '<p>Pronunciation is the ability to produce speech sounds in a way that is easily understandable to most regular speakers of the language.</p>' +
                '<p>Regional or national pronunciation variants are considered correct to the degree that they are understandable to most regular speakers of the language.</p>',
            },
            {
              key: 'Oral Fluency',
              value: `${scoreToFixed(score?.ai_data?.score_oral_fluency, 0)}/5`,
              guide:
                '<p><strong>5</strong> Native-like</p>' +
                '<p><strong>4</strong> Advanced</p>' +
                '<p><strong>3</strong> Good</p>' +
                '<p><strong>2</strong> Intermediate</p>' +
                '<p><strong>1</strong> Limited</p>' +
                '<p><strong>0</strong> Disfluent</p>' +
                '<p>Oral fluency is smooth, effortless and natural-paced delivery of speech.</p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Listening',
              value: `${scoreToFixed(score.Listening)}/${scoreToFixed(
                skillMaxScore.Listening,
              )}`,
            },
            {
              key: 'Speaking',
              value: `${scoreToFixed(score.Speaking)}/${scoreToFixed(
                skillMaxScore.Speaking,
              )}`,
            },
          ]);
          setSkillDescription(
            'In real exam, each Retell Lecture question may contribute up to 3.7 & 3.7 marks toward listening & speaking resp. (Asm: 3Q total)',
          );
          setHeaderTitle('Retell Lecture');
          setMaxScore(15);
          break;
        case 'AnswerShortQuestion':
          setTraitsTable([
            {
              key: 'Vocabulary',
              value: scoreToFixed(score?.ai_data?.score_vocabulary, 2),
              guide:
                '<p><strong>1</strong> Appropriate word choice in response</p>' +
                '<p><strong>0</strong> Inappropriate word choice in response</p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Listening',
              value: `${scoreToFixed(score.Listening, 2)}/${scoreToFixed(
                skillMaxScore.Listening,
                2,
              )}`,
            },
            {
              key: 'Speaking',
              value: `${scoreToFixed(score.Speaking, 2)}/${scoreToFixed(
                skillMaxScore.Speaking,
                2,
              )}`,
            },
          ]);
          setSkillDescription(
            'In real exam, each answer short question may contribute up to 0.32 & 0.24 marks toward listening & speaking resp. (Asm: 10Q total)',
          );
          setHeaderTitle('Answer Short Question');
          setMaxScore(1);
          break;
        case 'SummarizeWrittenText':
          setTraitsTable([
            {
              key: 'Content',
              value: `${scoreToFixed(score?.ai_data?.score_content)}/2.0`,
              guide:
                '<p><strong>2</strong> Provides a good summary of the text. All relevant aspects mentioned</p>' +
                '<p><strong>1</strong> Provides a fair summary of the text but misses one or two aspects</p>' +
                '<p><strong>0</strong> Omits or misrepresents the main aspects of the text</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
            {
              key: 'Form',
              value: `${scoreToFixed(score?.ai_data?.score_form, 0)}/1`,
              guide:
                '<p><strong>1</strong> Is written in one, single, complete sentence</p>' +
                '<p><strong>0</strong> Not written in one single, complete sentence or contains fewer than 5 or more than 75 words. Summary is written in capital letters.</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
            {
              key: 'Grammar',
              value: `${scoreToFixed(score?.ai_data?.score_grammar, 0)}/2`,
              guide:
                '<p><strong>2</strong> Has correct grammatical structure</p>' +
                '<p><strong>1</strong> Contains grammatical errors but with no hindrance to communication</p>' +
                '<p><strong>0</strong> Has defective grammatical structure which could hinder communication</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
            {
              key: 'Vocabulary',
              value: `${scoreToFixed(score?.ai_data?.score_vocabulary, 0)}/2`,
              guide:
                '<p><strong>2</strong> Has appropriate choice of words</p>' +
                '<p><strong>1</strong> Contains lexical errors but with no hindrances to communication</p>' +
                '<p><strong>0</strong> Has defective word choice which could hinder communication</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Reading',
              value: `${scoreToFixed(score.Reading)}/${scoreToFixed(
                skillMaxScore.Reading,
              )}`,
            },
            {
              key: 'Writing',
              value: `${scoreToFixed(score.Writing)}/${scoreToFixed(
                skillMaxScore.Writing,
              )}`,
            },
          ]);
          setSkillDescription(
            'In real exam, each summarize written text question may contribute up to 5.1 & 5.2 marks toward reading & writing respectively. (Asm: 2Q total)',
          );
          setHeaderTitle('Summarize Written Text');
          setMaxScore(7);
          break;
        case 'WriteEssay':
          setTraitsTable([
            {
              key: 'Content',
              value: `${scoreToFixed(score?.ai_data?.score_content, 0)}/3`,
              guide:
                '<p><strong>3</strong> Adequately deals with the prompt</p>' +
                '<p><strong>2</strong> Deals with the prompt but does not deal with one minor aspect</p>' +
                '<p><strong>1</strong> Deals with the prompt but omits a major aspect or more than one minor aspect</p>' +
                '<p><strong>0</strong> Does not deal properly with the prompt</p>',
            },
            {
              key: 'Form',
              value: `${scoreToFixed(score?.ai_data?.score_form, 0)}/2`,
              guide:
                '<p><strong>2</strong> Length is between 200 and 300 words</p>' +
                '<p><strong>1</strong> Length is between 120 and 199 or between 301 and 380 words</p>' +
                '<p><strong>0</strong> Length is less than 120 or more than 380 words. Essay is written in capital letters, contains no punctuation or only consists of bullet points or very short sentences</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
            {
              key: 'Grammar',
              value: `${scoreToFixed(score?.ai_data?.score_grammar, 0)}/2`,
              guide:
                '<p><strong>2</strong> Shows consistent grammatical control of complex language. Errors are rare and difficult to spot.</p>' +
                '<p><strong>1</strong> Shows a relatively high degree of grammatical control. No mistakes which would lead to misunderstandings</p>' +
                '<p><strong>0</strong> Contains mainly simple structures and/or several basic mistakes</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
            {
              key: 'Spelling',
              value: `${scoreToFixed(score?.ai_data?.score_spelling, 0)}/2`,
              guide:
                '<p><strong>2</strong> Correct spelling</p>' +
                '<p><strong>1</strong> One spelling error</p>' +
                '<p><strong>0</strong> More than one spelling error</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
            {
              key: 'Vocabulary Range',
              value: `${scoreToFixed(score?.ai_data?.score_vocabulary, 0)}/2`,
              guide:
                '<p><strong>2</strong> Good command of a broad lexical repertoire, idiomatic expressions and colloquialisms</p>' +
                '<p><strong>1</strong> Shows a good range of vocabulary for matters connected to general academic topics. Lexical shortcomings lead to circumlocution or some imprecision</p>' +
                '<p><strong>0</strong> Contains mainly basic vocabulary insufficient to deal with the topic at the required level</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
            {
              key: 'General Linguistic Range',
              value: `${scoreToFixed(
                score?.ai_data?.score_general_linguistic_range,
                0,
              )}/2`,
              guide:
                '<p><strong>2</strong> Exhibits smooth mastery of a wide range of language to formulate thoughts precisely, give emphasis, differentiate and eliminate ambiguity. No sign that the test taker is restricted in what they want to communicate</p>' +
                '<p><strong>1</strong> Sufficient range of language to provide clear descriptions, express viewpoints and develop arguments</p>' +
                '<p><strong>0</strong> Contains mainly basic language and lacks precision</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
            {
              key: 'Development Structure and Coherence Essay',
              value: `${scoreToFixed(
                score?.ai_data?.score_development_structure_coherence,
                0,
              )}/2`,
              guide:
                '<p><strong>2</strong> Shows good development and logical structure</p>' +
                '<p><strong>1</strong> Is incidentally less well structured, and some elements or paragraphs are poorly linked</p>' +
                '<p><strong>0</strong> Lacks coherence and mainly consists of lists or loose elements</p>' +
                '<p>(All traits are marked 0 if content is 0)</p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Writing',
              value: `${scoreToFixed(score.Writing)}/${scoreToFixed(
                skillMaxScore.Writing,
              )}`,
            },
          ]);
          setSkillDescription(
            'In real exam, each write essay question may contribute up to 11.2 marks toward writing. (Asm: 1Q total)',
          );
          setHeaderTitle('Write Essay ');
          setMaxScore(15);
          break;
        case 'WriteFromDictation':
          setTraitsTable([
            {
              key: 'Content',
              value: scoreToFixed(score?.ai_data?.score_task, 2),
              guide:
                '<p><strong>1</strong> Each correct word spelled correctly</p>' +
                '<p><strong>0</strong> Each incorrect or misspelled word</p>' +
                '<p>Partial credit (for each word spelled correctly)</p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Listening',
              value: `${scoreToFixed(score.Listening)}/${scoreToFixed(
                skillMaxScore.Listening,
              )}`,
            },
            {
              key: 'Writing',
              value: `${scoreToFixed(score.Writing)}/${scoreToFixed(
                skillMaxScore.Writing,
              )}`,
            },
          ]);
          setSkillDescription(
            'In real exam, each write from dictation question may contribute up to 8.5 & 8.9 marks toward listening and writing respectively. (Asm: 3Q total)',
          );
          setHeaderTitle('Write from Dictation');
          setMaxScore(12);
          break;
        case 'SummarizeSpokenText':
          setTraitsTable([
            {
              key: 'Content',
              value: `${scoreToFixed(score?.ai_data?.score_content, 0)}/2`,
              guide:
                '<p><strong>2</strong> Provides a good summary of the text. All relevant aspects are mentioned</p>' +
                '<p><strong>1</strong> Provides a fair summary of the text, but one or two aspects are missing</p>' +
                '<p><strong>0</strong> Omits or misrepresents the main aspects</p>',
            },
            {
              key: 'Form',
              value: `${scoreToFixed(score?.ai_data?.score_form, 0)}/2`,
              guide:
                '<p><strong>2</strong> Contains 50-70 words</p>' +
                '<p><strong>1</strong> Contains 40-49 words or 71-100 words</p>' +
                '<p><strong>0</strong> Contains less than 40 words or more than 100 words. Summary is written in capital letters, contains no punctuation or consists only of bullet points or very short sentences.</p>',
            },
            {
              key: 'Grammar',
              value: `${scoreToFixed(score?.ai_data?.score_grammar, 0)}/2`,
              guide:
                '<p><strong>2</strong> Correct grammatical structures</p>' +
                '<p><strong>1</strong> Contains grammatical errors with no hindrance to communication</p>' +
                '<p><strong>0</strong> Defective grammatical structure which could hinder communications</p>',
            },
            {
              key: 'Spelling',
              value: `${scoreToFixed(score?.ai_data?.score_spelling, 0)}/2`,
              guide:
                '<p><strong>2</strong> Correct spelling</p>' +
                '<p><strong>1</strong> One spelling error</p>' +
                '<p><strong>0</strong> More than one spelling errors</p>',
            },
            {
              key: 'Vocabulary',
              value: `${scoreToFixed(score?.ai_data?.score_vocabulary, 0)}/2`,
              guide:
                '<p><strong>2</strong> Appropriate choice of words</p>' +
                '<p><strong>1</strong> Some lexical errors but with no hindrance to communication</p>' +
                '<p><strong>0</strong> Defective word choice which could hinder communication</p>',
            },
          ]);
          setSkillTable([
            {
              key: 'Listening',
              value: `${scoreToFixed(score.Listening)}/${scoreToFixed(
                skillMaxScore.Listening,
              )}`,
            },
            {
              key: 'Writing',
              value: `${scoreToFixed(score.Writing)}/${scoreToFixed(
                skillMaxScore.Writing,
              )}`,
            },
          ]);
          setHeaderTitle('Summarize Spoken Text');
          setSkillDescription(
            'In real exam, each summarize spoken text question may contribute up to 2.7 & 6.0 marks toward listening and writing respectively. (Asm: 2Q total)',
          );
          setMaxScore(10);
          break;
        default:
          break;
      }
    }
  }, [score, category]);
  return (
    <>
    {loading ? (
        <Box  >

         <LoadingAIIndicator padding={'50px 300px'}/>
        </Box>
         
      ) : (
        <>
        {useragent.isDesktop ? (
        <>
          <Grid container justify="space-between" className={clsx(classes.header, classes.mainHeader)}>
            <Typography className={classes.headerText}>{type}</Typography>
            <Typography className={classes.headerText}>
              {headerTitle}
            </Typography>
            {!practiceNow && (
              <Typography className={classes.headerText} style={{ fontWeight: '400' }}>
                AI Tokens:{' '}
                <span className={classes.headerText}>
                  {`${remainingAIRequests || user?.remaining_ai_count}/${totalAIRequests || user?.total_ai_requests
                    }`}
                </span>
              </Typography>
            )}
          </Grid>
          <Grid container className={classes.body}>
            <Grid container spacing={3} justify="space-between">
              <Grid item xs={12} md={6} >
                {category === 'WriteFromDictation' ? (
                  <table className={classes.table}>
                    <thead className={classes.tableHeader}>
                      <tr>
                        <th scope="col">Number of Words</th>
                        <th scope="col" className={classes.scoreCol}>
                          {textareaInputArray?.length}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Correct Words
                          <GuideTooltip
                            title={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${traitsTable &&
                                    traitsTable[0] &&
                                    traitsTable[0].guide
                                    }`,
                                }}
                              />
                            }
                            arrow
                            placement="right-center"
                            style={{ backgroundColor: '#fff' }}>
                            <button
                              type="button"
                              style={{
                                border: 'none',
                                outline: 'none',
                                background: 'transparent',
                              }}>
                              <InfoIcon color={theme.palette.primary.main} />
                            </button>
                          </GuideTooltip>
                        </td>
                        <td className={classes.scoreCol}>
                          {
                            score?.ai_data?.word_sequence?.filter(
                              (item) => item.status === 'WRITTEN_AND_EXIST',
                            ).length
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <table className={classes.table}>
                    <thead className={classes.tableHeader}>
                      <tr>
                        <th scope="col">Traits</th>
                        <th scope="col" className={classes.scoreCol}>
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {traitsTable.map((item) => (
                        <tr>
                          <td>
                            {item.key}{' '}
                            <GuideTooltip
                              title={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.guide}`,
                                  }}
                                />
                              }
                              arrow
                              placement="right-center"
                              style={{ backgroundColor: '#fff' }}>
                              <button
                                type="button"
                                style={{
                                  border: 'none',
                                  outline: 'none',
                                  background: 'transparent',
                                }}>
                                <InfoIcon color={theme.palette.primary.main} />
                              </button>
                            </GuideTooltip>
                          </td>
                          <td className={classes.scoreCol}>{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Grid>
              <Grid item  xs={12} md={6}>
                <table className={classes.table}>
                  <thead>
                    <tr className={classes.tableHeader}>
                      <th scope="col">Communicative Skills</th>
                      <th scope="col" className={classes.scoreCol}>
                        Score
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillTable.map((item) => (
                      <tr>
                        <td>{item.key}</td>
                        <td className={classes.scoreCol}>{item.value}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="2" style={{ padding: 0 }}>
                        <div className={classes.scoreSection}>
                          <Typography className={classes.skillDescription}>
                            {skillDescription}
                          </Typography>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              justify="space-between"
              style={{ marginTop: '22px' }}>
              <Grid item  xs={12} md={6}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th
                        className={classes.header}
                        style={{ paddingLeft: '30px' }}>
                        {category === 'ReadAloud' ||
                          category === 'RepeatSentence' ||
                          category === 'DescribeImage' ||
                          category === 'RetellLecture' ||
                          category === 'AnswerShortQuestion'
                          ? 'AI Speech Recognition'
                          : 'AI Text Analysis'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{ paddingLeft: '30px' }}
                        className={classes.scoreSection}>
                        {category === 'ReadAloud' ||
                          category === 'RepeatSentence' ||
                          category === 'DescribeImage' ||
                          category === 'RetellLecture' ? (
                          <Grid container>
                            <div className={classes.icon}>
                              <Circle color="#2ABA86" /> Good
                            </div>
                            <div className={classes.icon}>
                              <Circle color="#0F4C8F" /> Average
                            </div>
                            <div className={classes.icon}>
                              <Circle color="#FF3C3C" /> Bad
                            </div>
                            <div className={classes.icon}>/ Pause</div>
                          </Grid>
                        ) : null}
                        {category === 'AnswerShortQuestion' ? (
                          <Grid container>
                            <div className={classes.icon}>
                              <Circle color="#2ABA86" /> Correct
                            </div>
                            <div className={classes.icon}>
                              <Circle color="#FF3C3C" /> Incorrect
                            </div>
                          </Grid>
                        ) : null}
                        {category === 'SummarizeWrittenText' ||
                          category === 'WriteEssay' ||
                          category === 'SummarizeSpokenText' ? (
                          <Grid container>
                            <Grid item container xs={12}>
                              <div className={classes.icon}>
                                <Circle color="#FF3C3C" /> Grammar
                              </div>
                              <div className={classes.icon}>
                                <Circle color="#E7961E" /> Spelling
                              </div>
                            </Grid>
                            <Typography>
                              Click the colored text for correction
                            </Typography>
                          </Grid>
                        ) : null}
                        {category === 'WriteFromDictation' ? (
                          <Grid container>
                            <div className={classes.icon}>
                              <Circle color="#2ABA86" /> Good
                            </div>
                            <div className={classes.icon}>
                              <Circle color="#FF3C3C" /> Incorrect or Missing
                            </div>
                          </Grid>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item  xs={12} md={6} className={classes.playerStyle}>
                {answer.startsWith('http') ? (
                  <Player
                    src={answer}
                    t={t}
                    isAnswer
                    showDownload
                    // height={48}
                    style={{ marginTop: '0 !important' }}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ marginTop: '15px' }}>
                {category === 'WriteFromDictation' ? (
                  <>
                    <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                      Your Answer:
                    </Typography>
                    {score?.ai_data?.word_sequence.map((x, index) => {
                      return (
                        <>
                          {answerArray.find(
                            (z) =>
                              z.replace(/[^\w\s]/gi, '').toLowerCase() ===
                              x.word.replace(/[^\w\s]/gi, '').toLowerCase() &&
                              x.status === 'WRITTEN_AND_EXIST',
                          ) ? (
                            <p
                              key={index.toString() + x.toString()}
                              style={
                                x.status === 'WRITTEN_AND_EXIST' && {
                                  ...baseClass,
                                  ...goodClass,
                                }
                              }>
                              {x.word} {x.status === 'STRUCK' && '/'}
                            </p>
                          ) : x.status === 'WRITTEN_AND_DONT_EXIST' ? (
                            <p
                              style={{
                                ...baseClass,
                                ...badClass,
                                textDecoration: 'line-through',
                              }}>
                              {x.word}
                            </p>
                          ) : (
                            <p
                              style={{
                                ...baseClass,
                                ...badClass,
                              }}>
                              {'{'}
                              {x.word}
                              {'}'}
                            </p>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : null}
                {category === 'WriteFromDictation' ? (
                  <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                    Correct Answer:
                  </Typography>
                ) : null}
                {category !== 'AnswerShortQuestion' &&
                  textareaInputArray.map((wrd, index) => {
                    let style = { ...baseClass };
                    if (checkIsStatus(wrd, index) === 'GOOD')
                      style = { ...baseClass, ...goodClass };
                    if (checkIsStatus(wrd, index) === 'AVERAGE')
                      style = { ...baseClass, ...averageClass };
                    if (
                      checkIsStatus(wrd, index) === 'BAD' ||
                      checkIsStatus(wrd, index) === 'MISSED'
                    )
                      style = { ...baseClass, ...badClass };
                    if (checkIsStatus(wrd, index) === 'WRITTEN_AND_DONT_EXIST')
                      style = { ...baseClass, textDecoration: 'line-through' };
                    if (
                      checkIsStatus(wrd, index) &&
                      checkIsStatus(wrd, index).type &&
                      checkIsStatus(wrd, index).type === 'SPELLING'
                    )
                      style = { ...baseClass, ...spellClass };
                    if (
                      checkIsStatus(wrd, index) &&
                      checkIsStatus(wrd, index).type &&
                      checkIsStatus(wrd, index).type === 'GRAMMAR'
                    )
                      style = { ...baseClass, ...badClass };
                    return (
                      <div className={classes.itemContainer}>
                        {category === 'WriteFromDictation' ? (
                          <p key={wrd + index}>{wrd}</p>
                        ) : (
                          <>
                            {checkIsStatus(wrd, index) &&
                              checkIsStatus(wrd, index).type ? (
                              <LightTooltip
                                title={`suggestion: ${checkIsStatus(wrd, index).suggestion
                                  }`}
                                arrow
                                placement="top"
                                style={{ backgroundColor: '#fff' }}>
                                <p style={style} key={wrd + index}>
                                  {wrd}
                                </p>
                              </LightTooltip>
                            ) : (
                              <>
                                <p style={style} key={wrd + index}>
                                  {wrd}
                                </p>
                                {checkPuasesStatus(wrd, index) ? '/' : null}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                {category === 'AnswerShortQuestion' ? (
                  <>
                    <Grid style={{ marginTop: '22px' }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                        Your Answer:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          marginTop: '15px',
                          color: score?.ai_data.score_task
                            ? '#2ABA86'
                            : '#FF3C3C',
                        }}>
                        {score?.ai_data?.transcribed_text}
                      </Typography>
                    </Grid>
                    <Grid style={{ marginTop: '22px' }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                        Correct Answer:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          marginTop: '15px',
                        }}>
                        {transcript}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <a
                href="/subscribe?type=ai"
                target="_blank"
                className={classes.byAiButton}>
                Buy AI Tokens
              </a>
              <Button className={classes.close} onClick={onClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
        
          <Grid
            container
            justify="space-between"
            className={classes.headerMobile}>
            <Typography className={classes.headerTextMobile}>
              {title}
            </Typography>
            {!practiceNow && (
              <Typography className={classes.headerTextMobile}>
                AI Tokens:{' '}
                <span className={classes.headerTextMobile}>{`${remainingAIRequests || user.remaining_ai_count
                  }/${totalAIRequests || user.total_ai_requests}`}</span>
              </Typography>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <button
                type="button"
                className={clsx(classes.tabButton, {
                  [classes.activeTab]: activeTab === 0,
                })}
                onClick={() => setActiveTab(0)}>
                Traits
              </button>
            </Grid>
            <Grid item xs={6}>
              <button
                type="button"
                className={clsx(classes.tabButton, {
                  [classes.activeTab]: activeTab === 1,
                })}
                onClick={() => setActiveTab(1)}>
                Communicative Skills
              </button>
            </Grid>
          </Grid>
          <Grid container className={classes.mobileBody}>
            <Grid container style={{ marginTop: '25px' }}>
              {activeTab === 0 ? (
                <>
                  <Grid item xs={12}>
                    {category === 'WriteFromDictation' ? (
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th scope="col">Number of Words</th>
                            <th scope="col" className={classes.scoreCol}>
                              {textareaInputArray?.length}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Correct Words{' '}
                              <button
                                onClick={() => {
                                  setModalContent(
                                    traitsTable &&
                                    traitsTable[0] &&
                                    traitsTable[0].guide,
                                  );
                                  setOpenModal(true);
                                }}
                                type="button"
                                style={{
                                  border: 'none',
                                  outline: 'none',
                                  background: 'transparent',
                                }}>
                                <InfoIcon color={theme.palette.primary.main} />
                              </button>
                            </td>
                            <td className={classes.scoreCol}>
                              {
                                score?.ai_data?.word_sequence?.filter(
                                  (item) => item.status === 'WRITTEN_AND_EXIST',
                                ).length
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th scope="col">Traits</th>
                            <th scope="col" className={classes.scoreCol}>
                              Score
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {traitsTable.map((item) => (
                            <tr>
                              <td>
                                {item.key}
                                <button
                                  onClick={() => {
                                    setModalContent(item.guide);
                                    setOpenModal(true);
                                  }}
                                  type="button"
                                  style={{
                                    border: 'none',
                                    outline: 'none',
                                    background: 'transparent',
                                  }}>
                                  <InfoIcon
                                    color={theme.palette.primary.main}
                                  />
                                </button>
                              </td>
                              <td className={classes.scoreCol}>{item.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {answer.startsWith('http') ? (
                      <Grid item xs={12}>
                        <Player src={answer} t={t} isAnswer showDownload />
                      </Grid>
                    ) : null}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <table className={classes.table}>
                    <thead>
                      <tr className={classes.tableHeader}>
                        <th scope="col">Communicative Skills</th>
                        <th scope="col" className={classes.scoreCol}>
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {skillTable.map((item) => (
                        <tr>
                          <td>{item.key}</td>
                          <td className={classes.scoreCol}>{item.value}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="2" style={{ padding: 0 }}>
                          <div className={classes.scoreSection}>
                            <Typography className={classes.skillDescription}>
                              {skillDescription}
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              )}
            </Grid>
            <Grid container style={{ marginTop: '25px' }}>
              <Grid item xs={12}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th
                        className={classes.header}
                        style={{ paddingLeft: '30px' }}>
                        {category === 'ReadAloud' ||
                          category === 'RepeatSentence' ||
                          category === 'DescribeImage' ||
                          category === 'RetellLecture' ||
                          category === 'AnswerShortQuestion'
                          ? 'AI Speech Recognition'
                          : 'AI Text Analysis'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{ paddingLeft: '30px' }}
                        className={classes.scoreSection}>
                        {category === 'ReadAloud' ||
                          category === 'RepeatSentence' ||
                          category === 'DescribeImage' ||
                          category === 'RetellLecture' ? (
                          <Grid container>
                            <div className={classes.icon}>
                              <Circle color="#2ABA86" /> Good
                            </div>
                            <div className={classes.icon}>
                              <Circle color="#0F4C8F" /> Average
                            </div>
                            <div className={classes.icon}>
                              <Circle color="#FF3C3C" /> Bad
                            </div>
                            <div className={classes.icon}>/ Pause</div>
                          </Grid>
                        ) : null}
                        {category === 'AnswerShortQuestion' ? (
                          <Grid container>
                            <div className={classes.icon}>
                              <Circle color="#2ABA86" /> Correct
                            </div>
                            <div className={classes.icon}>
                              <Circle color="#FF3C3C" /> Incorrect
                            </div>
                          </Grid>
                        ) : null}
                        {category === 'SummarizeWrittenText' ||
                          category === 'WriteEssay' ||
                          category === 'SummarizeSpokenText' ? (
                          <Grid container>
                            <Grid item container xs={12}>
                              <div className={classes.icon}>
                                <Circle color="#FF3C3C" /> Grammar
                              </div>
                              <div className={classes.icon}>
                                <Circle color="#E7961E" /> Spelling
                              </div>
                            </Grid>
                            <Typography>
                              Tap on the colored text for correction
                            </Typography>
                          </Grid>
                        ) : null}
                        {category === 'WriteFromDictation' ? (
                          <Grid container>
                            <div className={classes.icon}>
                              <Circle color="#2ABA86" /> Good
                            </div>
                            <div className={classes.icon}>
                              <Circle color="#FF3C3C" /> Incorrect or Missing
                            </div>
                          </Grid>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ marginTop: '15px' }}>
                {category === 'WriteFromDictation' ? (
                  <>
                    <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                      Your Answer:
                    </Typography>
                    {score?.ai_data?.word_sequence.map((x, index) => {
                      return (
                        <>
                          {answerArray.find(
                            (z) =>
                              z.replace(/[^\w\s]/gi, '').toLowerCase() ===
                              x.word.replace(/[^\w\s]/gi, '').toLowerCase() &&
                              x.status === 'WRITTEN_AND_EXIST',
                          ) ? (
                            <p
                              key={index.toString() + x.toString()}
                              style={
                                x.status === 'WRITTEN_AND_EXIST' && {
                                  ...baseClass,
                                  ...goodClass,
                                }
                              }>
                              {x.word} {x.status === 'STRUCK' && '/'}
                            </p>
                          ) : x.status === 'WRITTEN_AND_DONT_EXIST' ? (
                            <p
                              style={{
                                ...baseClass,
                                ...badClass,
                                textDecoration: 'line-through',
                              }}>
                              {x.word}
                            </p>
                          ) : (
                            <p
                              style={{
                                ...baseClass,
                                ...badClass,
                              }}>
                              {'{'}
                              {x.word}
                              {'}'}
                            </p>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : null}
                {category === 'WriteFromDictation' ? (
                  <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                    Correct Answer:
                  </Typography>
                ) : null}
                {category !== 'AnswerShortQuestion' &&
                  textareaInputArray.map((wrd, index) => {
                    let style = { ...baseClass };
                    if (checkIsStatus(wrd, index) === 'GOOD')
                      style = { ...baseClass, ...goodClass };
                    if (checkIsStatus(wrd, index) === 'AVERAGE')
                      style = { ...baseClass, ...averageClass };
                    if (
                      checkIsStatus(wrd, index) === 'BAD' ||
                      checkIsStatus(wrd, index) === 'MISSED'
                    )
                      style = { ...baseClass, ...badClass };
                    if (checkIsStatus(wrd, index) === 'WRITTEN_AND_DONT_EXIST')
                      style = { ...baseClass, textDecoration: 'line-through' };
                    if (
                      checkIsStatus(wrd, index) &&
                      checkIsStatus(wrd, index).type &&
                      checkIsStatus(wrd, index).type === 'SPELLING'
                    )
                      style = { ...baseClass, ...spellClass };
                    if (
                      checkIsStatus(wrd, index) &&
                      checkIsStatus(wrd, index).type &&
                      checkIsStatus(wrd, index).type === 'GRAMMAR'
                    )
                      style = { ...baseClass, ...badClass };
                    return (
                      <div className={classes.itemContainer}>
                        {category === 'WriteFromDictation' ? (
                          <>
                            <p style={baseClass} key={wrd + index}>
                              {wrd}
                            </p>
                          </>
                        ) : (
                          <>
                            {checkIsStatus(wrd, index) &&
                              checkIsStatus(wrd, index).type ? (
                              <p
                                style={style}
                                key={wrd + index}
                                onClick={() => {
                                  setCorrectionModalContent(
                                    checkIsStatus(wrd, index).suggestion,
                                  );
                                  setCorrectionModal(true);
                                }}>
                                {wrd}
                              </p>
                            ) : (
                              <>
                                <p style={style} key={wrd + index}>
                                  {wrd}
                                </p>
                                {checkPuasesStatus(wrd, index) ? '/' : null}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                {category === 'AnswerShortQuestion' ? (
                  <>
                    <Grid style={{ marginTop: '22px' }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                        Your Answer:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          marginTop: '15px',
                          color: score?.ai_data.score_task
                            ? '#2ABA86'
                            : '#FF3C3C',
                        }}>
                        {score?.ai_data?.transcribed_text}
                      </Typography>
                    </Grid>
                    <Grid style={{ marginTop: '22px' }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                        Correct Answer:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          marginTop: '15px',
                        }}>
                        {transcript}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: '25px' }}>
              <Grid item xs={10}>
                <a
                  href="/subscribe?type=ai"
                  target="_blank"
                  className={classes.byAiButtonMobile}>
                  Buy AI Tokens
                </a>
              </Grid>
              <Grid item xs={10}>
                <button
                  type="button"
                  className={classes.closeButton}
                  onClick={onClose}>
                  Close
                </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Dialog
              open={openModal}
              onClose={() => setOpenModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${modalContent}`,
                  }}
                />
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid>
            <Dialog
              open={correctionModal}
              onClose={() => setCorrectionModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogContent>
                <p>suggestion: {correctionModalContent}</p>
              </DialogContent>
            </Dialog>
          </Grid>
        </>
      )}
        </>

      )}
      
    </>
  );
};

AiScore.propTypes = {
  category: PropTypes.string,
  score: PropTypes.number,
  onClose: PropTypes.func,
  answer: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string,
  useragent: PropTypes.object,
  practiceNow: PropTypes.bool,
};

AiScore.defaultProps = {
  category: '',
  score: 0,
  onClose: () => { },
  answer: '',
  t: () => { },
  title: '',
  useragent: {},
  practiceNow: false,
};

export default AiScore;
