import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CourseAppBar from './CourseAppBar';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  newRoot: {
    paddingBottom: 0,
  },
  hint: {
    background: '#EDF0F2',
  },
  mobileHint: {
    background: '#EDF0F2',
    padding: 0,
    margin: 0,
  },
  instructionsBox: {
    width: '100%',
  },
  instructionText: {
    fontSize: '18px',
    fontWeight: 400,
    color: '#353535',
  },
  instructionTextMobile: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#353535',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
  },
  headingMobile: {
    fontSize: '14px',
  },
}));

/* Provider custom mobile appbar + bottom bar */
const CourseHoc = ({
  t,
  children,
  type,
  title,
  rightTitle,
  hint,
  loading,
  showHint,
  showAppBarRight,
  showAppBarLeft,
  useragent,
  isTrainingVideo = false,
}) => {
  const classes = useStyles();

  return useragent.isDesktop ? (
    <Paper
      elevation={0}
      className={isTrainingVideo ? classes.newRoot : classes.root}>
      {!loading && (
        <CourseAppBar
          t={t}
          title={title}
          rightTitle={rightTitle}
          type={type}
          showRight={showAppBarRight}
          showLeft={showAppBarLeft}
        />
      )}
      {!loading && showHint && (
        <Grid container alignItems="center" className={classes.hint}>
          <Box
            pl={5.625}
            pr={5.625}
            pt={3}
            pb={3}
            className={classes.instructionsBox}>
            <Typography variant="body1" className={classes.instructionText}>
              {hint}
            </Typography>
          </Box>
        </Grid>
      )}
      <Box
        mt={isTrainingVideo ? 0 : 3.25}
        pl={isTrainingVideo ? 0 : 5.625}
        pr={isTrainingVideo ? 0 : 5.625}
        pb={isTrainingVideo ? 0 : 2}>
        {children}
      </Box>
    </Paper>
  ) : (
    <Paper
      elevation={0}
      className={isTrainingVideo ? classes.newRoot : classes.root}>
      <CourseAppBar
        t={t}
        title={title}
        type={type}
        rightTitle={rightTitle}
        // stopTimer={stopTimer}
        // resetTimer={resetTimer}
      />
      {!loading && showHint && (
        <Grid container alignItems="center" className={classes.hint}>
          <Box className={classes.instructionsBox}>
            <Accordion elevation={0} className={classes.mobileHint}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  className={
                    useragent.isDesktop
                      ? classes.heading
                      : classes.headingMobile
                  }>
                  {t('instructions')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  className={
                    useragent.isDesktop
                      ? classes.instructionText
                      : classes.instructionTextMobile
                  }>
                  {hint}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      )}
      <Box
        mt={isTrainingVideo ? 0 : 1}
        pl={isTrainingVideo ? 0 : 1}
        pr={isTrainingVideo ? 0 : 1}
        pb={0}>
        {children}
      </Box>
    </Paper>
  );
};

CourseHoc.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  type: PropTypes.oneOf(['Reading', 'Writing', 'Listening', 'Speaking']),
  title: PropTypes.string,
  rightTitle: PropTypes.string,
  typeId: PropTypes.string,
  hint: PropTypes.string,
  showHint: PropTypes.bool,
  showAppBarRight: PropTypes.bool,
  showAppBarLeft: PropTypes.bool,
  loading: PropTypes.bool,
  typeQuestionsLoading: PropTypes.bool,
  questions: PropTypes.shape(),
  onQuestionChange: PropTypes.func,
  useragent: PropTypes.object,
  question: PropTypes.any,
};

CourseHoc.defaultProps = {
  type: null,
  typeId: null,
  title: null,
  rightTitle: null,
  hint: null,
  showHint: true,
  showAppBarRight: true,
  showAppBarLeft: true,
  questions: null,
  onQuestionChange: () => {},
  useragent: {},
  question: {},
  loading: false,
  typeQuestionsLoading: false,
};

export default CourseHoc;
