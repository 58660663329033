import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles, styled} from '@material-ui/core/styles';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import StepThree from './Steps/StepThree';
import StepFour from './Steps/StepFour';
import StepLoading from './Steps/StepLoading';

import {
  createStudyPlanAction,
  setShowupModalAction,
} from 'core/lib/adapters/redux/actions';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTop: '6px solid #0F4C8F',
    padding: '20px 30px',
  },
  steps: {
    color: '#0F4C8F',
    fontSize: '16px',
    '& span': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  close: {
    cursor: 'pointer',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  content: {
    padding: '0px 30px',
    minHeight: 400,
  },
  mobileContent:{
    padding: '0px 30px',
    // minHeight: 300,
  },
  footer: {
    padding: '30px 30px 30px 30px',
  },
  btn: {
    color: 'white',
    background: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14.4px',
    height: '38px',
    minWidth: '100px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
    marginBottom: 15,
  },
  prevbtn: {
    color: theme.palette.primary.main,
    background: 'rgba(15, 76, 143, 0.15)',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14.4px',
    height: '38px',
    minWidth: '100px',
    marginBottom: 15,
  },
  nextErrorText: {
    color: 'red',
    fontSize: 14,
    fontWeight: 600,
    marginRight: 30,
    marginBottom: 15,
  },
}));

function StudyPlanModals({useragent}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [takenBefore, setTakenBefore] = useState(false);
  const [closeModal, setCloseModal] = useState(0);
  const [studyPlanData, setStudyPlanData] = useState({});

  const [nextError, setNextError] = useState({
    message: 'All Fields are Required.',
    status: false,
  });

  const user = useSelector((state) => state.auth.user);
  const modalIsShow = useSelector((state) => state.studyPlan.modalIsShow);

  let res = ""
  const setNext = async () => {
    if (takenBefore && page == 1) {
      setPage(2);
    }

    setPage((s) => s + 1);

    if (page == 4)dispatch(
        createStudyPlanAction({
          user_id: user.id,
          type: 'plan',
          ...studyPlanData,
        }),
      );
      if(res?.payload?.date_of_exam){
        dispatch(getProfile());
      }
   };
  const setPrev = () => {
    if (page - 1 <= 0) return;
    if (page === 3 && takenBefore) setPage(2);
    setPage((s) => s - 1);
    setNextError({message: 'All Fields are Required.', status: false});
  };

  useEffect(() => {}, [nextError]);
  useEffect(() => {
    if (page == 2) {
      if (
        !studyPlanData.speaking ||
        !studyPlanData.writing ||
        !studyPlanData.reading ||
        !studyPlanData.listening ||
        !studyPlanData.grammar ||
        !studyPlanData.fluency ||
        !studyPlanData.pronunciation ||
        !studyPlanData.spelling ||
        !studyPlanData.vocabulary ||
        !studyPlanData.discourse ||
        studyPlanData.speakingError ||
        studyPlanData.writingError ||
        studyPlanData.readingError ||
        studyPlanData.listeningError ||
        studyPlanData.grammarError ||
        studyPlanData.fluencyError ||
        studyPlanData.pronunciationError ||
        studyPlanData.spellingError ||
        studyPlanData.vocabularyError ||
        studyPlanData.discourseError
      )
        setNextError({message: 'All Fields are Required.', status: true});
      else setNextError({message: 'All Fields are Required.', status: false});
    } else if (page == 3) {
      if (
        !studyPlanData.target_l ||
        !studyPlanData.target_r ||
        !studyPlanData.target_s ||
        !studyPlanData.target_w ||
        studyPlanData.target_sError ||
        studyPlanData.target_rError ||
        studyPlanData.target_lError ||
        studyPlanData.target_wError
      )
        setNextError({message: 'All Fields are Required.', status: true});
      else setNextError({message: 'All Fields are Required.', status: false});
    }
  }, [page, studyPlanData]);

  useEffect(() => {
    setPage(1);
  }, [closeModal]);




  return (
    <Dialog
      fullWidth="sm"
      maxWidth="sm"
      open={modalIsShow}
      onClose={() => {}}
      aria-labelledby="max-width-dialog-title">
      <div className={classes.header}>
        {page !== 5 && (
          <Grid container justify="space-between">
            <Grid item className={classes.steps}>
              <span>{page}</span> of 4
            </Grid>
            <Grid item>
              <div
                className={classes.close}
                onClick={() => {
                  dispatch(setShowupModalAction({statuse: false}));
                  setPage(1);
                  setNextError({
                    message: 'All Fields are Required.',
                    status: false,
                  });
                }}>
                <CloseIcon />
              </div>
            </Grid>
          </Grid>
        )}
      </div>

      <div className={useragent && useragent.isMobile?classes.mobileContent:classes.content}>
        {page === 1 && (
          <StepOne
            isTakenBefore={(value) => {
              if (value === 'no') setTakenBefore(true);
              else setTakenBefore(false);
            }}
          />
        )}
        {page === 2 && <StepTwo sendData={setStudyPlanData} />}
        {page === 3 && (
          <StepThree
            studyPlanData={studyPlanData}
            sendData={(data) => {
              setStudyPlanData(data);
            }}
          />
        )}
        {page === 4 && (
          <StepFour studyPlanData={studyPlanData} sendData={setStudyPlanData} />
        )}
        {page === 5 && <StepLoading closeModal={setCloseModal} />}
      </div>

      <div className={classes.footer}>
        {page !== 5 && (
          <Grid container justify="space-between" alignItems="center">
            <Grid container item xs={12}>
              {nextError.status && (
                <Typography
                  variant="subtitle1"
                  style={{textAlign: 'center'}}
                  className={classes.nextErrorText}>
                  {nextError.message}
                </Typography>
              )}
            </Grid>

            <Grid container item xs={12} justify="flex-end">
              {page !== 1 && (
                <Button
                  variant="contained"
                  onClick={setPrev}
                  className={classes.prevbtn}>
                  Prev
                </Button>
              )}
              <Button
                variant="contained"
                disabled={nextError.status}
                onClick={setNext}
                className={classes.btn}>
                Next
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </Dialog>
  );
}

export default StudyPlanModals;
