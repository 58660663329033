import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import CrossIcon from 'components/Icons/gen/Cross';
import CheckIcon from 'components/Icons/gen/Check';
import {sortArrayShuffle} from '../utils/utils';

const useStyles = makeStyles((theme) => ({
  selectComponent: {
    '&:before': {
      content: '""',
      width: 0,
    },
    background: '#EDF0F2',
    borderRadius: theme.spacing(0.5),
  },
  select: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingTop: 7,
    paddingBottom: 7,
  },
  selectIcon: {
    color: theme.palette.primary.main,
  },
  selectContainer: {
    background: '#EDF0F2',
    borderRadius: 3,
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // marginBottom: 10,
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  answer: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  itemText: {
    lineHeight: 2.3,
    fontSize: '18px',
    fontWeight: 400,
    color: '#353535',
  },
  correct: {
    fontSize: '18px',
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  wrong: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#FF3C3C',
  },
}));
const RWFillInTheBlanksPractice = ({
  t,
  question,
  onAnswered,
  answer,
  questionAnswer,
  submittedQuestion,
  mockAnswer,
}) => {
  const classes = useStyles();
  const [practiceAnswer, setAnswer] = useState(answer || []);
  const [blanks, setBlanks] = useState(question.question_data.blanks || []);
  const blankLength = blanks.length;
  const splitted = question.question_data.text.split('<blank/>');
  const theme = useTheme();
  const onAnswerChange = (index, e) => {
    const {value} = e.target;
    const preAnswer = [...practiceAnswer];
    preAnswer[index] = value;
    setAnswer(preAnswer);
  };

  useEffect(() => {
    setBlanks((blanks) => blanks.map((blank) => sortArrayShuffle(blank)));
  }, []);

  useEffect(() => {
    const filledAnswer = [];
    blanks.forEach((_, index) => {
      if (!practiceAnswer[index]) filledAnswer.push('');
      else filledAnswer.push(practiceAnswer[index]);
    });
    onAnswered(filledAnswer);
  }, [practiceAnswer]);

  useEffect(() => {
    if (mockAnswer && answer && answer.length > 0) {
      setAnswer(answer);
    }
  }, [answer]);
  return (
    <Grid
      item
      xs={12}
      style={{lineHeight: 3, display: 'contents', alignItems: 'center'}}>
      {splitted.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Typography
              variant="body1"
              display="inline"
              className={classes.itemText}>
              {item}
            </Typography>
            {index !== blankLength && (
              <span className={classes.selectContainer}>
                {submittedQuestion &&
                  answer &&
                  answer.length > 0 &&
                  (answer &&
                  questionAnswer &&
                  questionAnswer['acceptable-answers'] &&
                  questionAnswer['acceptable-answers'].data &&
                  answer[index] ===
                    questionAnswer['acceptable-answers'].data[0].answer[
                      index
                    ] ? (
                    <CheckIcon
                      color={theme.palette.primary.main}
                      className={classes.icon}
                    />
                  ) : (
                    <CrossIcon color="#f00" className={classes.icon} />
                  ))}
                <Select
                  native
                  variant="filled"
                  value={practiceAnswer[index]}
                  onChange={(e) => onAnswerChange(index, e)}
                  inputProps={{
                    name: 'blankField',
                    id: 'blank-field',
                  }}
                  className={classes.selectComponent}
                  classes={{
                    root: classes.selectRoot,
                    select: classes.select,
                    icon: classes.selectIcon,
                    filled: submittedQuestion
                      ? questionAnswer &&
                        questionAnswer['acceptable-answers'] &&
                        questionAnswer['acceptable-answers'].data &&
                        answer && answer[index] ===
                          questionAnswer['acceptable-answers'].data[0].answer[
                            index
                          ]
                        ? classes.correct
                        : classes.wrong
                      : '',
                  }}
                  open={false}>
                  <option value={null} disabled={submittedQuestion}>
                    -- select --
                  </option>
                  {blanks &&
                    blanks[index].map((blank) => (
                      <option
                        disabled={submittedQuestion}
                        value={blank}
                        key={blank}>
                        {blank}
                      </option>
                    ))}
                </Select>
              </span>
            )}
            {submittedQuestion && (
              <Typography
                className={classes.answer}
                variant="body1"
                color="primary"
                display="inline">
                {questionAnswer &&
                  questionAnswer['acceptable-answers'] &&
                  questionAnswer['acceptable-answers'].data &&
                  questionAnswer['acceptable-answers'].data[0].answer[index]}
              </Typography>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

RWFillInTheBlanksPractice.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_type: PropTypes.string.isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.array,
  submittedQuestion: PropTypes.bool.isRequired,
  questionAnswer: PropTypes.shape(),
  mockAnswer: PropTypes.bool,
};
RWFillInTheBlanksPractice.defaultProps = {
  answer: [],
  questionAnswer: null,
  mockAnswer: false,
};

export default RWFillInTheBlanksPractice;
