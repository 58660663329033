import * as React from 'react'

function SvgWriting(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1v-5.34a1 1 0 112 0V20a3 3 0 01-3 3H4a3 3 0 01-3-3V6a3 3 0 013-3h5.34a1 1 0 010 2H4z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.293 1.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-10 10A1 1 0 0112 17H8a1 1 0 01-1-1v-4a1 1 0 01.293-.707l10-10zM9 12.414V15h2.586l9-9L18 3.414l-9 9z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgWriting
