import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import Skill from '../SubCatagoryContents/Skill';

import PracticeQuestions from '../SubCatagoryContents/PracticeQuestions';
import MockTest from '../SubCatagoryContents/MockTest';

import AppTab from './AppTab';
import AppTabs from './AppTabs';

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: 20,
  },
  skillBox: {
    padding: '10px 15px',
    border: '1px solid #E5E5E5',
    marginRight: 15,
    cursor: 'pointer',
  },
  skillBoxMobile: {
    padding: '10px 10px',
    border: '1px solid #E5E5E5',
    marginRight: 8,
    cursor: 'pointer',
    fontSize: 10,
  },
  skillContainerMobile: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
    marginTop: 30,
  },
  suggestedText: {
    color: '#808080',
    fontSize: '1em',
    fontWeight: 500,
    marginTop: 20,
  },
}));

const PracticePathway = ({useragent}) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState('Practice Questions');
  const [activeSkill, setActiveSkill] = useState(0);

  const [page, setPage] = useState(1);
  const [userQuery, setUserQuery] = useState('');

  const studyPlan = useSelector((state) => state.studyPlan.studyPlan);
  const [recommandeds, setRecommandeds] = useState([]);
  const [mocktest, setMocktest] = useState([]);
  const [week, setWeek] = useState(1);

  const changeActiveTabHandler = (event, newValue, useragentStatuse) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      setActiveTabName('Practice Questions');
    }
    if (newValue === 1) {
      setActiveTabName('Mock Tests');
    }
  };

  const getRecommandedBasedOnSkill = (skill) => {
    const newRec = studyPlan?.Recommended?.filter((i) => i.g === skill);
    setRecommandeds(newRec);
  };

  const getQuestionListHandler = () => {
    if (activeTabName === 'Practice Questions') {
    } else if (activeTabName === 'Mock Tests') {
    }
  };

  useEffect(() => {
    getQuestionListHandler();
  }, [page, activeTabName, userQuery]);

  useEffect(() => {
    changeActiveTabHandler('e', 0, useragent.isMobile || useragent.isTablet);
  }, []);

  useEffect(() => {
    getRecommandedBasedOnSkill('S');
    setMocktest(studyPlan?.Mocktest);
    setWeek(studyPlan?.targets?.week);
  }, [studyPlan]);

  return (
    <>
      {useragent.isMobile || useragent.isTablet ? (
        <>
          <Grid className={classes.root}>
            <Grid container>
              <AppTabs
                value={activeTab}
                onChange={(e, newValue) =>
                  changeActiveTabHandler(
                    e,
                    newValue,
                    useragent.isMobile || useragent.isTablet,
                  )
                }
                indicatorColor="primary"
                textColor="primary"
                // centered
              >
                <AppTab label={'Practice Questions'} />
                <AppTab label={'Mock Tests'} />
              </AppTabs>
            </Grid>

            {activeTabName === 'Practice Questions' && (
              <Grid
                className={classes.skillContainerMobile}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start">
                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBoxMobile}
                  style={{
                    borderBottom: activeSkill === 0 ? '3px solid #0F4C8F' : '',
                  }}
                  onClick={() => {
                    getRecommandedBasedOnSkill('S');
                    setActiveSkill(0);
                  }}
                  xs={3}>
                  Speaking
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBoxMobile}
                  style={{
                    borderBottom: activeSkill === 1 ? '3px solid #0F4C8F' : '',
                  }}
                  onClick={() => {
                    getRecommandedBasedOnSkill('W');
                    setActiveSkill(1);
                  }}
                  xs={3}>
                  Writing
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBoxMobile}
                  style={{
                    borderBottom: activeSkill === 2 ? '3px solid #0F4C8F' : '',
                  }}
                  onClick={() => {
                    getRecommandedBasedOnSkill('R');
                    setActiveSkill(2);
                  }}
                  xs={3}>
                  Reading
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBoxMobile}
                  style={{
                    borderBottom: activeSkill === 3 ? '3px solid #0F4C8F' : '',
                  }}
                  onClick={() => {
                    getRecommandedBasedOnSkill('L');
                    setActiveSkill(3);
                  }}
                  xs={3}>
                  Listening
                </Grid>
              </Grid>
            )}

            <Grid container item xs={12}>
              <Typography variant="subtitle1" className={classes.suggestedText}>
                No. of questions to practice per week until your exam
              </Typography>
            </Grid>

            {activeTabName === 'Practice Questions' ? (
              <>
                <PracticeQuestions useragent={useragent} data={recommandeds} week={week}/>
              </>
            ) : (
              <>
                <MockTest useragent={useragent} data={mocktest} />
              </>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid className={classes.root}>
            <Grid container>
              <AppTabs
                value={activeTab}
                onChange={(e, newValue) =>
                  changeActiveTabHandler(
                    e,
                    newValue,
                    useragent.isMobile || useragent.isTablet,
                  )
                }
                indicatorColor="primary"
                textColor="primary"
                // centered
              >
                <AppTab label={'Practice Questions'} />
                <AppTab label={'Mock Tests'} />
              </AppTabs>
            </Grid>

            {activeTabName === 'Practice Questions' && (
              <Grid
                container
                style={{marginTop: 40}}
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBox}
                  style={{
                    borderBottom: activeSkill === 0 ? '3px solid #0F4C8F' : '',
                  }}
                  onClick={() => {
                    getRecommandedBasedOnSkill('S');
                    setActiveSkill(0);
                  }}
                  xs={2}>
                  Speaking
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBox}
                  style={{
                    borderBottom: activeSkill === 1 ? '3px solid #0F4C8F' : '',
                  }}
                  onClick={() => {
                    getRecommandedBasedOnSkill('W');
                    setActiveSkill(1);
                  }}
                  xs={2}>
                  Writing
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBox}
                  style={{
                    borderBottom: activeSkill === 2 ? '3px solid #0F4C8F' : '',
                  }}
                  onClick={() => {
                    getRecommandedBasedOnSkill('R');
                    setActiveSkill(2);
                  }}
                  xs={2}>
                  Reading
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBox}
                  style={{
                    borderBottom: activeSkill === 3 ? '3px solid #0F4C8F' : '',
                  }}
                  onClick={() => {
                    getRecommandedBasedOnSkill('L');
                    setActiveSkill(3);
                  }}
                  xs={2}>
                  Listening
                </Grid>
              </Grid>
            )}

            <Grid container item xs={12}>
              <Typography variant="subtitle1" className={classes.suggestedText}>
                No. of questions to practice per week until your exam
              </Typography>
            </Grid>

            {activeTabName === 'Practice Questions' ? (
              <>
                <PracticeQuestions useragent={useragent} data={recommandeds} week={week}/>
              </>
            ) : (
              <>
                <MockTest useragent={useragent} data={mocktest} />
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

PracticePathway.propTypes = {
  useragent: PropTypes.object,
};

PracticePathway.defaultProps = {
  t: () => null,
  onQuestionChange: () => {},
  useragent: {},
  sidebar: false,
};

export default PracticePathway;
