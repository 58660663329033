import * as React from 'react'

function SvgVocabBookImage(props) {
  return (
    <svg width={72} height={72} {...props}>
      <g fill="none">
        <circle fill="#FFF2F4" cx={36} cy={36} r={36} />
        <g opacity={0.9}>
          <path
            d="M23.887 15.974h24.667a5.666 5.666 0 015.666 5.666v26.666a5.666 5.666 0 01-5.666 5.666H23.887a5.666 5.666 0 01-5.666-5.666V21.64a5.666 5.666 0 015.665-5.666h.001z"
            fill="#F98F93"
          />
          <path
            d="M23.018 53.97a4.8 4.8 0 010-9.601h26.397a3 3 0 01.343.013h.209a4.258 4.258 0 004.25-4.243v1.834a4.79 4.79 0 01-1.629 3.6 4.801 4.801 0 01-3.173 8.4l-26.397-.004z"
            fill="#E16364"
          />
          <path
            d="M51.62 51.97H23.536a2.602 2.602 0 01-2.6-2.599 2.602 2.602 0 012.6-2.6H49.42a4.779 4.779 0 004.796-4.642v7.042h-.008c.007.067.007.144.007.2a2.604 2.604 0 01-2.595 2.599z"
            fill="#C64243"
          />
          <path
            d="M50.219 50.773H23.14a2.003 2.003 0 01-2.003-2 2.002 2.002 0 012.003-1.999h26.28a4.757 4.757 0 002.8-.9v2.9a2 2 0 01-2.001 1.999z"
            fill="#FFF"
          />
          <path fill="#E77071" d="M23.621 15.974h1.601v28.44h-1.601z" />
          <path
            d="M25.416 55.47a1.077 1.077 0 01-.15-.557v-8.145h8.601v8.149a1.1 1.1 0 01-1.65.952l-2.648-1.528-2.651 1.53a1.105 1.105 0 01-1.502-.4z"
            fill="#FFC411"
          />
          <path
            d="M27.07 47.571a.8.8 0 01.8.8v2.998a.8.8 0 01-.8.8.8.8 0 01-.8-.8v-2.998a.8.8 0 01.8-.8z"
            fill="#FFF"
          />
          <path
            d="M28.569 34.521l3.278-.412c.04.362.194.702.439.972.204.188.474.29.752.283a1.37 1.37 0 001.24-.8c.301-.8.465-1.646.484-2.5-.346.37-.757.672-1.214.891a3.464 3.464 0 01-1.44.283 3.6 3.6 0 01-2.69-1.132 3.965 3.965 0 01-1.098-2.866 4.25 4.25 0 01.558-2.155 3.666 3.666 0 011.543-1.471 5.412 5.412 0 012.462-.499 5.766 5.766 0 012.859.612 4.287 4.287 0 011.722 1.945 8.163 8.163 0 01.643 3.518 6.87 6.87 0 01-1.351 4.708 4.806 4.806 0 01-3.747 1.492 6.159 6.159 0 01-2.233-.33 3.461 3.461 0 01-1.361-.957 4.425 4.425 0 01-.846-1.582zm6.073-5.297a2.2 2.2 0 00-.486-1.51 1.524 1.524 0 00-1.181-.546 1.4 1.4 0 00-1.09.494 2.197 2.197 0 00-.425 1.483c-.037.542.122 1.08.448 1.514.273.339.687.533 1.122.526.443.008.867-.18 1.157-.514.331-.409.494-.929.455-1.453v.006zm4.844 1.973a7.107 7.107 0 011.218-4.736 4.757 4.757 0 013.719-1.351 5.529 5.529 0 011.967.296c.467.168.894.43 1.256.771.305.292.562.63.764 1 .2.386.351.796.45 1.22.223.905.334 1.836.331 2.769a8.092 8.092 0 01-1.094 4.727 4.362 4.362 0 01-3.763 1.498 5.271 5.271 0 01-2.422-.477 3.919 3.919 0 01-1.515-1.399 5.271 5.271 0 01-.671-1.8 12.37 12.37 0 01-.243-2.512l.003-.006zm3.27 0a8.073 8.073 0 00.402 3.098c.176.49.636.82 1.157.83.325.005.64-.122.871-.35.285-.311.473-.698.543-1.114a11.66 11.66 0 00.175-2.37 8.303 8.303 0 00-.403-3.178 1.274 1.274 0 00-1.2-.815 1.225 1.225 0 00-1.18.83 8.756 8.756 0 00-.366 3.08v-.011z"
            fill="#E06364"
          />
          <path
            d="M28.569 32.45l3.278-.413c.04.362.194.702.439.972.204.189.474.29.752.283a1.368 1.368 0 001.238-.8c.302-.8.466-1.645.485-2.5-.346.37-.758.673-1.214.892a3.462 3.462 0 01-1.44.283 3.6 3.6 0 01-2.691-1.133 3.965 3.965 0 01-1.097-2.866 4.25 4.25 0 01.558-2.154 3.666 3.666 0 011.543-1.471 5.412 5.412 0 012.462-.5 5.768 5.768 0 012.858.613 4.287 4.287 0 011.723 1.944 8.163 8.163 0 01.643 3.518 6.87 6.87 0 01-1.351 4.708 4.806 4.806 0 01-3.748 1.494 6.16 6.16 0 01-2.233-.33 3.46 3.46 0 01-1.362-.957 4.422 4.422 0 01-.843-1.583zm6.073-5.297a2.2 2.2 0 00-.486-1.51 1.524 1.524 0 00-1.181-.546 1.399 1.399 0 00-1.09.494 2.197 2.197 0 00-.425 1.482 2.27 2.27 0 00.448 1.515c.273.338.687.532 1.122.526.443.008.867-.18 1.157-.515.331-.408.494-.928.455-1.452v.006zm4.844 1.974a7.107 7.107 0 011.218-4.736 4.757 4.757 0 013.719-1.351 5.529 5.529 0 011.967.296c.467.168.894.43 1.256.771.305.292.562.63.764 1 .2.386.351.796.45 1.22.223.905.334 1.836.331 2.769a8.092 8.092 0 01-1.094 4.729 4.362 4.362 0 01-3.763 1.497 5.271 5.271 0 01-2.422-.477 3.919 3.919 0 01-1.515-1.398 5.271 5.271 0 01-.671-1.8 12.343 12.343 0 01-.243-2.511l.003-.01zm3.27 0a8.073 8.073 0 00.402 3.098c.176.49.636.82 1.157.83.325.005.64-.122.871-.35.285-.311.473-.698.543-1.114a11.66 11.66 0 00.175-2.37 8.303 8.303 0 00-.403-3.178 1.274 1.274 0 00-1.2-.815 1.225 1.225 0 00-1.18.83 8.756 8.756 0 00-.366 3.08v-.011z"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgVocabBookImage
