import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Recorder from './Recorder';

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: '100%',
  },
  recordContainer: {
    paddingLeft: theme.spacing(3),
  },
}));

const DescribeImagePractice = ({
  t,
  question,
  onAnswered,
  answer,
  mock,
  mockAnswer,
  useragent,
  isRecordingStop,
  setIsStopNextQuestion,
  isStopNextQuestion,
  onRecordStart,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} container justify="center">
        {question.question_media && (
          <img
            className={classes.image}
            src={question.question_media.image[question.question_media.image.length - 1]}
            alt=""
          />
        )}
      </Grid>
      {!mockAnswer ? (
        <Grid item xs={12} md={6} className={classes.recordContainer}>
          <Recorder
            question={question}
            isRecordingStop={isRecordingStop}
            setIsStopNextQuestion={setIsStopNextQuestion}
            isStopNextQuestion={isStopNextQuestion}
            startIn={25}
            endIn={40}
            onSaveFinish={(file) => onAnswered(file)}
            answer={answer}
            t={t}
            useragent={useragent}
            mock={mock}
            playBeepSound
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

DescribeImagePractice.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_media: PropTypes.shape().isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.string,
  mockAnswer: PropTypes.bool,
  useragent: PropTypes.object,
  mock: PropTypes.bool,
};
DescribeImagePractice.defaultProps = {
  answer: null,
  mockAnswer: false,
  useragent: {},
  mock: false,
};

export default DescribeImagePractice;
