import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

//mui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Paper, Grid, Hidden, Box, Button } from '@material-ui/core';

//components
import ProductHoc from './ProductHoc';
import WithAuthHoc from './WithAuthHoc';
import rocket from "../assets/icons/rocket.svg"
import Footer from 'components/footer/index'
import CommanMenuData from './Common/CommanMenuData';

// >>SIDEMENU 

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  sidebar: {
    borderRadius: 0,
    background: '#fff',
    padding: theme.spacing(2),
    paddingRight: 0,
    borderRight: '1px solid #eee',
    height: '100%',
  },

  contentContainer: {
    height: 'calc(100vh - 77px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 64px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 56px)',
    },
    display: 'block',
  },
  avatarText: {
    color: '#000',
  },
  sidebarLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  updatedBtnBox: {
    zIndex: 2,
    position: 'relative',
    marginBottom: '-18px',
    marginLeft: '24px',
    paddingTop: '12px'
  }
}));

const DashboardHoc = ({ children, includeTopMargin, t, showSidebar }) => {
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <WithAuthHoc>
      <ProductHoc includeTopMargin={includeTopMargin} t={t}>
        <Grid container>
          {showSidebar && (
            <Hidden mdDown>
              <Grid item md={2} xl={2}>
                <Paper elevation={0} className={classes.sidebar}>
                  <CommanMenuData t={t} />
                  <Box style={{ marginRight: theme.spacing(2) }}>
                    <Box className={classes.updatedBtnBox}  >
                      <img src={rocket} alt="installtion icon" />
                    </Box>
                    <Button onClick={() => { router.push('/subscribe'); }}
                      style={{ textTransform: "capitalize", fontWeight: "600", backgroundColor: "#0F4C8F", borderRadius: "50px", width: "100%", color: "#fff", marginRight: theme.spacing(2), padding: "12px 20px" }}>
                      {t('packageUpdate')}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Hidden>
          )}
          <Grid container item xl={10} lg={10} md={12} xs={12} className={classes.contentContainer}>
            {children}
            <Footer />
          </Grid>
        </Grid>
      </ProductHoc>
    </WithAuthHoc>
  );
};

DashboardHoc.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  t: PropTypes.func.isRequired,
  includeTopMargin: PropTypes.bool,
  showSidebar: PropTypes.bool,
};

DashboardHoc.defaultProps = {
  includeTopMargin: true,
  showSidebar: true,
};

export default DashboardHoc;
