import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  back: {
    height: '10px',
    background: '#C4C4C4',
    flexBasis: '80%',
    marginRight: '9px',
    borderRadius: '10px',
  },
  backMobile: {
    height: 5,
  },
  bar: {
    height: '10px',
    borderRadius: '10px',
    background: '#0F4C8F',
    // default width, will be overwritten
    width: 0,
  },
  barMobile: {
    height: 5,
  },
  numbers: {
    fontSize: '14px',
    color: '#808080',
    fontWeight: 600,
  },
  numbersMobile: {
    fontSize: '13px',
  },
}));

const ProgressBar = ({rate, total, ua}) => {
  const styles = useStyles();

  const calWidth = () => {
    return `${(rate / total) * 100}%`;
  };
  return (
    <div className={styles.root}>
      <div className={`${styles.back} ${ua.isMobile ? styles.backMobile : ''}`}>
        <div className={`${styles.bar} ${ua.isMobile ? styles.barMobile :''}`} style={{width: calWidth()}}></div>
      </div>
      <span className={`${styles.numbers} ${ua.isMobile ? styles.numbersMobile : ''}`}>
        {rate}/{total}
      </span>
    </div>
  );
};

ProgressBar.propTypes = {
  rate: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default ProgressBar;
