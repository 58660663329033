import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Player from '../../Player';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '30px',
    fontStyle: 'italic',
  },
  content: {
    fontSize: '18px',
    fontWeight: 400,
    fontStyle: 'italic',
    marginTop: '10px',
    lineHeight: '33.37px',
  },
}));

const MockModelAnswer = ({
  question,
  answer,
  acceptableAnswer,
  pausePlay,
  onPlayerStarted,
}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [playerState, setPlayerState] = useState(null);

  const getTranscript = () => {
    if (answer?.question_meta?.transcript) {
      return answer.question_meta.transcript;
    }
    return null;
  };

  const getAcceptableAnswer = (params) => {
    if (acceptableAnswer && acceptableAnswer.data) {
      return acceptableAnswer.data[0]?.answer;
    }
    return null;
  };

  const showAnswer = () => {
    if (
      question.category === 'Reading' ||
      question.question_type === 'HighlightCorrectSummary' ||
      question.question_type === 'HighlightIncorrectWords' ||
      question.question_type === 'ListeningFillInTheBlanks' ||
      question.question_type === 'ListeningMultipleChoiceMultipleAnswer' ||
      question.question_type === 'ListeningMultipleChoiceSingleAnswer' ||
      question.question_type === 'SelectMissingWord'
    ) {
      return null;
    }
    return true;
  };

  const getModelAnswer = (params) => {
    switch (question.question_type) {
      case 'ReadAloud':
        return {
          transcript: null,
          uri: getAcceptableAnswer(),
          userAnswerUri: true,
        };
      case 'DescribeImage':
        return {
          transcript: getTranscript(),
          uri: getAcceptableAnswer(),
          userAnswerUri: true,
        };
      case 'RepeatSentence':
        return {
          transcript: getTranscript(),
          uri: null,
          userAnswerUri: true,
        };
      case 'RetellLecture':
        return {
          transcript: getTranscript(),
          uri: getAcceptableAnswer(),
          userAnswerUri: true,
        };
      case 'AnswerShortQuestion':
        return {
          transcript: getTranscript(),
          uri: getAcceptableAnswer(),
          userAnswerUri: true,
        };
      case 'SummarizeWrittenText':
        return {
          transcript: null,
          text: getAcceptableAnswer(),
          userAnswerUri: false,
        };
      case 'WriteEssay':
        return {
          transcript: null,
          text: getAcceptableAnswer(),
          userAnswerUri: false,
        };
      case 'SummarizeSpokenText':
        return {
          transcript: null,
          text: getAcceptableAnswer(),
          userAnswerUri: false,
        };
      case 'WriteFromDictation':
        return {
          transcript: null,
          text: getAcceptableAnswer(),
          userAnswerUri: false,
        };
      default:
        return {
          text: null,
          uri: null,
          userAnswerUri: false,
        };
    }
  };

  useEffect(() => {
    onPlayerStarted();
  }, [playerState]);

  return (
    <>
      <div className={classes.root}>
        {showAnswer() ? (
          <>
            <Typography variant="h4" component="h4" className={classes.title}>
              Model Answer
            </Typography>
            {answer && getModelAnswer().transcript && (
              <Typography>{getModelAnswer().transcript}</Typography>
            )}
            {answer && getModelAnswer().text && (
              <Typography className={classes.content}>
                {getModelAnswer().text}
              </Typography>
            )}
            {answer && getModelAnswer().uri && (
              <Grid item lg={6} md={6} sm={12}>
                <Player
                  src={getModelAnswer().uri}
                  t={t}
                  isAnswer={false}
                  onPlayStarted={() => setPlayerState('model-answer')}
                  pausePlay={playerState !== 'model-answer' || pausePlay}
                />
              </Grid>
            )}
            {!getModelAnswer().uri &&
              !getModelAnswer().text &&
              !getModelAnswer().transcript && (
                <Typography>There are no model answer to show</Typography>
              )}
            <Typography variant="h4" component="h4" className={classes.title}>
              User Answer
            </Typography>
            {answer &&
              answer.answer &&
              answer.answer.answer.length > 0 &&
              !getModelAnswer().userAnswerUri && (
                <Typography className={classes.content}>
                  {answer?.answer.answer}
                </Typography>
              )}
            {answer &&
              getModelAnswer().userAnswerUri &&
              answer.answer &&
              answer.answer.answer && (
                <Grid item lg={6} md={6} sm={12}>
                  <Player
                    src={answer.answer.answer}
                    isAnswer={false}
                    t={t}
                    onPlayStarted={() => setPlayerState('user-answer')}
                    pausePlay={playerState !== 'user-answer' || pausePlay}
                  />
                </Grid>
              )}
          </>
        ) : null}
      </div>
    </>
  );
};

MockModelAnswer.propTypes = {
  acceptableAnswer: PropTypes.object,
  pausePlay: PropTypes.bool,
  onPlayerStarted: PropTypes.func,
};

MockModelAnswer.defaultProps = {
  acceptableAnswer: {},
  pausePlay: false,
  onPlayerStarted: () => {},
};

export default MockModelAnswer;
