import * as React from 'react'

function SvgFacebook(props) {
  return (
    <svg width="1em" height="1em" fill="currentColor" {...props}>
      <path
        d="M20 40A19.988 19.988 0 1120.025.024 19.988 19.988 0 0120 40zm0-38.72C9.661 1.28 1.28 9.661 1.28 20c0 10.339 8.381 18.72 18.72 18.72 10.339 0 18.72-8.381 18.72-18.72C38.72 9.661 30.339 1.28 20 1.28zm-3.036 30.248V19.989h-2.345v-3.827h2.345v-2.316c0-3.12.933-5.37 4.351-5.37h4.07v3.818h-2.867c-1.434 0-1.76.953-1.76 1.951v1.92h4.411l-.6 3.824h-3.81v11.543h-3.795v-.004z"
        fill="#A2A2A3"
      />
    </svg>
  )
}

export default SvgFacebook
