import * as React from 'react';

function resourcesDashboardM(props) {
  return (
    <svg
      width={props.width ? props.width : 82}
      height={props.height ? props.height : 59}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <rect width="132" height="94" fill="#0F4C8F" />
        <path
          d="M132 59L0 59L58.7212 23.2599C63.1922 20.5387 68.8078 20.5387 73.2788 23.2599L132 59Z"
          fill="#0F4C8F"
        />
        <g filter="url(#filter0_ddi)">
          <path
            d="M0 8.38873C0 3.75576 3.75576 0 8.38872 0H73.6113C78.2442 0 82 3.75576 82 8.38872C82 10.0171 81.2122 11.5448 79.8857 12.4891L49.119 34.3907C44.2591 37.8502 37.7409 37.8502 32.881 34.3906L2.11432 12.4891C0.787759 11.5448 0 10.0171 0 8.38873Z"
            fill="#0F4C8F"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddi"
          x="-22"
          y="-14"
          width="126"
          height="80.9853"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow" />
        </filter>
        <clipPath id="clip0">
          <rect width="82" height="59" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default resourcesDashboardM;
