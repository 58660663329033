import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  orderContainer: {
    position: 'relative',
    userSelect: 'none',
    padding: '0 16px',
    marginBottom: '10px',
    minHeight: '50px',
    color: 'white',
    border: '1px solid #ddd',
  },
  paragraph: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    color: '#303030',
  },
  order: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    color: '#fff',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const ReorderParagraphItem = ({item, optionIndex}) => {
  const classes = useStyles();
  return (
    <div className={classes.orderContainer}>
      <Typography variant="subtitle1" className={classes.paragraph}>
        {item}
        <span className={classes.order}>{optionIndex}</span>
      </Typography>
    </div>
  );
};

ReorderParagraphItem.propTypes = {
  item: PropTypes.string.isRequired,
  optionIndex: PropTypes.number.isRequired,
};

export default ReorderParagraphItem;
