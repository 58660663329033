import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Grid} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    background: '#fff',
  },
  header: {
    width: '100%',
    height: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F1F5F9',
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 0',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      padding: '0 5px',
    },
  },
  priceSection: {
    display: 'flex',
    alignItems: 'baseline',
  },
  price: {
    fontSize: 55,
    fontWeight: 600,
    '& sup': {
      fontSize: 18,
    },
  },
  buyBtn: {
    width: 151,
    height: 46,
    border: `1px solid ${theme.palette.primary.main}`,
    background: '#fff',
    cursor: 'pointer',
    borderRadius: 3,
    fontSize: 17,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginTop: 28,
    outline: 'none',
  },
  feathers: {
    listStyle: 'none',
    width: '100%',
    padding: 0,
    margin: 0,
    marginTop: 25,
    '& li': {
      padding: '18px 0',
      borderBottom: `1px solid #8080802b`,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
}));

const BasicPackageItem = ({data, bookPackage}) => {
  const classes = useStyles();

  const humanise = (diff) => {
    let str = '';
    const values = {
      ' year': 365,
      ' month': 30,
      ' day': 1,
    };
    for (const x in values) {
      const amount = Math.floor(diff / values[x]);

      if (amount >= 1) {
        str += `${amount + x + (amount > 1 ? 's' : '')} `;
        diff -= amount * values[x];
      }
    }

    return str;
  };

  const bookButtonText = (tags) => {
    if (tags.includes('course-package')) return 'Sign Up';
    if (tags.includes('mock-package')) return 'Book Now';
    return 'Upgrade';
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>{data.name}</div>
      <div className={classes.body}>
        <div className={classes.priceSection}>
          <div className={classes.price}>
            <sup>USD</sup>
            {data.price}
          </div>
          <span>/</span>
          <div className={classes.duration}>{humanise(data.duration)}</div>
        </div>
        <button
          type="button"
          className={classes.buyBtn}
          onClick={() => bookPackage(data)}>
          {bookButtonText(data.tags)}
        </button>
        <ul className={classes.feathers}>
          {data.description.map((item, index) => (
            <li key={index}>{item.message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

BasicPackageItem.propTypes = {
  data: PropTypes.object.isRequired,
  bookPackage: PropTypes.func.isRequired,
};

export default BasicPackageItem;
