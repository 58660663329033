import * as React from 'react';

function upgradeDashboardM(props) {
  return (
    <svg
      width={props.width ? props.width : 24}
      height={props.height ? props.height : 24}
      viewBox="0 0 24 24"
      fill={props.color}
      >
      <path
        fill="none"
        d="M8.80225 11V7C8.80225 5.93913 9.22367 4.92172 9.97382 4.17157C10.724 3.42143 11.7414 3 12.8022 3C13.8631 3 14.8805 3.42143 15.6307 4.17157C16.3808 4.92172 16.8022 5.93913 16.8022 7M12.8022 15V17M6.80225 21H18.8022C19.3327 21 19.8414 20.7893 20.2165 20.4142C20.5915 20.0391 20.8022 19.5304 20.8022 19V13C20.8022 12.4696 20.5915 11.9609 20.2165 11.5858C19.8414 11.2107 19.3327 11 18.8022 11H6.80225C6.27181 11 5.76311 11.2107 5.38803 11.5858C5.01296 11.9609 4.80225 12.4696 4.80225 13V19C4.80225 19.5304 5.01296 20.0391 5.38803 20.4142C5.76311 20.7893 6.27181 21 6.80225 21Z"
        stroke={props.color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default upgradeDashboardM;
