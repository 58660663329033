import React from 'react';
import PropTypes from 'prop-types';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowUp from '../../../Icons/gen/ArrowUp';
import ArrowDown from '../../../Icons/gen/ArrowDown';

import {reorderSkill} from '../../../../utils/setQuestionTypeOrder';

function createData(
  name,
  advice,
  youPracticed,
  cummulativeAccuracy,
  accuracy,
  progress,
) {
  return {name, advice, youPracticed, cummulativeAccuracy, accuracy, progress};
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Q. Type',
  },
  {
    id: 'practiced',
    numeric: true,
    disablePadding: false,
    label: 'You Practiced',
  },
  {
    id: 'accuracy',
    numeric: true,
    disablePadding: false,
    label: 'Cummulative Accuracy (%)',
  },
  // {
  //   id: 'accuracyLast10',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Accuracy (%) Last 10 Q.',
  // },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            style={{
              // width: headCell.numeric ? '25%' : '15%',
              maxWidth: 10,
              color: '#797979',
              fontWeight: 600,
              padding: 0,
            }}
            // align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tabel: {
    // maxWidth: 800,
  },
  tabelCell: {
    color: 'black',
    fontWeight: 600,
    // borderRight: '6px solid #fff',
    maxWidth: 34,
    height: '45px',
    '&:last-child': {
      border: 'none',
    },
  },
  typeBadg: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(15, 76, 143, 0.1);',
    fontWeight: 600,
    padding: '3px 8px',
    fontSize: 12,
    borderRadius: 3,
  },
}));

const EnhancedTable = ({data}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <TableContainer className={classes.tabel}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data &&
                stableSort(
                  reorderSkill(data[0]?.skill, data),
                  getComparator(order, orderBy),
                )?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      style={{
                        backgroundColor: index % 2 === 0 ? '#E5E5E5' : '#fff',
                      }}>
                      <TableCell
                        align="left"
                        id={labelId}
                        className={classes.tabelCell}
                        scope="row"
                        padding="none"
                        style={{
                          // width: '25%',
                          fontSize: 15,
                          paddingLeft: 10,
                        }}>
                        {row.title}{' '}
                      </TableCell>

                      <TableCell align="center" className={classes.tabelCell}>
                        {row.practiced}
                      </TableCell>
                      <TableCell align="center" className={classes.tabelCell}>
                        {row.accuracy.toFixed(2)}
                      </TableCell>
                      {/* <TableCell align="center" className={classes.tabelCell}>
                          {row.accuracyLast10.toFixed(2)}
                        </TableCell> */}
                    </TableRow>
                  );
                })}
              
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default EnhancedTable;
