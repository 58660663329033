import React from 'react';
import { useRouter } from 'next/router';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box } from '@material-ui/core';
import note from '../../../assets/icons/note.svg';
import reward from '../../../assets/icons/reward.svg';
import dateCal from '../../../assets/icons/dateCal.svg';
import target from '../../../assets/icons/target.svg';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: '0 -6px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 -4px',
        },
        '& .slick-slide': {
            padding: '0 6px',
        },
    },
    boxStudy: {
        backgroundColor: '#ffffff',
        borderRadius: "12px",
        padding: "15px",
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            padding: '8px',
        },
        '&:hover': {
            '& $iconHover': {
                transform: 'translateX(6px)'
            }
        },
    },
    titleText: {
        fontSize: '18px',
        fontWeight: '600',
        marginTop: '24px',
        marginBottom: '4px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    title: {
        color: "#0F4C8F",
        fontWeight: '500',
        cursor: 'pointer',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    subtitle: {
        fontWeight: "500",
        color: '#535a63',
        marginTop: "6px",
        fontSize: '15px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
        }
    },
    iconHover: {
        color: '#0F4C8F',
        transition: 'transform 0.3s ease-in-out',
        marginRight: '-6px',
        cursor: 'pointer',
        '&:hover': {
            transform: 'translateX(6px)'
        }
    }
}));

const StudyMaterials = ({ }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const router = useRouter();

    const data = [
        // { icon: note, label: 'Learning  eBooks', subtitle: 'Contains 90% Exam eBooks' },
        // { icon: reward, label: 'Shadowing', subtitle: 'Improve Oral in just 14 Days' },
        { icon: dateCal, label: 'AI Analysis', subtitle: 'Accurate score report analysis', link: '/analytics' },
        { icon: target, label: 'Mock Test', subtitle: 'test & Know your scores', link: '/mock' },
    ];
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: true,
                    centerPadding: '33.33%',
                    slidesToShow: 0.5
                },
            },
        ],
    };
    return (
        <>
            <Typography variant="h6" className={classes.titleText} >{t('studyMaterials')}</Typography>
            {/* <Grid container spacing={2} style={{ marginTop: "2px", marginBottom: "12px" }} > */}

            <Box className={classes.main}>
                <Slider {...settings}>
                    {data.map((item, index) => (
                        // <Grid item xl={3} lg={3} md={3} sm={6} xs={12} key={index}>
                        <Box className={classes.boxStudy} onClick={() => router.push(item?.link)}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography onClick={() => router.push(item?.link)} className={classes.title}>{item?.label} </Typography>
                                <Box style={{ transform: 'rotate(-45deg)' }}>
                                    <ArrowForwardIcon onClick={() => router.push(item?.link)} className={classes.iconHover} style={{}} />
                                </Box>
                            </Box>
                            <Typography variant="body2"
                                className={classes.subtitle}>{item?.subtitle} </Typography>
                        </Box>
                        // </Grid>
                    ))}
                </Slider>
            </Box>
            {/* </Grid> */}
        </>
    );
};

StudyMaterials.propTypes = {
    useragent: PropTypes.object,
    isInDashboard: PropTypes.bool,
};

StudyMaterials.defaultProps = {
    useragent: {},
    isInDashboard: false,
};

export default StudyMaterials;
