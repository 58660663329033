import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import {cancelingSessionAction} from 'core/lib/adapters/redux/actions';
import {pascalCaseConvertor} from '../../../../utils/utils';
import Watch from '../../../Icons/gen/Watch';

const useStyle = makeStyles((theme) => ({
  paginationUl: {
    '&>li>button': {
      padding: 0,
      margin: 0,
      borderRadius: 0,
    },
    '&>li:first-child>button': {
      borderRadius: `${theme.spacing(0.5)}px 0 0 ${theme.spacing(0.5)}px`,
    },
    '&>li:last-child>button': {
      borderRadius: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    },
  },
  paginationRoot: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },

  anchor: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
  },
  tagBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tagBoxText: {
    fontWeight: 700,
    fontSize: 11,
  },
  setsTasgs: {
    width: 100,
    height: 26,
    backgroundColor: 'rgba(15,76,143,.1)',
  },
  setsTasgsText: {
    color: '#0F4C8F',
  },
  freeTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(66, 255, 0, 0.1)',
  },
  freeTagText: {
    color: '#3E9D03',
  },
  upgradeTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(255, 230, 0, 0.1)',
  },
  upgradeTagText: {
    color: '#DDC80C',
    fontSize: 11,
  },
  paidTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(255, 60, 60, 0.08)',
  },
  paidTagText: {
    color: '#FF3C3C',
  },
  tableCell: {
    width: '30%',
    verticalAlign: 'baseline',
    paddingLeft: 58,
  },
  tableCellInDashboard: {
    width: '30%',
    verticalAlign: 'baseline',
  },
  watchCell: {
    position: 'relative',
    width: '20%',
  },
  watchBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#353535',
    position: 'absolute',
    top: 30,
  },
  watchBoxSVG: {
    paddingRight: theme.spacing(1),
  },
  descriptionCell: {
    verticalAlign: 'baseline',
    width: '30%',
    // paddingTop: 22,
  },

  openBtn: {
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
    // marginBottom: 10,
    marginTop: 5,
    textTransform: 'capitalize',
  },
  cancelBtn: {
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 600,
    backgroundColor: '#DADADA',
    color: '#808080',
    marginTop: 5,
    textTransform: 'capitalize',
  },
  btnCel: {
    verticalAlign: 'bottom',
  },
}));

const UserSessionsTable = ({t, sessions, changePage, inDashboard = false}) => {
  const classes = useStyle();
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const {data, meta} = sessions || {};
  const [page, setPage] = useState(1);

  const errorMessage = useSelector((state) => state.session.errorMessage);
  const error = useSelector((state) => state.session.error);
  const getDuration = (mins) => {
    const h = (mins / 60) | 0;
    const m = mins % 60 | 0;

    if (h == 0 && m != 0) return {data: m, message: 'mins', both: false};
    if (m == 0 && h != 0) return {data: h, message: 'hrs', both: false};
    return {data: h, subData: m, message: 'hrs', both: true};
  };

  const badgeBoxClass = (type) => {
    if (type === 1) return classes.freeTag;
    if (!type) return classes.upgradeTag;
  };

  const badgeTextClass = (type) => {
    if (type === 1) return classes.freeTagText;
    if (!type) return classes.upgradeTagText;
  };
  const badgeName = (type) => {
    if (type === 1) return 'Free';
    if (!type) return 'Available';
  };

  useEffect(() => {
    changePage(page);
  }, [page]);
  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {variant: 'error'});
      dispatch({type: 'RESET_SESSION_BOOKING'});
    }
  }, [errorMessage, error]);

  return (
    <>
      {!data || !meta ? (
        <Grid
          container
          className={classes.root}
          direction="column"
          alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container>
          <Table aria-label="simple table">
            <TableBody>
              {data &&
                data.map((session, index) => (
                  <>
                    {!session.canceled_at && (
                      <TableRow key={session.session.id}>
                        <TableCell
                          classes={{
                            root: inDashboard
                              ? classes.tableCellInDashboard
                              : classes.tableCell,
                          }}>
                          <div className={classes.row}>
                            <div className={classes.col}>
                              <Typography
                                variant="subtitle1"
                                classes={{subtitle1: classes.sessionTitle}}>
                                {pascalCaseConvertor(session.session.name)}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary">
                                {`Added ${moment(
                                  session.session.started_at,
                                ).format('dddd Do MMMM, YYYY, LT')}-${
                                  session.timezone
                                }`}
                              </Typography>
                              <div className={classes.row}>
                                <div
                                  className={`${classes.tagBox} ${classes.setsTasgs}`}>
                                  <span
                                    className={`${classes.tagBoxText} ${classes.setsTasgsText}`}>
                                    {`${
                                      parseInt(session.session.capacity, 10) -
                                      (parseInt(session.session.submits, 10) ||
                                        0)
                                    } Seats Left`}
                                  </span>
                                </div>
                                <div
                                  className={`${classes.tagBox} ${badgeBoxClass(
                                    session.session.is_free,
                                    session.session.paid_status,
                                  )}`}>
                                  <span
                                    className={`${
                                      classes.tagBoxText
                                    } ${badgeTextClass(
                                      session.session.is_free,
                                      session.session.paid_status,
                                    )}`}>
                                    {badgeName(
                                      session.session.is_free,
                                      session.session.paid_status,
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell classes={{root: classes.watchCell}}>
                          <div className={classes.watchBox}>
                            <Watch width="20" height="20" />{' '}
                            <span
                              style={{
                                paddingLeft: 8,
                                fontSize: 13,
                              }}>
                              {`${
                                getDuration(session.session.duration).both
                                  ? `${
                                      getDuration(session.session.duration)
                                        ?.data
                                    }/${
                                      getDuration(session.session.duration)
                                        .subData
                                    } ${
                                      getDuration(session.session.duration)
                                        .message
                                    }`
                                  : `${
                                      getDuration(session.session.duration)
                                        ?.data
                                    } ${
                                      getDuration(session.session.duration)
                                        .message
                                    }`
                              }`}{' '}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell className={classes.descriptionCell}>
                          <SmartText
                            text={session.session.description}
                            length={100}
                          />
                        </TableCell>
                        <TableCell className={classes.btnCel}>
                          {session.join_url && (
                            <Button
                              className={classes.openBtn}
                              variant="contained"
                              disableElevation
                              color="primary"
                              onClick={() => {
                                window.open(
                                  `https://zoom.us/wc/join/${
                                    session.join_url.split('/').reverse()[0]
                                  }`,
                                  '_blank',
                                );
                              }}>
                              Open
                            </Button>
                          )}
                          {!session.session.canceled_at ? (
                            <Button
                              className={classes.cancelBtn}
                              variant="contained"
                              disableElevation
                              // color="primary"
                              onClick={() => {
                                dispatch(cancelingSessionAction(session.id));
                              }}>
                              Cancel
                            </Button>
                          ) : (
                            <Typography
                              variant="caption"
                              color="red"
                              style={{color: 'red', fontWeight: 600}}>
                              Canceled by teacher
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
            </TableBody>
          </Table>
          <Grid container justify="center" alignItems="center">
            <Pagination
              count={meta && meta.pagination && meta.pagination.total_pages}
              variant="outlined"
              shape="rounded"
              color="primary"
              boundaryCount={3}
              page={meta && meta.pagination && meta.pagination.current_page}
              onChange={(e, page) => setPage(page)}
              size="large"
              classes={{
                root: classes.paginationRoot,
                ul: classes.paginationUl,
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

UserSessionsTable.propTypes = {
  t: PropTypes.func,
  sessions: PropTypes.object,
  changePage: PropTypes.func,
  questionType: PropTypes.string,
  onQuestionChange: PropTypes.func,
};

UserSessionsTable.defaultProps = {
  t: () => null,
  sessions: [],
  changePage: () => null,
  questionType: '',
  onQuestionChange: () => {},
};

export default UserSessionsTable;

const SmartText = ({text, length = 220}) => {
  const [showLess, setShowLess] = useState(true);

  if (text.length < length) {
    return <div dangerouslySetInnerHTML={{__html: text}} />;
  }

  return (
    <div>
      <div>
        {showLess ? (
          <div
            dangerouslySetInnerHTML={{__html: `${text.slice(0, length)}...`}}
          />
        ) : (
          <div dangerouslySetInnerHTML={{__html: text}} />
        )}
      </div>
      <a
        style={{color: '#0F4C8F', cursor: 'pointer', fontWeight: 600}}
        onClick={() => setShowLess(!showLess)}>
        See {showLess ? 'More' : 'Less'}
      </a>
    </div>
  );
};
