import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {useRouter} from 'next/router';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SessionList from 'components/Session/SessionList';
import {fetchAllSessionsAction} from 'core/lib/adapters/redux/actions';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#0F4C8F',
  },
  headerTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: '#fff',
  },

  Container: {
    maxHeight: 600,
    height: 600,
    overflowY: 'auto',
    marginBottom: theme.spacing(1),
  },

  VideosGuidesContainer: {
    backgroundColor: '#fff',
    padding: '35px 20px',
  },

  gotoOtherPage: {
    color: '#0F4C8F',
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 600,
  },
  gotoOtherPageSession: {
    color: '#0F4C8F',
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 600,
  },

  mainBoxTitle: {
    fontWeight: 800,
    fontSize: 17,
    color: '#303030',
  },

  /// ////////////////////////session design

  root: {
    width: '100%',
  },
  sessionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30,
    // padding: '0px 58px',
  },
  sessionHeaderMobile: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  sessionHeaderTitle: {
    paddingTop: 30,
  },
  sessionHeaderTitleMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 30,
    paddingLeft: '6%',
  },
  liveZoomTitle: {
    fontWeight: 600,
  },
  mockIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: '#EDF0F2',
    padding: theme.spacing(0.7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  },
  filterBtn: {
    width: '100%',
    height: 50,
    backgroundColor: '#F1F2F6',
    borderRadius: 10,
    color: '#7C7C7C',
    fontSize: 16,
    fontWeight: 500,
  },
  filterBtnMobile: {
    height: 'auto',
    // marginTop: theme.spacing(2),
  },

  listBox: {
    position: 'relative',
  },
  listBoxUl: {
    position: 'absolute',
    top: 63,
    width: '93%',
    backgroundColor: '#F1F2F6',
    zIndex: 1,
    borderRadius: 3,
    maxHeight: 300,
    height: 300,
    overflow: 'auto',
  },
  listBoxLi: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));

const months = [
  {order: 0, name: 'January'},
  {order: 1, name: 'February'},
  {order: 2, name: 'March'},
  {order: 3, name: 'April'},
  {order: 4, name: 'May'},
  {order: 5, name: 'June'},
  {order: 6, name: 'July'},
  {order: 7, name: 'August'},
  {order: 8, name: 'September'},
  {order: 9, name: 'October'},
  {order: 10, name: 'November'},
  {order: 11, name: 'December'},
];

const Session = ({useragent}) => {
  const {t} = useTranslation();
  const router = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [month, setMonth] = useState({
    order: moment().get('month'),
    name: months.find((i) => i.order == moment().get('month')).name,
  });
  const [year, setYear] = useState(moment().year());
  const [years, setYears] = useState([]);
  const [monthListShow, setMonthListShow] = useState(false);
  const [yearListShow, setYearListShow] = useState(false);
  const [filterShow, setFilterShow] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const redirectToNewPage = (e, href) => {
    e.preventDefault();
    router.push(href);
  };

  const renderYears = () => {
    const years = [];
    for (let i = moment().year(); i >= 2015; i--) {
      years.push(i);
    }
    setYears(years);
  };

  useEffect(() => {
    dispatch(
      fetchAllSessionsAction({
        page: 1,
        per_page: 15,
        search: {year, month: month.order + 1, day: moment().day()},
      }),
    );
  }, [year, month]);

  useEffect(() => {
    renderYears();
  }, []);
  return (
    <Grid item xs={12} className={`${classes.VideosGuidesContainer}`}>
      <Grid item xs={12} container>
        <Paper className={classes.root} elevation={0}>
          <Grid
            item
            xs={12}
            container
            alignItems={isMobile ? 'flex-end' : 'center'}
            className={
              isMobile ? classes.sessionHeaderMobile : classes.sessionHeader
            }>
            {/* && isMobile == false */}
            {filterShow && (
              <Grid
                item
                xs={12}
                container
                spacing={1}
                alignItems="center"
                justify="flex-end">
                <Grid
                  item
                  xs={6}
                  lg={2}
                  container
                  alignItems="flex-end"
                  justify="flex-end"
                  className={classes.listBox}>
                  <Button
                    className={`${classes.filterBtn} ${
                      isMobile ? classes.filterBtnMobile : ''
                    }`}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      setMonthListShow(!monthListShow);
                    }}>
                    {month.name} <ExpandMoreIcon />
                  </Button>
                  {monthListShow && (
                    <List dense={false} className={classes.listBoxUl}>
                      {months.map((i) => (
                        <ListItem key={i.order}>
                          <ListItemText
                            onClick={() => {
                              setMonth(i);
                              setMonthListShow(false);
                            }}
                            className={classes.listBoxLi}
                            primary={`${i.name}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  lg={2}
                  container
                  alignItems="flex-end"
                  justify="flex-end"
                  className={classes.listBox}>
                  <Button
                    className={`${classes.filterBtn} ${
                      isMobile ? classes.filterBtnMobile : ''
                    }`}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      setYearListShow(!yearListShow);
                    }}>
                    {year} <ExpandMoreIcon />
                  </Button>
                  {yearListShow && (
                    <List dense={false} className={classes.listBoxUl}>
                      {years.map((i) => (
                        <ListItem key={i}>
                          <ListItemText
                            onClick={() => {
                              setYear(i);
                              setYearListShow(false);
                            }}
                            className={classes.listBoxLi}
                            primary={`${i}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <SessionList
            t={t}
            type="ss"
            Icon="Speaking"
            typeId="eee"
            useragent={useragent}
            hideFilterBtns={(value) => setFilterShow(value)}
            hideFilterBtnsInMobile={(value) => setIsMobile(value)}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default Session;
