import * as React from 'react';

function InfoDashboardM(props) {
  return (
    <svg
      width={props.width ? props.width : 24}
      height={props.height ? props.height : 24}
    //   viewBox="0 0 24 24"
      fill={props.color}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0002 2.49998C5.85803 2.49998 2.50016 5.85784 2.50016 9.99998C2.50016 14.1421 5.85803 17.5 10.0002 17.5C14.1423 17.5 17.5002 14.1421 17.5002 9.99998C17.5002 5.85784 14.1423 2.49998 10.0002 2.49998ZM0.833496 9.99998C0.833496 4.93737 4.93755 0.833313 10.0002 0.833313C15.0628 0.833313 19.1668 4.93737 19.1668 9.99998C19.1668 15.0626 15.0628 19.1666 10.0002 19.1666C4.93755 19.1666 0.833496 15.0626 0.833496 9.99998Z"
        // fill="#C0C0C0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0002 9.16665C10.4604 9.16665 10.8335 9.53974 10.8335 9.99998V13.3333C10.8335 13.7935 10.4604 14.1666 10.0002 14.1666C9.53992 14.1666 9.16683 13.7935 9.16683 13.3333V9.99998C9.16683 9.53974 9.53992 9.16665 10.0002 9.16665Z"
        // fill="#C0C0C0"
      />
      <path
        d="M10.8335 6.66665C10.8335 7.12688 10.4604 7.49998 10.0002 7.49998C9.53992 7.49998 9.16683 7.12688 9.16683 6.66665C9.16683 6.20641 9.53992 5.83331 10.0002 5.83331C10.4604 5.83331 10.8335 6.20641 10.8335 6.66665Z"
        // fill="#C0C0C0"
      />
    </svg>
  );
}

export default InfoDashboardM;
