import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import PropTypes from 'prop-types';

import dynamic from 'next/dynamic';

const PdfViewer = dynamic(() => import('./PDFViewer'), {ssr: false});

const Loading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <CircularProgress />
  </div>
);

const DocViewer = ({source, useragent}) => {
  if (!source) {
    return <Loading />;
  }

  const src = source;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}>
      <PdfViewer file={src} loading={<Loading />} useragent={useragent} />
    </div>
  );
};

DocViewer.propTypes = {
  source: PropTypes.string,
  useragent: PropTypes.object,
};

DocViewer.defaultProps = {
  source: '',
  useragent: {},
};

export default DocViewer;
