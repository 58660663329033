import * as React from 'react';

function SvgBookmark(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 15 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.22266 0C2.46123 0 0.222656 2.23857 0.222656 5V22.1667L7.61155 15.8334L15.0004 22.1667V5C15.0004 2.23858 12.7619 0 10.0004 0H5.22266Z"
        fill="#0F4C8F"
      />
    </svg>
  );
}

SvgBookmark.defaultProps = {
  width: 24,
  height: 24,
};

export default SvgBookmark;
