import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {useRouter} from 'next/router';
import InfoDashboardM from 'components/Icons/gen/InfoDashboardM';
import {fetchStudyPlanAction} from 'core/lib/adapters/redux/actions';
import PropTypes from 'prop-types';
import AppTab from './Tabs/AppTab';
import AppTabs from './Tabs/AppTabs';
import Session from './session';
import StudyPlan from './studyPlan';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  writeEnglishContainer: {
    display: 'flex',
    backgroundColor: '#0F4C8F',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    fontWeight: 500,
    fontSize: '0.9em',
    color: '#fff',
    marginLeft: 3,
    textAlign: 'left',
  },
  headerImageContainer: {
    marginBottom: theme.spacing(2),
  },
  tabsBox: {
    backgroundColor: '#fff',
    borderTop: '2px solid #0F4C8F',
    marginTop: 15,
    paddingTop: 15,
    paddingLeft: '5%',
  },
  imageText: {
    fontSize: 18,
    textAlign: 'right',
    color: '#fff',
    fontWeight: 500,
  },
  imageTextMobile: {
    fontSize: '0.5em',
    textAlign: 'right',
    color: '#fff',
    fontWeight: 600,
  },
  joinBtn: {
    textTransform: 'capitalize',
    border: '2px solid #fff',
    borderRadius: 3,
    color: '#fff',
    backgroundColor: 'transparent',
    padding: '4px 30px',
    marginTop: 20,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$selected': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  joinBtnMobile: {
    textTransform: 'capitalize',
    border: '1px solid #fff',
    borderRadius: 3,
    color: '#fff',
    fontSize: '0.6em',
    backgroundColor: 'transparent',
    padding: '0px 0px',
    marginTop: '2%',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$selected': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

const HomeTab = ({useragent}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const router = useRouter();

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState('Study Plan');

  const [bannerImg, setBannerImg] = useState('');
  const [bannerUrl, setBannerUrl] = useState('');

  const {notificationPost, bannerPost} = useSelector((state) => ({
    notificationPost: state.post.notificationPost,
    bannerPost: state.post.bannerPost,
  }));

  const changeActiveTabHandler = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      setActiveTabName('Study Plan');
    }
    if (newValue === 1) {
      setActiveTabName('Online Classes');
      dispatch(fetchStudyPlanAction());
    }
  };

  const redirectToNewPage = (e, href) => {
    e.preventDefault();
    if (href) {
      router.push(href);
    }
  };

  useEffect(() => {
    if (bannerPost && bannerPost.data && bannerPost.data[0]) {
      const obj =
        bannerPost.data[0].text && JSON.parse(bannerPost.data[0].text);
      setBannerUrl(obj.url);
      setBannerImg(obj.img);
    }
  }, [bannerPost]);

  useEffect(() => {
    changeActiveTabHandler('e', 0, useragent.isMobile || useragent.isTablet);
  }, []);

  return (
    <Grid className={classes.root}>
      {notificationPost &&
        notificationPost.data &&
        notificationPost.data[0] &&
        notificationPost.data[0].title !== 'off' && (
          <Box className={classes.writeEnglishContainer}>
            <Box style={{width: 20, marginRight: '0.5rem'}}>
              <InfoDashboardM width={20} height={20} color="#B4C2D0" />
            </Box>
            <Typography
              variant="h6"
              align="center"
              className={classes.headerTitle}>
              {notificationPost &&
                notificationPost.data &&
                notificationPost.data[0]?.text}
            </Typography>
          </Box>
        )}

      {bannerImg && (
        <Grid container onClick={(e) => redirectToNewPage(e, bannerUrl)}>
          <Grid
            container
            className={classes.headerImageContainer}
            alignItems="center">
            <Grid
              item
              xs={12}
              container
              justify="center"
              style={{position: 'relative'}}
              alignItems="center">
              <img src={bannerImg} alt="Banner" style={{width: '100%'}} />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.tabsBox}>
        <AppTabs
          value={activeTab}
          onChange={(e, newValue) =>
            changeActiveTabHandler(
              e,
              newValue,
              useragent.isMobile || useragent.isTablet,
            )
          }
          indicatorColor="primary"
          textColor="primary"
          // centered
        >
          <AppTab label="Study Plan" />
          <AppTab label="Online Classes" />
        </AppTabs>
      </Grid>
      {activeTabName === 'Study Plan' ? (
        <>
          <StudyPlan useragent={useragent} />
        </>
      ) : (
        <>
          <Session useragent={useragent} />
        </>
      )}
    </Grid>
  );
};

HomeTab.propTypes = {
  useragent: PropTypes.object,
};

HomeTab.defaultProps = {
  useragent: {},
};

export default HomeTab;
