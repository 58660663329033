import * as React from 'react'

function SvgVolume(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.433 4.099A1 1 0 0112 5v14a1 1 0 01-1.625.78L5.65 16H2a1 1 0 01-1-1V9a1 1 0 011-1h3.65l4.725-3.78a1 1 0 011.058-.121zM10 7.08l-3.375 2.7A1 1 0 016 10H3v4h3a1 1 0 01.625.22L10 16.92V7.08zM18.363 4.223a1 1 0 011.414 0 11 11 0 010 15.554 1 1 0 01-1.414-1.414 9 9 0 000-12.726 1 1 0 010-1.414zm-3.53 3.53a1 1 0 011.414 0 6 6 0 010 8.484 1 1 0 01-1.414-1.414 4 4 0 000-5.656 1 1 0 010-1.414z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgVolume
