import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {getPracticeBulkScore} from 'core/lib/adapters/redux/actions';
import CourseHoc from '../ModalComponents/CourseHoc';
import ChooseQuestionTypeHoc from '../ModalComponents/ChooseQuestionTypeHoc';
import MultipleQuestionsResultModal from './MultipleQuestionsResultModal';

function MultipleQuestionsModal(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [isInResultPage, setIsInResultPage] = useState(false);
  const [questionsResult, setQuestionsResult] = useState();
  const [totalScore, setTotalSore] = useState(0);
  const [score, setScore] = useState(0);
  const [requestBuklkScore, setRequestBulkScore] = useState(false);
  const [lastPageCurrentQuestion, setLastPageCurrentQuestion] = useState(1);
  const [isInResultPageNextRender, setIsInResultPageNextRender] = useState(
    false,
  );
  const bulkScore = useSelector((state) => state.questions.bulkScore) || {};

  const setNextQuestion = () => {
    // if(currentQuestion + 1 > 1) return;
    // TODO why 2 works??!
    if (currentQuestion + 2 === props.questions?.length)
      setIsLastQuestion(true);
    if (currentQuestion + 1 >= props.questions?.length) setIsInResultPage(true);
    if (currentQuestion === props.questions?.length)
      setIsInResultPageNextRender(true);
    if (currentQuestion + 1 >= props.questions?.length) {
      setIsInResultPageNextRender(true);
      return;
    }
    setCurrentQuestion((s) => s + 1);
  };
  const setPrevQuestion = () => {
    if (currentQuestion + 1 !== props.questions?.length - 1)
      setIsLastQuestion(false);
    if (currentQuestion - 2 >= props.questions?.length)
      setIsInResultPageNextRender(false);
    if (currentQuestion - 1 < 0) {
      setIsInResultPage(false);
      return;
    }
    setCurrentQuestion((s) => s - 1);
  };

  useEffect(() => {
    if (isInResultPage) {
      const array = [];
      questionsResult.forEach((item) => {
        array.push(item.answer.answer.id);
      });
      dispatch(
        getPracticeBulkScore({
          ids: array.toString(),
        }),
      );
      setRequestBulkScore(true);
    }
  }, [isInResultPage]);

  useEffect(() => {
    if (bulkScore && !bulkScore.loading && requestBuklkScore) {
      Object.keys(bulkScore).map((key) => {
        setScore((prev) => prev + bulkScore[key]);
        setTotalSore((prev) => prev + 1);
      });
      setRequestBulkScore(false);
    }
  }, [bulkScore]);

  return !isInResultPage ? (
    <CourseHoc
      t={t}
      title={props.title}
      rightTitle={() =>
        props.ua.isMobile || props.ua.isTablet ? (
          <>
            <span style={{fontSize: '14px', fontWeight: 600}}>
              {currentQuestion + 1}
            </span>
            <span style={{fontSize: '12px'}}>
              {` / ${props.questions?.length}`}
            </span>
          </>
        ) : (
          <>
            <span style={{fontSize: '36px'}}>{currentQuestion + 1}</span>
            <span style={{fontSize: '24px'}}>
              {` of ${props.questions?.length}`}
            </span>
          </>
        )
      }
      type={props.subCategory}
      useragent={props.ua}
      showHint={false}>
      <ChooseQuestionTypeHoc
        navigatorType={
          isLastQuestion ? 'multipleQuestionLastPage' : 'multipleQuestion'
        }
        question={props.questions[currentQuestion]}
        id={props?.questions[currentQuestion]?.id}
        onPrevNav={setPrevQuestion}
        useragent={props.ua}
        onNextNav={setNextQuestion}
        onExitNav={props.onExit}
        multipleQuestionsFinalData={(data) => setQuestionsResult(data)}
        isInResultPage={isInResultPageNextRender}
      />
    </CourseHoc>
  ) : (
    <CourseHoc
      t={t}
      title={props.title}
      rightTitle={`${score}/${totalScore}`}
      useragent={props.ua}
      type={props.subCategory}
      showHint={false}>
      <MultipleQuestionsResultModal
        propCurrentQuestion={(data) => setLastPageCurrentQuestion(data)}
        lastPageData={questionsResult}
        onExit={props.onExit}
      />
    </CourseHoc>
  );
}

MultipleQuestionsModal.propTypes = {
  questions: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired,
  ua: PropTypes.object.isRequired,
};

export default MultipleQuestionsModal;
