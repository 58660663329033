import * as React from 'react';

function SvgCircleCross(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.596436 11C0.596436 17.0751 5.4781 22 11.4999 22C14.3917 22 17.1651 20.8411 19.2099 18.7782C21.2547 16.7153 22.4035 13.9174 22.4035 11C22.4035 4.92487 17.5218 0 11.4999 0C5.4781 0 0.596436 4.92487 0.596436 11ZM2.57889 11C2.57889 6.02944 6.57298 2 11.4999 2C16.4269 2 20.421 6.02944 20.421 11C20.421 15.9706 16.4269 20 11.4999 20C6.57298 20 2.57889 15.9706 2.57889 11Z"
        fill={props.color}
      />
      <path
        d="M15.4648 16C15.2013 16.0015 14.9481 15.8972 14.761 15.71L6.83121 7.70999C6.57977 7.45634 6.48158 7.08662 6.57361 6.74012C6.66564 6.39361 6.93392 6.12296 7.27738 6.03012C7.62085 5.93727 7.98732 6.03634 8.23875 6.28999L16.1686 14.29C16.3562 14.4778 16.4618 14.7334 16.4618 15C16.4618 15.2666 16.3562 15.5222 16.1686 15.71C15.9815 15.8972 15.7283 16.0015 15.4648 16Z"
        fill={props.color}
      />
      <path
        d="M7.53506 16C7.27159 16.0015 7.01835 15.8972 6.83129 15.71C6.64364 15.5222 6.53809 15.2666 6.53809 15C6.53809 14.7334 6.64364 14.4778 6.83129 14.29L14.7611 6.29C15.1498 5.89788 15.78 5.89788 16.1687 6.29C16.5573 6.68212 16.5573 7.31788 16.1687 7.71L8.23883 15.71C8.05177 15.8972 7.79853 16.0015 7.53506 16Z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgCircleCross;
