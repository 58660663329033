import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'components/Icons/gen/Cross';
import ReorderParagraphDesktop from './Practice/ReorderParagraphDesktop';
import ReorderParagraphMobile from './Practice/ReorderParagraphMobile';
import ParagraphItem from './Practice/ParagraphItem';

const useStyles = makeStyles((theme) => ({
  rightNav: {
    background: '#FF3C3C',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  leftNav: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    padding: theme.spacing(0.5),
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    textAlign: 'center',
    color: 'white',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1.5),
    background: 'white',
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    padding: 0,
    '&:hover': {
      background: 'white',
    },
  },
  listItem: {
    border: `1px solid #ddd`,
    borderRadius: 3,
    marginBottom: theme.spacing(3),
    position: 'relative',
  },
  list: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  orderCount: {
    position: 'absolute',
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    right: 0,
    bottom: 0,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const ReorderParagraphPractice = ({
  t,
  question,
  onAnswered,
  answer,
  questionAnswer,
  submittedQuestion,
}) => {
  const classes = useStyles();
  const [answerColumns, setAnswerColumns] = useState();

  const [showAnswerDialog, setShowAnswerDialog] = useState(false);

  useEffect(() => {
    const filledArray = [];
    if (answerColumns && answerColumns.target) {
      answerColumns.target.items.forEach((item) => {
        filledArray.push(`${question.question_data.options.indexOf(item) + 1}`);
      });
    }
    onAnswered(filledArray);
  }, [answerColumns]);

  useEffect(() => {
    setShowAnswerDialog(true);
  }, [submittedQuestion]);

  return (
    <Grid container>
      {submittedQuestion && questionAnswer && (
        <Dialog
          onClose={() => setShowAnswerDialog(false)}
          aria-labelledby="correctDialog"
          open={showAnswerDialog}
          maxWidth="lg"
          fullWidth>
          <DialogTitle id="correctDialogTitle" className={classes.dialogTitle}>
            <Typography variant="body1"> {t('correctOrder')}</Typography>
            <IconButton
              onClick={() => setShowAnswerDialog(false)}
              className={classes.closeIcon}>
              <CloseIcon color="#f00" width={35} height={35} />
            </IconButton>
          </DialogTitle>
          <List className={classes.list}>
            {questionAnswer &&
              questionAnswer['acceptable-answers'].data[0].answer.map((a) => (
                <Grid item key={a} style={{padding: '0 8px'}}>
                  <ParagraphItem
                    item={question.question_data.options[a - 1]}
                    optionIndex={a}
                  />
                </Grid>
              ))}
          </List>
        </Dialog>
      )}
      <ReorderParagraphDesktop
        t={t}
        onAnswer={(data) => setAnswerColumns(data)}
        options={question.question_data.options}
        showCorrectOrder={submittedQuestion}
        correctOrder={
          questionAnswer &&
          questionAnswer['acceptable-answers']?.data[0]?.answer
        }
        showCorrectAnswer={() => setShowAnswerDialog(true)}
      />
      <ReorderParagraphMobile
        t={t}
        onAnswer={(data) => setAnswerColumns(data)}
        options={question.question_data.options}
      />
    </Grid>
  );
};

ReorderParagraphPractice.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_media: PropTypes.array.isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  submittedQuestion: PropTypes.bool.isRequired,
  questionAnswer: PropTypes.shape(),
};
ReorderParagraphPractice.defaultProps = {
  answer: null,
  questionAnswer: null,
};

export default ReorderParagraphPractice;
