import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Button, Divider, Hidden } from '@material-ui/core';
import note from '../../../assets/icons/note.svg';
import reward from '../../../assets/icons/reward.svg';
import dateCal from '../../../assets/icons/dateCal.svg';
import target from '../../../assets/icons/target.svg';
import mockTestBannerBoy from "../../../assets/icons/mockTestBannerBoy.svg";
import freeTag from "../../../assets/icons/freeTag.svg";
const useStyles = makeStyles((theme) => ({
    bannerText: {
        padding: "20px 30px",
        marginBottom: "65px",
        maxWidth: '50%',
        [theme.breakpoints.down('sm')]: {
            padding: "15px 18px",
        },
        // [theme.breakpoints.up('md')]: {
        //     maxWidth: '50%',
        // },
    },
    mockBannerText: {
        fontSize: "65px",
        lineHeight: '65px',
        fontWeight: "600",
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontFamily: 'Bebas Neue',
        '@media (max-width: 1450px)': {
            fontSize: "50px",
            lineHeight: '50px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "50px",
            lineHeight: '50px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "35px",
            lineHeight: '35px',
        },
    },
    bannerMainSubTitle: {
        fontSize: "18px",
        fontWeight: "600",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            lineHeight: 'normal',
            marginTop: '5px'
        }
    },
    mockTestFree: {
        position: 'relative',
        backgroundColor: "#1C61AC",
        padding: '24px',
        borderRadius: '0 0 12px 12px'
    },
    freeMockText: {
        fontSize: "30px",
        color: "#ffffff",
        textTransform: "uppercase",
        [theme.breakpoints.down('lg')]: {
            fontSize: "30px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "20px",
        },
    },
    freeTagImg: {
        '@media (max-width: 425px)': {
            width: '50px'
        },
    },
    getFreeTestBtn: {
        backgroundColor: "#ffffff",
        borderRadius: "50px",
        color: theme.palette.primary.main,
        textTransform: "capitalize",
        padding: '6px 3vh',
        height: "45px",
        animation: '$colorChange 2s infinite',
        '&:hover': {
            backgroundColor: '#fff'
        },
        [theme.breakpoints.down('sm')]: {
            height: "40px",
        }
    },
    mockTestBoy: {
        position: 'absolute',
        bottom: '12px',
        right: 0,
        [theme.breakpoints.down('lg')]: {
            maxWidth: '35%',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '26%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '34%',
            right: '-102px'
        },
        '@media (max-width: 653px)': {
            right: '-68px',
            bottom: '24px',
            maxWidth: '50%',
        },
        '@media (max-width: 599px)': {
            right: '-68px',
            bottom: '84px',
            maxWidth: '50%',
        },
        '@media (max-width: 476px)': {
            right: '-68px',
            bottom: '177px',
            maxWidth: '60%',
        },
        '@media (max-width: 399px)': {
            right: '-68px',
            bottom: '177px',
            maxWidth: '70%',
        },
    },
    '@keyframes colorChange': {
        '0%': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        },
        '32%': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        },
        '33%': {
            backgroundColor: '#ff0000',
            color: '#ffffff',
        },
        '65%': {
            backgroundColor: '#ff0000',
            color: '#ffffff'
        },
        '66%': {
            backgroundColor: '#8cb227',
            color: '#ffffff'
        },
        '99%': {
            backgroundColor: '#8cb227',
            color: '#ffffff'
        },
        '100%': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        },
        // '33%': {
        //     backgroundColor: '#fff',
        //     color: theme.palette.primary.main
        // },
        // '66%': {
        //     backgroundColor: '#ff0000',
        //     color: '#ffffff'
        // },
        // '100%': {
        //     backgroundColor: '#8cb227',
        //     color: '#ffffff'
        // },
    },
}));

const DashMockTest = ({ useragent, isInDashboard = false }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const router = useRouter();


    return (
        <>
            <Box className={classes.peparBox} style={{ backgroundColor: "#fff", borderRadius: "12px" }} >
                <Typography style={{ fontSize: "18px", fontWeight: "600", padding: "15px 20px" }}> Mock Test</Typography>
                <Divider />
                <Box className={classes.bannerText}>
                    <Typography className={classes.mockBannerText}> Mastering PTE online mock TESTS</Typography>
                    <Typography className={classes.bannerMainSubTitle}> Your Pathway to Success</Typography>
                </Box>
                <Box className={classes.mockTestFree}>
                    <Grid container spacing={3}>
                        <Grid item lg={8} sm={10} xs={12}>
                            <Box style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', gap: '12px' }}>
                                <img src={freeTag} alt='free' className={classes.freeTagImg} />
                                <Typography className={classes.freeMockText}> {t('freePteMockTest')} </Typography>
                                <Button onClick={() => router.push('/mock')} className={classes.getFreeTestBtn} >{t('getYourFreeBtn')}</Button>
                            </Box>
                        </Grid>
                        {/* <Hidden smDown> */}
                        <Grid item xs={4} style={{ padding: '0px' }}>
                            <Box className={classes.mockTestBoy}>
                                <img src={mockTestBannerBoy} alt='mockTestBannerBoy' width={'100%'} height={'auto'} />
                            </Box>
                        </Grid>
                        {/* </Hidden> */}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

DashMockTest.propTypes = {
    useragent: PropTypes.object,
    isInDashboard: PropTypes.bool,
};

DashMockTest.defaultProps = {
    useragent: {},
    isInDashboard: false,
};

export default DashMockTest;
