import {useState, useEffect} from 'react';
import {makeStyles, styled} from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStepFourStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 'normal',
  },
  header: {
    fontWeight: 'bold',
  },
  date: {
    width: '100%',
  },
}));
function StepFour({studyPlanData = {}, sendData}) {
  const classes = useStepFourStyles();
  const [studyPlanInfo, setStudyPlanInfo] = useState(studyPlanData);

  const [selectedDate, setSelectedDate] = useState(
    moment(new Date(), 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
  );

  useEffect(() => {
    const today = moment(new Date());
    const days = moment(selectedDate).diff(today, 'days');
    setStudyPlanInfo((prev) => {
      if (prev)
        return {
          ...prev,
          date_of_exam: selectedDate,
          week: Math.ceil(days / 7) == 0 ? 1 : Math.ceil(days / 7),
        };
      else
        return {
          date_of_exam: selectedDate,
          week: Math.ceil(days / 7) == 0 ? 1 : Math.ceil(days / 7),
        };
    });
  }, [selectedDate]);

  useEffect(() => {
    sendData(studyPlanInfo);
  }, [studyPlanInfo]);

  return (
    <>
      <h4 className={classes.title}>When is your next test?</h4>
      <h4 className={classes.header}>If you don't know yet, just estimate</h4>
      <form noValidate>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            minDate={moment(new Date(), 'YYYY-MM-DD')
              .add(1, 'days')
              .format('YYYY-MM-DD')}
            variant="inline"
            onChange={(value) => {
              setSelectedDate(value);
            }}
            format="dd/MM/yy"
            margin="normal"
            value={studyPlanInfo?.date_of_exam}
            id="date-picker-inline"
            className={classes.date}
            // label="Date picker inline"
            //   value={selectedDate}
            //   onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </form>
    </>
  );
}

export default StepFour;
