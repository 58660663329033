import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Grid} from '@material-ui/core';
import DatabaseIcon from '../../../Icons/gen/Database';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    background: '#fff',
  },
  header: {
    width: '100%',
    height: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F1F5F9',
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 0',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      padding: '0 5px',
    },
  },
  price: {
    fontSize: 13,
    fontWeight: 500,
    marginRight: 20,
    color: theme.palette.primary.main,
  },
  buyBtn: {
    width: '100%',
    height: 38,
    border: `1px solid ${theme.palette.primary.main}`,
    background: '#fff',
    cursor: 'pointer',
    borderRadius: 3,
    // fontSize: 17,
    fontWeight: 700,
    color: theme.palette.primary.main,
    outline: 'none',
  },
  feathers: {
    listStyle: 'none',
    width: '100%',
    padding: 0,
    margin: 0,
    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '18px 14px',
      borderBottom: `1px solid #8080802b`,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 500,
    },
  },
  icon: {
    width: 47,
    height: 47,
    background: '#6aa7eb21',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: 11,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  aiCount: {
    fontSize: 16,
    fontWeight: 500,
    '& span': {
      fontWeight: 700,
      padding: 0,
    },
  },
}));

const BasicPackageItem = ({data, bookPackage}) => {
  const classes = useStyles();
  const humanise = (diff) => {
    let str = '';
    const values = {
      ' year': 365,
      ' month': 30,
      ' day': 1,
    };
    for (const x in values) {
      const amount = Math.floor(diff / values[x]);

      if (amount >= 1) {
        str += `${amount + x + (amount > 1 ? 's' : '')} `;
        diff -= amount * values[x];
      }
    }

    return str;
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>AI Tokens</div>
      <div className={classes.body}>
        <ul className={classes.feathers}>
          {data?.map((item, index) => (
            <li key={index}>
              <Grid container>
                <Grid container item lg={8} md={12} sm={12} xs={8}>
                  <div className={classes.icon}>
                    <DatabaseIcon />
                  </div>
                  <div className={classes.info}>
                    <div className={classes.aiCount}>
                      <span>{item.ai_count} </span>Tokens
                    </div>
                    <Grid container>
                      <div className={classes.price}>$ {item.price}</div>
                      <div style={{fontSize: 14}}>{humanise(item.duration)}</div>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={4}>
                  <button
                    type="button"
                    className={classes.buyBtn}
                    onClick={() => bookPackage(item)}>
                    Buy Now
                  </button>
                </Grid>
              </Grid>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

BasicPackageItem.propTypes = {
  data: PropTypes.object.isRequired,
  bookPackage: PropTypes.func.isRequired,
};

export default BasicPackageItem;
