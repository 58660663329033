import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SvgPlayCircle from '../../Icons/gen/PlayCircle';
import {parseVideoUrlToIframe} from '../../../utils/utils';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '20px 0px',
    width: '100%',
  },
  mobileRoot: {
    padding: '10px',
    width: '100%',
  },
  adviceText: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: 60,
  },
  adviceBtn: {
    marginTop: 15,
    color: '#fff',
    fontSize: 14,
    fontWeight: 600,
    padding: '15px 30px',
    textTransform: 'capitalize',
  },
  practiceAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: '#EDF0F2',
    padding: theme.spacing(0.7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  },
  type: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  item: {
    borderBottom: '1px solid rgba(228, 228, 228, 0.6)',
    paddingBottom: '10px',
    marginTop: '17px',
    width: '100%',
  },
  itemTitle: {
    fontSize: '13px',
    fontWeight: 600,
    color: '#0F4C8F',
    marginLeft: 5,
    cursor: 'pointer',
  },
  fixFlex: {
    marginBottom: '30px',
    // '@media (max-width: 800px)': {
    //   justifyContent: 'center',
    // },
  },
  iconWrapper: {
    background: '#E5E5E5',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    padding: 10,
  },
  videoBox: {
    position: 'relative',
    width: '600px',
    paddingTop: '56.25%',
    '@media (max-width: 480px)': {
      width: 310,
    },
    '@media (max-width: 320px)': {
      width: 240,
    },
  },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const StudyTour = () => {
  const classes = useStyle();
  const {videosPosts} = useSelector((state) => ({
    videosPosts: state.post.videosPosts,
  }));
  const [videoSource, setVideoSource] = useState('');
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div style={{width: '100%'}}>
        {videosPosts &&
          videosPosts.data &&
          videosPosts.data
            .filter((i) => i.is_featured === 0)
            .map((p) => (
              <div className={classes.item} key={p.id}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  xs={12}>
                  <Grid xs={1} item container justify="flex-start">
                    <div className={classes.iconWrapper}>
                      {/* <SvgHelpMark /> */}
                      <SvgPlayCircle />
                    </div>
                  </Grid>
                  <Grid
                    xs={10}
                    style={{textAlign: 'start'}}
                    item
                    container
                    //   justify={`${useragent?.isMobile ? 'center' : 'flex-start'}`}
                    onClick={() => {
                      setVideoSource(p.text);
                      setOpenModal(true);
                    }}>
                    <Typography
                      variant="subtitle1"
                      className={classes.itemTitle}>
                      {p.title}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ))}
      </div>
      <Dialog
        onClose={() => setOpenModal(false)}
        aria-labelledby="simple-dialog-title"
        open={openModal}>
        <div className={classes.videoBox}>
          <iframe
            className={classes.videoIframe}
            src={parseVideoUrlToIframe(videoSource)}
            title="Video Player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Dialog>
    </>
  );
};

StudyTour.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  t: PropTypes.func,
  onQuestionChange: PropTypes.func,
  useragent: PropTypes.object,
  sidebar: PropTypes.bool,
};

StudyTour.defaultProps = {
  t: () => null,
  onQuestionChange: () => {},
  useragent: {},
  sidebar: false,
};

export default StudyTour;
