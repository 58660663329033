import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Draggable} from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
  orderContainer: {
    position: 'relative',
    userSelect: 'none',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minHeight: '50px',
    color: 'white',
    border: '1px solid #ddd',
  },
  mobileOrderContainer: {
    position: 'relative',
    userSelect: 'none',
    marginBottom: theme.spacing(3),
    // marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minHeight: '50px',
    color: 'white',
    border: '1px solid #ddd',
  },
  paragraph: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: '#303030',
  },
  order: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    color: '#fff',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const ReorderParagraphItem = ({item, index, optionIndex, mobile}) => {
  const classes = useStyles();
  return (
    <Draggable key={index} draggableId={item} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={
              mobile ? classes.mobileOrderContainer : classes.orderContainer
            }
            style={{
              backgroundColor: snapshot.isDragging ? '#efefef' : '#fff',
              ...provided.draggableProps.style,
            }}>
            <Typography variant="subtitle1" className={classes.paragraph}>
              {item}
              <span className={classes.order}>{optionIndex + 1}</span>
            </Typography>
          </div>
        );
      }}
    </Draggable>
  );
};

ReorderParagraphItem.propTypes = {
  item: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  optionIndex: PropTypes.number.isRequired,
};

export default ReorderParagraphItem;
