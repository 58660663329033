import * as React from 'react'

function SvgSort(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M17 6H2a1 1 0 010-2h15a1 1 0 110 2z" fill={props.color} />
      <path
        d="M22 6h-5a1 1 0 110-2h5a1 1 0 110 2zM12 13H2a1 1 0 110-2h10a1 1 0 110 2z"
        fill={props.color}
      />
      <path
        d="M22 13H12a1 1 0 110-2h10a1 1 0 110 2zM22 20H7a1 1 0 110-2h15a1 1 0 110 2z"
        fill={props.color}
      />
      <path
        d="M7 20H2a1 1 0 110-2h5a1 1 0 110 2zM17 8a1 1 0 01-1-1V3a1 1 0 112 0v4a1 1 0 01-1 1zM12 15a1 1 0 01-1-1v-4a1 1 0 112 0v4a1 1 0 01-1 1z"
        fill={props.color}
      />
      <path
        d="M7 22a1 1 0 01-1-1v-4a1 1 0 112 0v4a1 1 0 01-1 1z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgSort
