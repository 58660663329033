import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withUserAgent} from 'next-useragent';
import {useRouter} from 'next/router';
import {useSnackbar} from 'notistack';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import {useSelector, useDispatch} from 'react-redux';
import {
  getQuestion,
  getMockAnswersAction,
  showMockUserAction,
  getMockAction,
  practiceScoringAction,
  getAiCallStatus,
  getAcceptableAnswer,
} from 'core/lib/adapters/redux/actions';
import ReadAloudPractice from 'components/Practice/Speaking/ReadAloud/ReadAloudPractice';
import DescribeImagePractice from 'components/DescribeImagePractice';
import ListenAndRepeatPractice from 'components/ListenAndRepeatPractice';
import WritingPractice from 'components/WritingPractice';
import RWFillInTheBlanksPractice from 'components/RWFillInTheBlanksPractice';
import ReadingChoicePractice from 'components/ReadingChoicePractice';
import ReorderParagraphPractice from 'components/MockTest/MockAnswer/ReorderParagraph';
import ReadingFillInTheBlanks from 'components/ReadingFillInTheBlanks';
import SummarizeSpokenText from 'components/Practice/Listening/SummarizeSpokenText';
import ListeningFillInTheBlanks from 'components/Practice/Listening/FillInTheBlanks';
import SelectMissingWord from 'components/Practice/Listening/SelectMissingWord';
import HighlightIncorrectWords from 'components/Practice/Listening/HighlightIncorrectWords';
import NonAiScore from 'components/NonAiScore/nonAi';
import AiScore from 'components/AiScore/ai';
import {useTranslation} from 'react-i18next';
import {useInterval} from 'utils/utils';
import ModelAnswer from '../ModalComponents/modelAnswerComponent';
import LoadingIndicator from '../../LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  paginationUl: {
    '&>li>button': {
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '42.05px',
      height: '34.16px',
      background: '#fff',
      border: '1px solid #ECEEEF',
      boxSizing: 'border-box',
      cursor: 'pointer',
      outline: 'none',
      borderRadius: 0,
    },
    '&>li:first-child>button': {
      borderRadius: '5px 0px 0px 5px',
    },
    '&>li:last-child>button': {
      borderRadius: '0px 5px 5px 0px',
    },
  },
  paginationUlMobile: {
    '&>li>button': {
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32.05px',
      height: '34.16px',
      background: '#fff',
      border: '1px solid #ECEEEF',
      boxSizing: 'border-box',
      cursor: 'pointer',
      outline: 'none',
      borderRadius: 0,
    },
    '&>li:first-child>button': {
      borderRadius: '5px 0px 0px 5px',
    },
    '&>li:last-child>button': {
      borderRadius: '0px 5px 5px 0px',
    },
  },
  paginationRoot: {
    marginTop: theme.spacing(2),
  },
  paginationContainer: {
    display: 'flex',
  },
  paginationItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '42.05px',
    height: '34.16px',
    background: '#fff',
    border: '1px solid #ECEEEF',
    boxSizing: 'border-box',
    cursor: 'pointer',
    marginTop: '20px',
    outline: 'none',
    '&:fcous': {
      outline: 'none',
    },
    '&:first-child': {
      borderRadius: '5px 0px 0px 5px',
    },
    '&:last-child': {
      borderRadius: '0px 5px 5px 0px',
    },
  },
  activeItem: {
    background: `${theme.palette.primary.main} !important`,
    color: '#fff',
    outline: 'none',
  },
  questionIndex: {
    width: '45px',
    height: '45px',
    borderRadius: '5px',
    background: '#EDF0F2',
    color: 'black',
    fontSize: '18px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeContainer: {
    width: '168px',
    height: '37px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#EDF0F2',
    color: 'black',
    fontSize: '14px',
    fontWeight: 600,
    padding: '0 12px',
    borderRadius: '3px',
    '& span': {
      color: 'red',
    },
  },
  navigator: {
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.main,
    background: '#fff !important',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14.4px',
    height: '38px',
    border: 'none',
    outline: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      background: '#fff',
    },
  },
  navBar: {
    background: theme.palette.primary.main,
    width: '100%',
    height: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderRadius: '3px',
    padding: '8px 42px',
  },
  exitButton: {
    width: 69,
    height: 38,
    background: '#fff',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14.4,
    border: 'none',
    outline: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  },
}));

const MockAnswer = ({ua, lastPageData, propCurrentQuestion, onExit}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const router = useRouter();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [playerState, setPlayerState] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const acceptableAnswer = useSelector(
    (state) =>
      state.questions.acceptableAnswer[
        lastPageData[currentQuestion].question.id
      ],
  );

  useEffect(() => {
    dispatch(
      getAcceptableAnswer({id: lastPageData[currentQuestion].question.id}),
    );
  }, [currentQuestion]);

  const shouldRenderModelAnswer = (question, answer) => {
    return (
      question &&
      answer &&
      [
        'SummarizeWrittenText',
        'WriteEssay',
        'ReadAloud',
        'RepeatSentence',
        'DescribeImage',
        'RetellLecture',
        'AnswerShortQuestion',
        'SummarizeSpokenText',
        'WriteFromDictation',
      ].indexOf(question.question_type) >= 0
    );
  };

  const renderQuestion = (question, answer) => {
    switch (question.question_type) {
      case 'ReadAloud':
        return (
          <ReadAloudPractice
            question={question}
            t={t}
            onAnswered={() => {}}
            answer={answer?.answer?.answer}
            mockAnswer
            useragent={ua}
          />
        );
      case 'RepeatSentence':
      case 'RetellLecture':
      case 'AnswerShortQuestion':
        return (
          <ListenAndRepeatPractice
            question={question}
            t={t}
            answer={answer?.answer?.answer}
            mockAnswer
            onAnswered={() => {}}
            useragent={ua}
          />
        );
      case 'DescribeImage':
        return (
          <DescribeImagePractice
            question={question}
            t={t}
            answer={answer?.answer?.answer}
            onAnswered={() => {}}
            mockAnswer
            useragent={ua}
          />
        );
      case 'SummarizeWrittenText':
      case 'WriteEssay':
        return (
          <WritingPractice
            question={question}
            t={t}
            answer={answer?.answer?.answer}
            onAnswered={() => {}}
            mockAnswer
            submittedQuestion={!!answer}
            useragent={ua}
          />
        );
      case 'ReadingAndWritingFillInTheBlanks':
        return (
          <RWFillInTheBlanksPractice
            t={t}
            question={question}
            answer={answer?.answer?.answer}
            onAnswered={() => {}}
            mockAnswer
            questionAnswer={answer}
            submittedQuestion={answer?.answer?.answer}
          />
        );
      case 'ReadingMultipleChoiceMultipleAnswer':
      case 'ReadingMultipleChoiceSingleAnswer':
      case 'HighlightCorrectSummary':
      case 'ListeningMultipleChoiceMultipleAnswer':
      case 'ListeningMultipleChoiceSingleAnswer':
        return (
          <ReadingChoicePractice
            t={t}
            answer={answer?.answer?.answer}
            onAnswered={() => {}}
            mockAnswer
            isSingle={
              question.question_type === 'ReadingMultipleChoiceSingleAnswer' ||
              question.question_type === 'HighlightCorrectSummary' ||
              question.question_type === 'ListeningMultipleChoiceSingleAnswer'
            }
            question={question}
            questionAnswer={answer}
            submittedQuestion
            isHighlight={question.question_type === 'HighlightCorrectSummary'}
            isListening={
              question.question_type === 'HighlightCorrectSummary' ||
              question.question_type ===
                'ListeningMultipleChoiceMultipleAnswer' ||
              question.question_type === 'ListeningMultipleChoiceSingleAnswer'
            }
          />
        );
      case 'ReorderParagraph':
        return (
          <ReorderParagraphPractice
            t={t}
            answer={answer?.answer?.answer}
            question={question}
            onAnswered={() => {}}
            mockAnswer
            useragent={ua}
            questionAnswer={answer}
            submittedQuestion={answer?.answer}
          />
        );
      case 'ReadingFillInTheBlanks':
        return (
          <ReadingFillInTheBlanks
            t={t}
            answer={answer?.answer?.answer}
            question={question}
            onAnswered={() => {}}
            mockAnswer
            questionAnswer={answer}
            submittedQuestion={answer?.answer?.answer}
          />
        );
      case 'SummarizeSpokenText':
      case 'WriteFromDictation':
        return (
          <SummarizeSpokenText
            question={question}
            t={t}
            onAnswered={() => {}}
            answer={answer?.answer?.answer}
            mockAnswer
            submittedQuestion={!!answer}
            useragent={ua}
            showCounter={question.question_type === 'SummarizeSpokenText'}
          />
        );
      case 'ListeningFillInTheBlanks':
        return (
          <ListeningFillInTheBlanks
            t={t}
            question={question}
            answer={answer?.answer?.answer}
            mockAnswer
            onAnswered={() => {}}
            questionAnswer={answer}
            submittedQuestion={answer.answer.answer}
          />
        );
      case 'SelectMissingWord':
        return (
          <SelectMissingWord
            t={t}
            answer={answer && answer.answer && answer.answer.answer}
            question={question}
            mockAnswer
            onAnswered={() => {}}
            questionAnswer={answer}
            submittedQuestion={answer?.answer?.answer}
          />
        );
      case 'HighlightIncorrectWords':
        return (
          <HighlightIncorrectWords
            t={t}
            answer={answer?.answer?.answer}
            question={question}
            mockAnswer
            questionAnswer={answer}
            onAnswered={() => {}}
            submittedQuestion={answer?.answer?.answer}
          />
        );
      default:
        return <LoadingIndicator/>
    }
  };

  return (
    <>
      <Grid container justify="center" style={{overflowX: 'auto'}}>
        <Pagination
          count={lastPageData?.length}
          variant="outlined"
          shape="rounded"
          color="primary"
          // siblingCount={2}
          // boundaryCount={3}
          page={currentQuestion + 1}
          onChange={(e, page) => {
            setCurrentQuestion(page - 1);
            propCurrentQuestion(page);
          }}
          size="large"
          // classes={{
          // root: classes.paginationRoot,
          // ul: classes.paginationUlMobile,
          // }}
        />
      </Grid>
      {acceptableAnswer &&
        acceptableAnswer.data &&
        renderQuestion(
          lastPageData[currentQuestion].question,
          lastPageData[currentQuestion].answer,
        )}
      <Grid container style={{marginBottom: '20px'}}>
        {acceptableAnswer &&
          acceptableAnswer.data &&
          lastPageData[currentQuestion].question &&
          shouldRenderModelAnswer(
            lastPageData[currentQuestion].question,
            lastPageData[currentQuestion].answer,
          ) && (
            <ModelAnswer
              question={lastPageData[currentQuestion].question}
              answer={lastPageData[currentQuestion].answer}
              acceptableAnswer={acceptableAnswer}
              pausePlay={playerState !== 'model-answer'}
              onPlayerStarted={() => setPlayerState('model-answer')}
            />
          )}
      </Grid>
      <Grid container className={classes.navBar}>
        <button type="button" className={classes.exitButton} onClick={onExit}>
          Exit
        </button>
      </Grid>
    </>
  );
};

MockAnswer.getInitialProps = async ({ua}) => {
  return {
    props: {ua},
  };
};

MockAnswer.propTypes = {
  ua: PropTypes.object,
  onExit: PropTypes.func,
};

MockAnswer.defaultProps = {
  ua: {},
  onExit: () => {},
};

export default withUserAgent(MockAnswer);
