import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useUserAgent, withUserAgent} from 'next-useragent';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import {useRouter} from 'next/router';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import VideoGuides from 'components/Dashbord/VideoGuides';

const useStyles = makeStyles((theme) => ({
  VideosGuidesContainer: {
    backgroundColor: '#fff',
    padding: '20px 0px',
    borderTop: '2px solid #0F4C8F',
  },
  mainBoxTitle: {
    fontWeight: 800,
    fontSize: 17,
    color: '#303030',
  },
}));
const ResourceTab = ({useragent}) => {
  const {t} = useTranslation();
  const router = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();

  const redirectToNewPage = (e, href) => {
    e.preventDefault();
    router.push(href);
  };

  return (
    <Grid
      item
      xs={12}
      lg={4}
      // className={`${classes.VideosGuidesContainer} ${classes.Container}`}>//for scroable in X
      className={`${classes.VideosGuidesContainer}`}>
      <Grid item xs={12} container>
        <VideoGuides
          isMobile
          t={t}
          type="ss"
          Icon="Speaking"
          typeId="eee"
          useragent={useragent}
        />
      </Grid>
    </Grid>
  );
};
export default ResourceTab;
