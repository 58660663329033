import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CourseHoc from '../ModalComponents/CourseHoc';
import CourseRenderContent from '../ModalComponents/CourseRenderContent';

const VideoModal = ({data, ua, onClose}) => {
  const {t} = useTranslation();

  const check = () => {
    const text = data.content.replace('<p><br></p>', '');
    return {
      __html: text
        .replace('width="500"', 'width="100%"')
        .replace(
          'height="400"',
          'height="100%" style="position:absolute;top:0;right:0;left:0;bottom:0;"',
        ),
    };
  };
  return (
    <CourseHoc
      t={t}
      title={data?.title}
      isTrainingVideo
      showHint={false}
      showAppBarRight={false}
      showAppBarLeft={false}>
      <Grid item xs={12} container justify="center">
        <div
          dangerouslySetInnerHTML={check()}
          style={{
            width: '100%',
            height: ua.isDesktop ? 600 : 180,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
          }}
        />
      </Grid>
      {ua.isMobile && (
        <Button
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          onClick={onClose}
          style={{width: '100%'}}>
          Close
        </Button>
      )}
    </CourseHoc>
  );
};

export default VideoModal;
