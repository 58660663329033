import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Grid, Typography, TextField, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import { recordingStoppedAction } from 'core/lib/adapters/redux/actions/modelAction';
import { useDispatch } from 'react-redux';

// const SpellCheckerTooltip = withStyles((theme) => ({
//   tooltip: {
//     background: '#fff',
//     color: '#353535',
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//     padding: '33px 22px',
//   },
// }))(Tooltip);

const useStyles = makeStyles((theme) => ({
  textContainer: {
    marginBottom: theme.spacing(2),
    marginTop : theme.spacing(2),
    '& p': {
      lineHeight: 1.5,
      fontSize: 18,
      fontWeight: 400,
      color: '#353535',
    },
  },
  textContainerParagraph: {
    // lineHeight:2,
  },
  writingButtons: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      padding: '8px 12px',
      '&.MuiButton-contained': {
        padding: '8px 12px',
        height: '40px',
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    }
  },
  textFieldContainer: {
    marginBottom: theme.spacing(2),
    fontFamily: 'Montserrat',
  },
  totalCounterAndBtnsAndTimerCounter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      // flexDirection: 'column',
      alignItems: 'start'
    }
  },
  actionBtnAlign: {
    justifyContent: 'center',
    order: 2,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
      flexWrap: 'nowrap',
      order: 3,
      marginTop:"12px",
      gap:'6px'
    }
  },
  orderCount: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 1
    }
  },
  orderTimeShow: {
    order: 3,
    [theme.breakpoints.down('xs')]: {
      order: 2
    }
  },
  totalWordCount: {
    marginRight: theme.spacing(0.5),
    fontWeight: 600,
  },
  timer: {
    width: '45px',
    fontWeight: 600,
  },
  timeFinished: {
    color: 'red',
  },
  correctSpell: {
    color: 'green',
  },
  wrongSpell: {
    color: 'red',
  },
}));

const WritingPractice = ({
  t,
  question,
  onAnswered,
  submittedQuestion,
  useragent,
  mockAnswer,
  stopTimer,
  mockQuestion,
  setNextBtnrecordingStop
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const textFieldRef = useRef();
  const [text, setText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const maxAllowedWordCount = 300;
  const [timer, setTimer] = useState(
    question.question_type === 'WriteEssay' ? 1200 : 600,
  );
  const [additionalTime, setAdditionalTime] = useState(false);
  
  useEffect(() => {
    if (timer < 0 && setNextBtnrecordingStop) {
      setNextBtnrecordingStop(true)
    }
  }, [timer])


  // const renderTime = () => {
  //   const timerAbs = Math.abs(timer);
  //   const minute = Math.floor(timerAbs / 60);
  //   const second = timerAbs - 60 * Math.floor(timerAbs / 60);
  //   return `${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`
  //     }`;
  // };
  const renderTime = () => {
    if (timer <= 0) {
      return "00:00";
    }

    const timerAbs = Math.abs(timer);
    const minute = Math.floor(timerAbs / 60);
    const second = timerAbs - 60 * Math.floor(timerAbs / 60);
    return `${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`}`;
  };

  const onTextChange = (e) => {
    setText(e.target.value);
  };

  const copySelected = () => {
    if (submittedQuestion) {
      return;
    }

    const textarea = textFieldRef.current;
    if (textarea) {
      const { selectionStart, selectionEnd } = textarea;

      if (textarea.selectionStart !== textarea.selectionEnd) {
        const selectedText = textarea.value.slice(
          textarea.selectionStart,
          textarea.selectionEnd,
        );

        navigator.clipboard
          .writeText(selectedText)
          .then(() => {
            // enqueueSnackbar(t('notif:selectedTextHasBeenCopied'), {
            //   variant: 'success',
            // });
          })
          .catch((e) => {
            console.log("can't write clipboard", e);
          });

        textarea.focus();
        textarea.selectionStart = selectionStart;
        textarea.selectionEnd = selectionEnd;
      } else {
        textarea.focus();
      }
    }
  };

  const cutSelected = () => {
    if (submittedQuestion) {
      return;
    }

    const textarea = textFieldRef.current;
    if (textarea) {
      if (textarea.selectionStart !== textarea.selectionEnd) {
        const nextSelectionPosition = textarea.selectionStart;
        const selectedText = textarea.value.slice(
          textarea.selectionStart,
          textarea.selectionEnd,
        );

        setText(
          text.slice(0, textarea.selectionStart) +
          text.slice(textarea.selectionEnd),
        );

        navigator.clipboard
          .writeText(selectedText)
          .then(() => {
            // enqueueSnackbar(t('notif:selectedTextHasBeenCut'), {
            //   variant: 'success',
            // });

            textarea.focus();
            textarea.selectionStart = nextSelectionPosition;
            textarea.selectionEnd = nextSelectionPosition;
          })
          .catch((e) => {
            console.log("can't write clipboard", e);
          });
      } else {
        textarea.focus();
      }
    }
  };

  const pasteSelected = () => {
    if (submittedQuestion) {
      return;
    }

    const textarea = textFieldRef.current;
    if (textarea) {
      navigator.clipboard
        .readText()
        .then((copiedText) => {
          const nextSelectionPosition =
            textarea.selectionStart + copiedText.length;
          if (copiedText) {
            setText(
              text.slice(0, textarea.selectionStart) +
              copiedText +
              text.slice(textarea.selectionEnd),
            );

            // enqueueSnackbar(t('notif:selectedTextHasBeenPasted'), {
            //   variant: 'success',
            // });

            textarea.focus();
            textarea.selectionStart = nextSelectionPosition;
            textarea.selectionEnd = nextSelectionPosition;
          }
        })
        .catch((e) => {
          console.log("can't read clipboard", e);
        });
    }
  };

  // const spellCheckerHandler = () => {
  //   const el = document.createElement('textarea');
  //   el.value = text.slice(
  //     textSelection.selectStart,
  //     textSelection.selectEnd + 1,
  //   );
  //   el.select();
  //   axios
  //     .get(`/api/spell-checker?word=${el.value}`)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setSpellChecker(response.data);
  //       }
  //     })
  //     .catch((error) =>
  //       enqueueSnackbar(error.response.data.error, {variant: 'error'}),
  //     );
  // };

  // counter, wordCount,Buttons, onAnswer
  console.log(wordCount, "wordCount", text);
  useEffect(() => {
    const calculateWordCount = () => {
      const wordsArray = text.trim().split(/\s+/);
      const wordCount = wordsArray.filter(word => word !== '').length;
      console.log(wordCount, "wordCount");
      return wordCount;
    };

    const newWordCount = calculateWordCount();
    setWordCount(newWordCount > 0 ? newWordCount : 0);

    const shouldRun = debounce(() => {
      if (newWordCount <= maxAllowedWordCount) {
        onAnswered(text);
      } else {
        onAnswered('');
      }
    }, 50);
    shouldRun();
  }, [text]);



  useEffect(() => {
    if (timer === 0) {
      dispatch(recordingStoppedAction(true)
)    }
  }, [timer]);

  useEffect(() => {
    if (!stopTimer) {
      if (timer === 0) setAdditionalTime(true);
      const timerTimeout = setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => {
        clearTimeout(timerTimeout);
      };
    }
  }, [timer]);

  // useEffect(() => {
  //   if (spellChecker) setSpellTooltip(true);
  // }, [spellChecker]);
  console.log("mockAnswer", mockAnswer);
  console.log(additionalTime, "additionalTime");
  return (
    <Grid container justify="center">
      <Grid item xs={12} className={classes.textContainer}>
        <Typography className={classes.textContainerParagraph} variant="body1">
          {/* {question.question_data.text} */}
          <div dangerouslySetInnerHTML={{ __html: question?.question_data?.text }} />
        </Typography>
      </Grid>
      {!mockAnswer ? (
        <>
          <Grid item xs={12} className={classes.textFieldContainer}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              disabled={submittedQuestion}
              size="medium"
              // spellCheck={spellCheckOn}
              // inputProps={{
              //   autoCorrect: spellCheckOn ? 'on' : 'off',
              //   spellCheck: spellCheckOn ? 'true' : 'false',
              //   'data-gramm': spellCheckOn ? 'true' : 'false',
              // }}
              autoFocus
              placeholder={t('typeHere')}
              rows={10}
              value={text}
              onChange={onTextChange}
              inputRef={textFieldRef}
            />
          </Grid>
          {useragent.isMobile || useragent.isTablet ? (
            <Grid item xs={12} container justify="space-between">
              <Grid item>
                <Box>
                  <Typography
                    className={classes.totalWordCount}
                    display="inline"
                    variant="subtitle2"
                    style={{ fontSize: '13px' }}>
                    {t('totalWordCount')}
                  </Typography>
                  <Typography
                    display="inline"
                    style={{ color: 'red', fontWeight: 600 }}>
                    {wordCount}
                  </Typography>
                </Box>
                {wordCount > maxAllowedWordCount && (
                  <Box>
                    <Typography variant="subtitle2" style={{ color: 'red' }}>
                      Max Allowed: {maxAllowedWordCount} Words
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item>
                {additionalTime ? (
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    className={classes.totalWordCount}
                    style={{ fontSize: '13px' }}>
                    Additional time:{' '}
                  </Typography>
                ) : null}
                <Typography
                  display="inline"
                  className={clsx(classes.timer, {
                    [classes.timeFinished]: additionalTime,
                  })}>
                  {renderTime()}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              container
              className={classes.totalCounterAndBtnsAndTimerCounter}>
              <Grid item xs={6} sm={3} className={classes.orderCount} >
                <Box>
                  <Typography
                    className={classes.totalWordCount}
                    display="inline"
                    variant="subtitle2">
                    {t('totalWordCount')}
                  </Typography>
                  <Typography
                    display="inline"
                    style={{ color: 'red', fontWeight: 600 }}>
                    {wordCount}
                  </Typography>
                </Box>
                {wordCount > maxAllowedWordCount && (
                  <Box>
                    <Typography variant="subtitle2" style={{ color: 'red' }}>
                      Max Allowed: {maxAllowedWordCount} Words
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item container className={classes.actionBtnAlign} xs={12} sm={6}>
                {/* {!mockQuestion ? (
                  <>
                    {spellChecker ? (
                      <SpellCheckerTooltip
                        title={
                          <div>
                            {spellChecker?.map((item) => (
                              <Grid
                                container
                                direction="column"
                                style={{
                                  borderBottom: '1px solid #ccc',
                                  padding: '10px 0',
                                }}>
                                <div>
                                  word:{' '}
                                  <span
                                    className={
                                      item.is_correct
                                        ? classes.correctSpell
                                        : classes.wrongSpell
                                    }>
                                    {item.word}
                                  </span>
                                </div>
                                {item.suggestions &&
                                item.suggestions.length > 0 ? (
                                  <div>
                                    suggestion:{' '}
                                    {item?.suggestions?.map((suggest) => (
                                      <span>{suggest}, </span>
                                    ))}
                                  </div>
                                ) : null}
                              </Grid>
                            ))}
                          </div>
                        }
                        arrow
                        open={spellTooltip}>
                        <Button
                          variant="contained"
                          className={classes.writingButtons}
                          onClick={() => {
                            setSpellTooltip(false);
                            setSpellChecker(null);
                          }}>
                          {t('spellChecker')}
                        </Button>
                      </SpellCheckerTooltip>
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.writingButtons}
                        onClick={() => {
                          spellCheckerHandler();
                        }}>
                        {t('spellChecker')}
                      </Button>
                    )}
                  </>
                ) : null} */}
                <Button
                  variant="contained"
                  className={classes.writingButtons}
                  onClick={cutSelected}>
                  {t('cut')}
                </Button>
                <Button
                  variant="contained"
                  className={classes.writingButtons}
                  onClick={copySelected}>
                  {t('copy')}
                </Button>
                <Button
                  variant="contained"
                  className={classes.writingButtons}
                  onClick={pasteSelected}>
                  {t('paste')}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                className={classes.orderTimeShow}
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {additionalTime ? (
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    className={classes.totalWordCount}>
                    {/* Additional time:{' '} */}
                  </Typography>
                ) : null}
                <Typography
                  display="inline"
                  className={clsx(classes.timer, {
                    [classes.timeFinished]: additionalTime,
                  })}>
                  {renderTime()}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : null}
    </Grid>
  );
};

WritingPractice.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_type: PropTypes.string.isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.string,
  submittedQuestion: PropTypes.bool.isRequired,
  useragent: PropTypes.object,
  mockAnswer: PropTypes.bool,
  stopTimer: PropTypes.bool,
};
WritingPractice.defaultProps = {
  answer: null,
  useragent: {},
  mockAnswer: false,
  stopTimer: false,
};

export default WritingPractice;
