import * as React from 'react'

function SvgSearch(props) {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10c0 5.523 4.477 10 10 10s10-4.477 10-10S15.523 0 10 0 0 4.477 0 10zm2 0a8 8 0 1116 0 8 8 0 01-16 0z"
        fill={props.color}
      />
      <path
        d="M23 24a1 1 0 01-.71-.29L16 17.42A1.004 1.004 0 0117.42 16l6.29 6.28a1 1 0 010 1.42 1 1 0 01-.71.3z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgSearch
