import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';

const useStepOneStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 'normal',
  },
}));
function StepOne({ isTakenBefore }) {
  const s = useStepOneStyles();
  const [value, setValue] = useState("no");
  const handleChange = (event) => {
    const { value } = event.target;
    setValue(value);
  };

  useEffect(() => {
    isTakenBefore(value);
  }, [value]);
  return (
    <>
      <h4 className={s.title}>Have You Taken This Test Before?</h4>
      <Radio
        checked={value === 'yes'}
        onChange={handleChange}
        value="yes"
        color="default"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'D' }}
      />{' '}
      Yes
      <Radio
        checked={value === 'no'}
        onChange={handleChange}
        value="no"
        color="default"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'D' }}
      />{' '}
      No
      <div style={{ marginBottom: 200 }}></div>
    </>
  );
}

export default StepOne;
