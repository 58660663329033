import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash.debounce';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import {
  fetchAllSessionsAction,
  fetchUserSessionsAction,
} from 'core/lib/adapters/redux/actions/sessionActions';
import SessionTable from './SessionTable';
import UserSessionsTable from './SessionTable/userSessionsTable';
import UserSessionsTableM from './SessionTable/Mobile/userSessionsTableM';
import SessionTableM from './SessionTable/Mobile';
import AppTab from '../../AppTab';
import AppTabs from '../../AppTabs';
import { Box } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    // padding: '20px 0px',
    width: '100%',
  },
  mobileRoot: {
    padding: '10px',
    width: '100%',
  },
  practiceAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: '#EDF0F2',
    padding: theme.spacing(0.7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  },
  type: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  title: {
    marginTop: theme.spacing(3),
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    fontSize: '24px',
  },
  questionType: {
    fontSize: '24px',
    fontWeight: 600,
  },
  searchInput: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&::placeholder': {
      fontSize: '12px',
      fontWeight: 500,
      color: '#c7c7c7',
      opacity: 1,
    },
  },
  sortText: {
    color: '#dadada',
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  sortIcon: {
    transform: 'rotate(90deg)',
  },
}));

const QuestionList = ({
  t,
  typeId,
  onQuestionChange,
  useragent,
  hideFilterBtns,
  hideFilterBtnsInMobile,
  inDashboard = false,
  year,
  month,
}) => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState('Sessions');
  const [page, setPage] = useState(1);
  const { sessions, userSessions } = useSelector((state) => ({
    sessions: state.session.sessions,
    userSessions: state.session.userSessions,
  }));

  const changeActiveTabHandler = (event, newValue, useragentStatuse) => {
    setActiveTab(newValue);
    hideFilterBtnsInMobile(useragentStatuse);
    if (newValue === 0) {
      hideFilterBtns(true);
      setActiveTabName('Sessions');
    }
    if (newValue === 1) {
      hideFilterBtns(false);
      setActiveTabName('My Sessions');
    }
  };

  const getSessionListHandler = () => {
    if (activeTabName === 'Sessions') {
      dispatch(
        fetchAllSessionsAction({
          page,
          per_page: 15,
          search: `${year}-${month}-1`,
        }),
      );
    } else if (activeTabName === 'My Sessions')
      dispatch(fetchUserSessionsAction({ page, per_page: 15 }));
  };

  useEffect(() => {
    getSessionListHandler();
  }, [page, activeTabName, year, month]);

  useEffect(() => {
    changeActiveTabHandler('e', 0, useragent.isMobile || useragent.isTablet);
  }, []);

  return useragent.isMobile || useragent.isTablet ? (
    <Grid className={classes.mobileRoot}>
      <Grid container>
        <AppTabs
          value={activeTab}
          onChange={(e, newValue) =>
            changeActiveTabHandler(
              e,
              newValue,
              useragent.isMobile || useragent.isTablet,
            )
          }
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="scrollable"
          scrollButtons="auto"
          aria-label="ant example">
          <AppTab label={t('sessionsList')} />
          <AppTab label={t('mySessions')} />
        </AppTabs>
      </Grid>
      {activeTabName === 'Sessions' ? (
        <>
          <SessionTableM
            t={t}
            sessions={sessions}
            changePage={(pageNumber) => {
              setPage(pageNumber);
            }}
            questionType={typeId}
            onQuestionChange={onQuestionChange}
            useragent={useragent}
            page={page}
          />
        </>
      ) : (
        <UserSessionsTableM
          t={t}
          sessions={userSessions}
          setResetUserSessionListAfterBuy={() => {
            getQuestionListHandler();
          }}
          changePage={(pageNumber) => {
            setPage(pageNumber);
          }}
          questionType={typeId}
          onQuestionChange={onQuestionChange}
          useragent={useragent}
          hideFilterBtns={() => hideFilterBtns(false)}
          page={page}
        />
      )}
    </Grid>
  ) : (
    <Box style={{ padding: '20px' }}>
      <Grid className={classes.root}>
        <Grid container>
          <AppTabs
            style={{ paddingLeft: inDashboard ? 0 : 58 }}
            value={activeTab}
            onChange={(e, newValue) =>
              changeActiveTabHandler(
                e,
                newValue,
                useragent.isMobile || useragent.isTablet,
              )
            }
            indicatorColor="primary"
            textColor="primary">
            <AppTab label={t('sessionsList')} />
            <AppTab label={t('mySessions')} />
          </AppTabs>
        </Grid>
        {activeTabName === 'Sessions' ? (
          <>
            <SessionTable
              t={t}
              sessions={sessions}
              changePage={(pageNumber) => {
                setPage(pageNumber);
              }}
              questionType={typeId}
              onQuestionChange={onQuestionChange}
              useragent={useragent}
              page={page}
              inDashboard={inDashboard}
            />
          </>
        ) : (
          <UserSessionsTable
            t={t}
            sessions={userSessions}
            setResetUserSessionListAfterBuy={() => {
              getQuestionListHandler();
            }}
            changePage={(pageNumber) => {
              setPage(pageNumber);
            }}
            questionType={typeId}
            onQuestionChange={onQuestionChange}
            useragent={useragent}
            page={page}
            inDashboard={inDashboard}
          />
        )}
      </Grid>
    </Box>
  );
};

QuestionList.propTypes = {
  t: PropTypes.func,
  onQuestionChange: PropTypes.func,
  useragent: PropTypes.object,
  sidebar: PropTypes.bool,
  year: PropTypes.number,
  month: PropTypes.number,
};

QuestionList.defaultProps = {
  t: () => null,
  onQuestionChange: () => { },
  useragent: {},
  sidebar: false,
  month: 0,
  year: 0,
};

export default QuestionList;
