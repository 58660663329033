import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import UserPackageList from '../../../components/userPackageList';
import useTheme from '@material-ui/core/styles/useTheme';
import {useRouter} from 'next/router';
import moment from 'moment';
import Watch from '../../../../Icons/gen/Watch';
import CircularProgress from '@material-ui/core/CircularProgress';
import {pascalCaseConvertor} from '../../../../../utils/utils';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useMediaQuery} from '@material-ui/core';
import {cancelingSessionAction} from 'core/lib/adapters/redux/actions';

const useStyle = makeStyles((theme) => ({
  paginationUl: {
    '&>li>button': {
      padding: 0,
      margin: 0,
      borderRadius: 0,
    },
    '&>li:first-child>button': {
      borderRadius: `${theme.spacing(0.5)}px 0 0 ${theme.spacing(0.5)}px`,
    },
    '&>li:last-child>button': {
      borderRadius: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    },
  },
  paginationRoot: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  sessionTitle: {
    fontWeight: 600,
    fontSize: 16,
  },
  anchor: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
  },
  tableCell: {
    width: '30%',
    verticalAlign: 'baseline',
  },
  watchCell: {
    verticalAlign: 'baseline',
    paddingTop: 22,
    width: '20%',
  },
  descriptionCell: {
    verticalAlign: 'baseline',
    width: '30%',
    // paddingTop: 22,
  },
  dialogContainer: {
    margin: 0,
    width: '100%',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: 16,
    color: '#353535',
  },
  dialogSubTitle: {
    fontWeight: 500,
    fontSize: 14,
    color: '#353535',
    marginTop: 15,
  },
  dialogTagSubTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tagBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    marginRight: theme.spacing(1),
  },
  tagBoxText: {
    fontWeight: 700,
    fontSize: 11,
  },
  setsTasgs: {
    width: 100,
    height: 26,
    backgroundColor: 'rgba(15,76,143,.1)',
  },
  setsTasgsText: {
    color: '#0F4C8F',
  },
  freeTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(66, 255, 0, 0.1)',
  },
  freeTagText: {
    color: '#3E9D03',
  },
  paidTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(255, 60, 60, 0.08)',
  },
  paidTagText: {
    color: '#FF3C3C',
  },
  watchBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#353535',
  },
  watchBoxSVG: {
    paddingRight: theme.spacing(1),
  },
  dialogContent: {
    fontWeight: 400,
    fontSize: 18,
    color: '#303030',
    maxHeight: 400,
    overflow: 'scroll',
    marginTop: theme.spacing(2),
  },
  dialogFooter: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dialogFooterBtn: {
    fontWeight: 600,
    fontSize: 16,
    outline: 'none',
    border: 'none',
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    borderRadius: 3,
  },
  dialogFooterBtnJoin: {
    color: 'white',
    backgroundColor: '#0F4C8F',
  },
  dialogFooterBtnCancel: {
    color: '#808080',
    backgroundColor: '#DADADA',
  },
}));

const UserSessionsTable = ({sessions, changePage, onQuestionChange}) => {
  const classes = useStyle();
  const {enqueueSnackbar} = useSnackbar();

  const dispatch = useDispatch();
  const {data, meta} = sessions || {};
  const [page, setPage] = useState(1);
  const errorMessage = useSelector((state) => state.session.errorMessage);
  const error = useSelector((state) => state.session.error);

  const [sessionModal, setSessionModal] = useState(false);
  const [sessionInModal, setSessionInModal] = useState({});

  useEffect(() => {
    changePage(page);
  }, [page]);

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {variant: 'error'});
      dispatch({type: 'RESET_SESSION_BOOKING'});
    }
  }, [errorMessage, error]);

  return (
    <>
      {!data || !meta ? (
        <Grid
          container
          className={classes.root}
          direction="column"
          alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container>
          <Table aria-label="simple table">
            <TableBody>
              {data &&
                data.map((session) => (
                  <>
                    {!session.canceled_at && (
                      <TableRow key={session.session.id}>
                        {sessionModal && (
                          <Dialog
                            classes={{paper: classes.dialogContainer}}
                            open={sessionModal}
                            fullWidth
                            maxWidth="sm"
                            onClose={() => {
                              setSessionModal(!sessionModal);
                            }}
                            aria-labelledby="responsive-dialog-title">
                            <DialogContent style={{width: '100%'}}>
                              <DialogContentText>
                                <SessionDetail session={sessionInModal || {}} />
                              </DialogContentText>
                            </DialogContent>
                          </Dialog>
                        )}
                        <TableCell classes={{root: classes.tableCell}}>
                          <div className={classes.row}>
                            {/* <span className={classes.index}>
                          {questionNumber(index)}
                        </span> */}
                            <div className={classes.col}>
                              <Typography
                                variant="subtitle1"
                                classes={{subtitle1: classes.sessionTitle}}
                                // onClick={onQuestionChange}
                                onClick={(e) => {
                                  onQuestionChange(e);
                                  setSessionInModal(session);
                                  setSessionModal(true);
                                }}>
                                {/* <Link
                              href={`/sessions/${session.category}/${questionType}/${session.id}`}>
                              <a className={classes.anchor}> */}
                                {pascalCaseConvertor(session.session.name)}
                                {/* </a>
                            </Link> */}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary">
                                {`Added ${moment(
                                  session.session.started_at,
                                ).format('dddd Do MMMM, YYYY, LT')}-${
                                  session.timezone
                                }`}
                              </Typography>
                              <div className={classes.row}>
                                <div
                                  className={`${classes.tagBox} ${classes.setsTasgs}`}>
                                  <span
                                    className={`${classes.tagBoxText} ${classes.setsTasgsText}`}>
                                    {`${
                                      parseInt(session.session.capacity, 10) -
                                      (parseInt(session.session.submits, 10) ||
                                        0)
                                    } Seats Left`}
                                  </span>
                                </div>
                                <div
                                  className={`${classes.tagBox} ${
                                    session.session.is_free
                                      ? classes.freeTag
                                      : classes.paidTag
                                  }`}>
                                  <span
                                    className={`${classes.tagBoxText} ${
                                      session.session.is_free
                                        ? classes.freeTagText
                                        : classes.paidTagText
                                    }`}>
                                    {session.session.is_free ? 'Free' : 'Paid'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
            </TableBody>
          </Table>
          <Grid container justify="center" alignItems="center">
            <Pagination
              count={meta && meta.pagination && meta.pagination.total_pages}
              variant="outlined"
              shape="rounded"
              color="primary"
              boundaryCount={3}
              page={meta && meta.pagination && meta.pagination.current_page}
              onChange={(e, page) => setPage(page)}
              size="large"
              classes={{
                root: classes.paginationRoot,
                ul: classes.paginationUl,
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

UserSessionsTable.propTypes = {
  t: PropTypes.func,
  sessions: PropTypes.object,
  changePage: PropTypes.func,
  questionType: PropTypes.string,
  onQuestionChange: PropTypes.func,
};

UserSessionsTable.defaultProps = {
  t: () => null,
  sessions: [],
  changePage: () => null,
  questionType: '',
  onQuestionChange: () => {},
};

export default UserSessionsTable;

const SessionDetail = ({session = {}}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {user, payment} = useSelector(
    (state) => ({
      user: state.auth.user,
      payment: state.payment.payment,
    }),
    shallowEqual,
  );

  const getDuration = (mins) => {
    var h = (mins / 60) | 0,
      m = mins % 60 | 0;

    if (h == 0 && m != 0) return {data: m, message: 'mins', both: false};
    else if (m == 0 && h != 0) return {data: h, message: 'hrs', both: false};
    else return {data: h, subData: m, message: 'hrs', both: true};
  };
  return (
    <div style={{width: '100%'}}>
      <div className={classes.dialogTitle}>{session.session.name}</div>
      <div className={classes.dialogSubTitle}>{`Added ${moment(
        session.session.started_at,
      ).fromNow()}`}</div>
      <div className={classes.dialogTagSubTitle}>
        <div className={classes.tagContainer}>
          <div className={`${classes.tagBox} ${classes.setsTasgs}`}>
            <span className={`${classes.tagBoxText} ${classes.setsTasgsText}`}>
              {`${session.session.capacity} Seats Left`}
            </span>
          </div>
          <div
            className={`${classes.tagBox} ${
              session.session.is_free ? classes.freeTag : classes.paidTag
            }`}>
            <span
              className={`${classes.tagBoxText} ${
                session.session.is_free
                  ? classes.freeTagText
                  : classes.paidTagText
              }`}>
              {session.session.is_free ? 'Free' : 'Paid'}
            </span>
          </div>
        </div>

        <div className={classes.watchBox}>
          <Watch />{' '}
          <span
            style={{
              paddingLeft: 8,
            }}>
            {`${
              getDuration(session.session.duration).both
                ? `${getDuration(session.session.duration)?.data}/${
                    getDuration(session.session.duration).subData
                  } ${getDuration(session.session.duration).message}`
                : `${getDuration(session.session.duration)?.data} ${
                    getDuration(session.session.duration).message
                  }`
            }`}{' '}
          </span>
        </div>
      </div>
      <div className={classes.dialogContent}>{session.session.description}</div>
      <div className={classes.dialogFooter}>
        {session.join_url && (
          <button
            className={`${classes.dialogFooterBtn} ${classes.dialogFooterBtnJoin}`}
            onClick={() => {
              window.open(
                `https://zoom.us/wc/join/${
                  session.join_url.split('/').reverse()[0]
                }`,
                '_blank',
              );
            }}>
            Open
          </button>
        )}

        {!session.session.canceled_at ? (
          <button
            onClick={() => {
              dispatch(cancelingSessionAction(session.id));
            }}
            className={`${classes.dialogFooterBtn} ${classes.dialogFooterBtnCancel}`}>
            Cancel
          </button>
        ) : (
          <span style={{color: 'red', fontWeight: 600}}>
            Canceled by teacher
          </span>
        )}
      </div>
    </div>
  );
};
