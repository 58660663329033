import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Training from './Training';
import PracticePathway from './PracticePathway';
import weekIcon from '../../../assets/icons/weekIcon.svg'
import AppTab from './Tabs/AppTab';
import AppTabs from './Tabs/AppTabs';
import { Box } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '11px 0px 24px 0px',
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '2px 0px 14px 0px',
    }
  },
  weekSelectIcon: {
    background: '#e7eef4',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    padding: '6px',
    width: '124px',
    borderRadius: '4px',
    height: '31px',
  },
  weekSelect: {
    // width: '93px',
    // height: '31px',
    color: '#000000',
    fontSize: '17px',
    fontWeight: 600,
    background: '#e7eef4',
    border: 'none',
    outline: 'none',
  },

  mobileRoot: {
    padding: '20px 0px',
    width: '100%',
    overflowX: 'auto',
  },

  skillContainerMobile: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
  skillBox: {
    padding: '10px 12px',
    margin: '2px',
    border: '1px solid #E5E5E5',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: '600',
    width: 'auto',
    display: 'flex',
    borderRadius: "5px",
    [theme.breakpoints.down('md')]: {
      padding: "6px 8px",
      fontSize: 12
    },
  },

  skillBoxMobile: {
    padding: '10px 10px',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    marginRight: 8,
    cursor: 'pointer',
    fontSize: 14,
  },

  suggestedText: {
    color: '#808080',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
  },
  gotoOtherPage: {
    color: theme.palette.primary.main,
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 600,
  },
}));

const AnalyticsInDashboard = ({ useragent, isInDashboard = false }) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState('Practice');
  const [page, setPage] = useState(1);
  const [userQuery, setUserQuery] = useState('');
  const [week, setWeek] = useState(1);

  const [activeSkill, setActiveSkill] = useState(0);
  const studyPlan = useSelector((state) => state.studyPlan.studyPlan) || {};
  const targetWeek = studyPlan?.targets?.week || 1;

  const changeActiveTabHandler = (event, newValue, useragentStatuse) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      setActiveTabName('Practice');
    }
    if (newValue === 1) {
      setActiveTabName('Practice Pathway');
    }
  };

  const getQuestionListHandler = () => {
    if (activeTabName === 'Practice') {
    } else if (activeTabName === 'Practice Pathway') {
    }
  };

  const redirectToNewPage = (e, href) => {
    e.preventDefault();
    router.push(href);
  };

  const Range = (start, end) => {
    const list = [];
    for (let i = start; i <= end; i++) {
      list.push(i);
    }
    return list;
  };

  useEffect(() => {
    getQuestionListHandler();
  }, [page, activeTabName, userQuery]);

  useEffect(() => {
    changeActiveTabHandler('e', 0, useragent.isMobile || useragent.isTablet);
  }, []);

  return (
    <>
      <Grid className={classes.root}>
        {/* <Grid container justify="space-between" alignItems="center">
          <Grid item xs={5}>
            <AppTabs
              value={activeTab}
              onChange={(e, newValue) =>
                changeActiveTabHandler(
                  e,
                  newValue,
                  useragent.isMobile || useragent.isTablet,
                )
              }
              indicatorColor="primary"
              textColor="primary"
            // centered
            >
              <AppTab label="Practice" />
              <AppTab label="Practice Pathway" />
            </AppTabs>
          </Grid>
          <Grid item>
            {activeTabName === 'Practice' && (
              <select
                name="weeks"
                id="weeks"
                className={classes.weekSelect}
                onChange={(e) => setWeek(e.target.value)}>
                {Range(1, 26).map((item) => (
                  <option value={item}>
                    {item === 1 ? `${item} week` : `${item} weeks`}
                  </option>
                ))}
              </select>
            )}
          </Grid>
        </Grid> */}

        {/* {useragent.isMobile || useragent.isTablet ? (
          <>
            <Grid className={classes.mobileRoot}>
              <Grid
                className={classes.skillContainerMobile}
                container
                direction="row"
                // justify="space-between"
                alignItems="flex-start"
              >
                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBoxMobile}
                  style={{
                    backgroundColor: activeSkill === 0 ? '#0F4C8F' : '',
                    color: activeSkill === 0 ? '#fff' : '',
                  }}
                  onClick={() => setActiveSkill(0)}
                  xs={3}>
                  Speaking
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBoxMobile}
                  style={{
                    backgroundColor: activeSkill === 1 ? '#0F4C8F' : '',
                    color: activeSkill === 1 ? '#fff' : '',
                  }}
                  onClick={() => setActiveSkill(1)}
                  xs={3}>
                  Writing
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBoxMobile}
                  style={{
                    backgroundColor: activeSkill === 2 ? '#0F4C8F' : '',
                    color: activeSkill === 2 ? '#fff' : '',
                  }}
                  onClick={() => setActiveSkill(2)}
                  xs={3}>
                  Reading
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.skillBoxMobile}
                  style={{
                    backgroundColor: activeSkill === 3 ? '#0F4C8F' : '',
                    color: activeSkill === 3 ? '#fff' : '',
                  }}
                  onClick={() => setActiveSkill(3)}
                  xs={3}>
                  Listening
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : ( */}
        <>
          <Grid className={classes.root}>
            <Grid
              container
              direction="row"
              // justify="space-between"
              alignItems="flex-start"
              style={{ margin: '-2px' }}>
              <Grid
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBox}
                style={{
                  backgroundColor: activeSkill === 0 ? '#0F4C8F' : '',
                  color: activeSkill === 0 ? '#fff' : '',
                }}
                onClick={() => setActiveSkill(0)}>
                Speaking
              </Grid>

              <Grid
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBox}
                style={{
                  backgroundColor: activeSkill === 1 ? '#0F4C8F' : '',
                  color: activeSkill === 1 ? '#fff' : '',
                }}
                onClick={() => setActiveSkill(1)}>
                Writing
              </Grid>

              <Grid
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBox}
                style={{
                  backgroundColor: activeSkill === 2 ? '#0F4C8F' : '',
                  color: activeSkill === 2 ? '#fff' : '',
                }}
                onClick={() => setActiveSkill(2)}>
                Reading
              </Grid>

              <Grid
                item
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.skillBox}
                style={{
                  backgroundColor: activeSkill === 3 ? '#0F4C8F' : '',
                  color: activeSkill === 3 ? '#fff' : '',
                }}
                onClick={() => setActiveSkill(3)}>
                Listening
              </Grid>
            </Grid>
          </Grid>
        </>
        {/* )} */}
        {activeTabName === 'Practice' && (
          <Box className={classes.weekSelectIcon}>
            <img src={weekIcon} alt="week" />
            <select
              name="weeks"
              id="weeks"
              // size="10"
              className={classes.weekSelect}
              onChange={(e) => setWeek(e.target.value)}
            >
              {Range(1, 26).map((item) => (
                <option value={item}>
                  {item === 1 ? `${item} week` : `${item} weeks`}
                </option>
              ))}
            </select>
          </Box>
        )}
        {activeTabName === 'Practice' ? (
          <Training
            useragent={useragent}
            isInDashboard={isInDashboard}
            week={week}
            skill={activeSkill}
          />
        ) : (
          <PracticePathway
            useragent={useragent}
            isInDashboard={isInDashboard}
            week={week}
            skill={activeSkill}
          />
        )}

        {useragent.isMobile || useragent.isTablet ? (
          <Grid
            xs={12}
            container
            // className={classes.gotoOtherPage}
            item
            justify="center"
            alignItems="center"
            onClick={(e) => redirectToNewPage(e, '/analytics')}>
            <Typography variant="subtitle1" className={classes.gotoOtherPage}>
              View Full Analytics
            </Typography>
            <ArrowForwardIcon style={{ color: '#0F4C8F' }} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

AnalyticsInDashboard.propTypes = {
  t: PropTypes.func,
  onQuestionChange: PropTypes.func,
  useragent: PropTypes.object,
  sidebar: PropTypes.bool,
};

AnalyticsInDashboard.defaultProps = {
  t: () => null,
  onQuestionChange: () => { },
  useragent: {},
  sidebar: false,
};

export default AnalyticsInDashboard;
