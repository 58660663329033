import * as React from 'react'

function SvgViewMoreImage(props) {
  return (
    <svg width={72} height={72} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#F4F5FE" fillRule="nonzero" cx={36} cy={36} r={36} />
        <path
          stroke="#6D7FE9"
          strokeWidth={4}
          strokeLinecap="round"
          d="M36 24v24m12.001-12h-24"
        />
      </g>
    </svg>
  )
}

export default SvgViewMoreImage
