import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, useMediaQuery } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import useTheme from '@material-ui/core/styles/useTheme';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  bookingSessionAction,
  checkCreditAction,
  useCreditAction,
  getUserPackageIndexAction,
} from 'core/lib/adapters/redux/actions';
import Watch from '../../../Icons/gen/Watch';
import { pascalCaseConvertor } from '../../../../utils/utils';
import UserPackageList from '../../components/userPackageList';

const useStyle = makeStyles((theme) => ({
  paginationUl: {
    '&>li>button': {
      padding: 0,
      margin: 0,
      borderRadius: 0,
    },
    '&>li:first-child>button': {
      borderRadius: `${theme.spacing(0.5)}px 0 0 ${theme.spacing(0.5)}px`,
    },
    '&>li:last-child>button': {
      borderRadius: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
    },
  },
  paginationRoot: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  sessionTitle: {
    fontWeight: 600,
    fontSize: 16,
  },
  sessionDateText: {
    fontWeight: 500,
    fontSize: 15,
    color: '#353535',
  },
  anchor: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
  },
  tagBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tagBoxText: {
    fontWeight: 700,
    fontSize: 11,
  },
  setsTasgs: {
    width: 100,
    height: 26,
    backgroundColor: 'rgba(15,76,143,.1)',
  },
  setsTasgsText: {
    color: '#0F4C8F',
    fontSize: 11,
  },
  freeTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(66, 255, 0, 0.1)',
  },
  freeTagText: {
    color: '#3E9D03',
    fontSize: 11,
  },

  upgradeTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(255, 230, 0, 0.1)',
  },
  upgradeTagText: {
    color: '#DDC80C',
    fontSize: 11,
  },

  paidTag: {
    width: 80,
    height: 26,
    backgroundColor: 'rgba(255, 60, 60, 0.08)',
  },
  paidTagText: {
    color: '#FF3C3C',
    fontSize: 11,
  },

  tableCell: {
    width: '30%',
    verticalAlign: 'baseline',
    paddingLeft: 58,
  },
  tableCellInDashboard: {
    width: '30%',
    verticalAlign: 'baseline',
  },
  watchCell: {
    position: 'relative',
    width: '20%',
  },
  watchBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#353535',
    position: 'absolute',
    top: 30,
  },
  watchBoxSVG: {
    paddingRight: theme.spacing(1),
  },
  descriptionCell: {
    verticalAlign: 'baseline',
    width: '30%',
    // paddingTop: 22,
  },
  joinBtn: {
    textTransform: 'capitalize',
  },

  btnCel: {
    verticalAlign: 'bottom',
  },
  noBottomBorder: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      padding: '8px'
    },
    '& .MuiTableCell-head': {
      fontSize: '14px',
      color: '#808080',
      fontWeight: '500',
      textTransform: 'uppercase'
    },
    '& .MuiTableCell-body ': {
      fontSize: '16px'
    }
  },
  sessionText: {
    borderRight: '1px solid #ccc',
    paddingRight: '8px',
  },
  lastChild: {
    borderRight: 'none '
  },
  attainSec: {
    color: '#0F4C8F'
  }
}));

const SessionsTable = ({ t, sessions, changePage, inDashboard = false }) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { data, meta } = sessions || {};
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const [userPackageListModal, setUserPackageListModal] = useState(false);
  const [sessionBillableId, setSessionBillableId] = useState();
  const [submitSession, setSubmitSession] = useState(false);
  const checkCredit = useSelector((state) => state.package.checkCredit);

  const sessionError = useSelector((state) => state.session.error);
  const sessionErrorMessage = useSelector(
    (state) => state.session.errorMessage,
  );

  const useCredit = useSelector((state) => state.package.useCredit);
  const packageError = useSelector((state) => state.package.error);
  const packageErrorMessage = useSelector(
    (state) => state.package.errorMessage,
  );
  const sessionBooking = useSelector((state) => state.session.sessionBooking);

  const badgeBoxClass = (type, paidStatus) => {
    if (type === 1) return classes.freeTag;
    if (!type && paidStatus === 'available') return classes.upgradeTag;
    return classes.paidTag;
  };

  const badgeTextClass = (type, paidStatus) => {
    if (type === 1) return classes.freeTagText;
    if (!type && paidStatus === 'available') return classes.upgradeTagText;
    return classes.paidTagText;
  };
  const badgeName = (type, paidStatus) => {
    if (type === 1) return 'Free';
    if (!type && paidStatus === 'available') return 'Available';
    return 'Paid';
  };

  const getDuration = (mins) => {
    const h = parseInt(mins / 60, 10) || 0;
    const m = mins % 60 || 0;
    if (h === 0 && m !== 0) return `${m} min`;
    if (m === 0 && h !== 0) return `${h} hrs`;
    return `${h} h ${m} m`;
  };

  const onUpgradeModalCreditSuccess = () => {
    setUserPackageListModal(false);
    enqueueSnackbar(t('notif:upgradedSuccessfully'), {
      key: 'course-loading',
      variant: 'success',
      // persist: true,
    });
    dispatch({ type: 'RESET_CHECK_CREDIT' });
  };

  useEffect(() => {
    changePage(page);
  }, [page]);

  useEffect(() => {
    if (packageError && packageErrorMessage && setSubmitSession) {
      enqueueSnackbar(packageErrorMessage, {
        variant: 'error',
      });
      dispatch({ type: 'RESET_USE_CREDIT' });
      setSubmitSession(false);
    }
  }, [packageErrorMessage, packageError]);

  useEffect(() => {
    if (useCredit && useCredit.isSuccess) {
      enqueueSnackbar(t('notif:bookingSuccessfullyDone'), {
        variant: 'success',
      });
      dispatch({ type: 'RESET_USE_CREDIT' });
      setSubmitSession(false);
    }
  }, [useCredit]);

  useEffect(() => {
    if (sessionError && sessionErrorMessage && submitSession) {
      enqueueSnackbar(sessionErrorMessage, {
        variant: 'error',
      });
      dispatch({ type: 'RESET_SESSION_BOOKING' });
      setSubmitSession(false);
    }
  }, [sessionErrorMessage, sessionError]);

  useEffect(() => {
    if (sessionBooking && submitSession) {
      enqueueSnackbar(t('notif:bookingSuccessfullyDone'), {
        variant: 'success',
      });
      dispatch({ type: 'RESET_SESSION_BOOKING' });
      setSubmitSession(false);
    }
  }, [sessionBooking]);

  useEffect(() => {
    if (checkCredit?.data && checkCredit?.data.length !== 0 && submitSession) {
      dispatch(
        useCreditAction({
          packageId: checkCredit?.data[0].id,
          entityId: sessionBillableId,
        }),
      );
      setSubmitSession(false);
    } else if (
      checkCredit?.data &&
      checkCredit?.data.length === 0 &&
      submitSession
    ) {
      enqueueSnackbar(
        t('notif:yourPackageDoseNotContainThisSessionPleaseUpgrade'),
        {
          variant: 'error',
        },
      );
      setSubmitSession(false);
    }
    dispatch({ type: 'RESET_CHECK_CREDIT' });
  }, [checkCredit]);

  useEffect(() => {
    dispatch(getUserPackageIndexAction({ page: 1, per_page: 100 }));
  }, []);


  const rows = [{
    name: "Vocabulary Session for Good Listening Practice",
    session: [
      'Listening', 'Writing', 'Speaking'
    ]
  },
  {
    name: "Vocabulary Session for Good Listening Practice",
    session: [
      'Listening', 'Writing', 'Speaking'
    ]
  },
  {
    name: "Vocabulary Session for Good Listening Practice",
    session: [
      'Listening', 'Writing', 'Speaking'
    ]
  }
  ];
  return (
    <>
      <TableContainer>
        <Table className={classes.noBottomBorder} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell align="left">Session Name</TableCell>
              <TableCell align="left">Session for</TableCell>
              <TableCell align="left">Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left" style={{ display: 'flex', gap: '8px' }}>
                  {row.session.map((session, sessionIndex) => (
                    <Box
                      key={sessionIndex}
                      align="right"
                      className={`${classes.sessionText} ${sessionIndex === row.session.length - 1 ? classes.lastChild : ''}`}
                    >
                      {session}
                    </Box>
                  ))}
                </TableCell>

                <TableCell className={classes.attainSec} align="left">Attain Session <ArrowForwardIosIcon style={{ fontSize: '12px', color: '#0F4C8F' }} /> </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {!data || !meta ? (
        <Grid
          container
          className={classes.root}
          direction="column"
          alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container>
          <Table aria-label="simple table">
            <TableBody>
              {data &&
                data?.map((session) => (
                  <>
                    <TableRow key={session.id}>
                      <TableCell
                        classes={{
                          root: inDashboard
                            ? classes.tableCellInDashboard
                            : classes.tableCell,
                        }}>
                        <div className={classes.row}>
                          <div className={classes.col}>
                            <Typography
                              variant="subtitle1"
                              classes={{ subtitle1: classes.sessionTitle }}>
                              {pascalCaseConvertor(session.name)}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              className={classes.sessionDateText}>
                              {`${moment
                                .utc(session.started_at)
                                .tz(moment.tz.guess())
                                .format(
                                  'dddd Do MMMM, YYYY, LT',
                                )} - ${moment.tz.guess()}`}
                            </Typography>
                            <div className={classes.row}>
                              <div
                                className={`${classes.tagBox} ${classes.setsTasgs}`}>
                                <span
                                  className={`${classes.tagBoxText} ${classes.setsTasgsText}`}>
                                  {`${session.capacity - (session.submits || 0)
                                    } Seats Left`}
                                </span>
                              </div>
                              <div
                                className={`${classes.tagBox} ${badgeBoxClass(
                                  session.is_free,
                                  session.paid_status,
                                )}`}>
                                <span
                                  className={`${classes.tagBoxText
                                    } ${badgeTextClass(
                                      session.is_free,
                                      session.paid_status,
                                    )}`}>
                                  {badgeName(
                                    session.is_free,
                                    session.paid_status,
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell classes={{ root: classes.watchCell }}>
                        <div className={classes.watchBox}>
                          <Watch width="20" height="20" />{' '}
                          <span
                            style={{
                              paddingLeft: 8,
                              fontSize: 13,
                            }}>
                            {getDuration(session.duration)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className={classes.descriptionCell}>
                        <SmartText text={session.description} length={100} />
                      </TableCell>

                      <TableCell className={classes.btnCel}>
                        <Button
                          style={{
                            cursor: 'pointer',
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                          classes={{ label: classes.joinBtn }}
                          onClick={() => {
                            if (session.is_free)
                              dispatch(
                                bookingSessionAction({ sessionId: session.id }),
                              );
                            else
                              dispatch(
                                checkCreditAction({
                                  id: session.billable_id,
                                }),
                              );
                            setSessionBillableId(session.billable_id);
                            setSubmitSession(true);
                          }}
                          variant="contained"
                          disableElevation
                          disabled={session.capacity === session.submits}
                          color="primary">
                          {session.capacity !== session.submits
                            ? 'Join'
                            : 'Booked Out'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
          <Grid container justify="center" alignItems="center">
            <Pagination
              count={meta && meta.pagination && meta.pagination.total_pages}
              variant="outlined"
              shape="rounded"
              color="primary"
              boundaryCount={3}
              page={meta && meta.pagination && meta.pagination.current_page}
              onChange={(e, selectedPage) => setPage(selectedPage)}
              size="large"
              classes={{
                root: classes.paginationRoot,
                ul: classes.paginationUl,
              }}
            />
          </Grid>
        </Grid>
      )} */}
      <Dialog
        fullScreen={isXs}
        open={userPackageListModal}
        onClose={() => {
          setUserPackageListModal(false);
          dispatch({ type: 'RESET_CHECK_CREDIT' });
          dispatch({ type: 'RESET_PACKAGE' });
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          select your package
        </DialogTitle>
        <DialogContent>
          <UserPackageList
            packages={checkCredit?.data}
            sessionBillId={sessionBillableId}
            onCreditSuccess={onUpgradeModalCreditSuccess}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

SessionsTable.propTypes = {
  t: PropTypes.func,
  sessions: PropTypes.object,
  changePage: PropTypes.func,
  questionType: PropTypes.string,
  onQuestionChange: PropTypes.func,
};

SessionsTable.defaultProps = {
  t: () => null,
  sessions: [],
  changePage: () => null,
  questionType: '',
  onQuestionChange: () => { },
};

export default SessionsTable;

const SmartText = ({ text, length = 220 }) => {
  const [showLess, setShowLess] = useState(true);

  if (text.length < length) {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }

  return (
    <div>
      <div>
        {showLess ? (
          <div
            dangerouslySetInnerHTML={{ __html: `${text.slice(0, length)}...` }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </div>
      <a
        style={{ color: '#0F4C8F', cursor: 'pointer', fontWeight: 600 }}
        onClick={() => setShowLess(!showLess)}>
        See {showLess ? 'More' : 'Less'}
      </a>
    </div>
  );
};
