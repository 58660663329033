import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {
  getSubCategoriesAction,
  // getSubCategoryCategoriesAction
} from 'core/lib/adapters/redux/actions/pte-course';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Types from './Types';
import CourseBox from './CourseBox';

const CourseMain = (props) => {
  const subCategories = useSelector((state) => state.course.subCategories) || {
    loading: true,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubCategoriesAction({id: props.subCatId, depth: 3}));
  }, [props.subCatId]);

  const [subCategoryCategories, setSubCategoryCategories] = useState(null);
  const [subCategorySelected, setSubCategorySelected] = useState('');
  const clicked = (id) => {
    setSubCategoryCategories(
      subCategories.data.filter((item) => item.id === id)[0].children,
    );
    setSubCategorySelected(
      subCategories.data.filter((item) => item.id === id)[0].name,
    );
  };

  useEffect(() => {
    // cleanup whenever tab changes
    return () => {
      setSubCategoryCategories([]);
    };
  }, [props.subCatId]);

  useEffect(() => {
    // show the first item content when tab loads
    if (subCategories.data) {
      clicked(subCategories.data[0].id);
    }
  }, [subCategories]);

  const checkHidden = () => {
    if (
      subCategories &&
      subCategories.data &&
      subCategories.data[0] &&
      subCategories.data[0].name.startsWith('hidden_')
    )
      return false;
    return true;
  };

  return (
    <>
      {subCategories.loading ? (
        <Grid container justify="center">
          <CircularProgress size={25} />
        </Grid>
      ) : (
        checkHidden() && (
          <Types tabs={subCategories.data} ua={props.ua} clicked={clicked} />
        )
      )}
      {subCategoryCategories &&
        subCategoryCategories.map((item) => (
          <CourseBox
            type={item.name}
            key={item.id}
            subCatId={item.id}
            totalCourses={item.total_number_of_courses}
            totalViewdCourses={item.total_number_of_viewed_courses}
            ua={props.ua}
            userPackagesData={props.userPackagesData}
            mainCategory={props.mainCategory}
            subCategory={subCategorySelected}
          />
        ))}
    </>
  );
};

CourseMain.propTypes = {
  page: PropTypes.string.isRequired,
  subCatId: PropTypes.number.isRequired,
  ua: PropTypes.object.isRequired,
  userPackagesData: PropTypes.array,
};

export default CourseMain;
