import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {
  fetchPracticeProgressAction,
  fetchStudyPlanAction,
} from 'core/lib/adapters/redux/actions';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import Table from './TrainingTable';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  mobileRoot: {
    // padding: '20px 0px',
    width: '100%',
    // overflowX: 'auto',
  },

  skillContainerMobile: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
  skillBox: {
    padding: '20px 15px',
    border: '1px solid #E5E5E5',
    marginRight: 15,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
  },

  suggestedText: {
    color: '#808080',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 20,
  },
}));

const Training = ({
  useragent = null,
  isInDashboard = false,
  week,
  skill = 0,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeSkill, setActiveSkill] = useState(0);
  const [modifiedData, setModifiedData] = useState([]);
  const practiceProgress =
    useSelector((state) => state.analytic.practiceProgress) || {};
  const categorized = useSelector((state) => state.questions.categorized) || {};
  const studyPlan = useSelector((state) => state.studyPlan.studyPlan) || {};
  const recommendedPlan = studyPlan?.Recommended;
  const targetWeek = studyPlan?.targets?.week;

  const mapped = {
    'Read Loud': 'ReadAloud',
    'Describe Image': 'DescribeImage',
    'Repeat Sentence': 'RepeatSentence',
    'Re-tell Lecture': 'RetellLecture',
    'Answer short question': 'AnswerShortQuestion',
    'Sumarize writen text': 'SummarizeWrittenText',
    'Write essay': 'WriteEssay',
    'R: Multiple-choice, choose single answer':
      'ReadingMultipleChoiceSingleAnswer',
    'R: Multiple-choice, choose multiple answer':
      'ReadingMultipleChoiceMultipleAnswer',
    'Re-order paragraphs': 'ReorderParagraph',
    'Reading : Fill in the blanks': 'ReadingFillInTheBlanks',
    'Reading & writing : Fill in the blanks':
      'ReadingAndWritingFillInTheBlanks',
    'Sumarize spoken text': 'SummarizeSpokenText',
    'L: Multiple-choice, choose single answer':
      'ListeningMultipleChoiceSingleAnswer',
    'L: Multiple-choice, choose multiple answer':
      'ListeningMultipleChoiceMultipleAnswer',
    'Fill in the blanks': 'ListeningFillInTheBlanks',
    'Highlight incorrect words': 'HighlightIncorrectWords',
    'Highlight correct summary': 'HighlightCorrectSummary',
    'Select missing words': 'SelectMissingWord',
    'Write from dictation': 'WriteFromDictation',
  };

  const setTitleHandler = (type) => {
    if (categorized && type) {
      let title = '';
      Object.keys(categorized).forEach((category) => {
        Object.keys(categorized[category]).forEach((tempType) => {
          if (tempType === type) {
            title = categorized[category][tempType];
          }
        });
      });
      return title;
    }
  };

  const setSkillHandler = (type) => {
    if (categorized && type) {
      let skillTitle = '';
      Object.keys(categorized).forEach((category) => {
        Object.keys(categorized[category]).forEach((tempType) => {
          if (tempType === type) {
            skillTitle = category;
          }
        });
      });
      return skillTitle;
    }
  };

  const setAdviceValueHandler = (type) => {
    if (studyPlan && recommendedPlan && type) {
      let advice = '';
      Object.keys(recommendedPlan).forEach((key) => {
        if (mapped[recommendedPlan[key].qt] === type) {
          advice = ((recommendedPlan[key].a / targetWeek) * week).toFixed(0);
        }
      });
      return advice;
    }
  };

  const calculateCumulativeAccuracy = (type) => {
    const {weeks} = practiceProgress;
    const array = [];
    Object.keys(weeks).forEach((weekKey) => {
      if (weeks[weekKey][type].accuracy > 0)
        array.push(weeks[weekKey][type].accuracy);
    });
    const cumulativeAccuracy =
      array.length > 0
        ? parseInt((array.reduce((a, b) => a + b, 0) / array.length) * 100, 10)
        : 0;
    return cumulativeAccuracy;
  };

  useEffect(() => {
    const items = [];
    if (practiceProgress) {
      const {weeks} = practiceProgress;
      const practiced = {};
      const accuracy = {};
      if (weeks) {
        Object.keys(weeks).forEach((weekKey) => {
          Object.keys(weeks[weekKey]).forEach((key) => {
            calculateCumulativeAccuracy(key);
            if (practiced[key]) practiced[key] += weeks[weekKey][key].count;
            else practiced[key] = weeks[weekKey][key].count;
            if (accuracy[key]) accuracy[key] += weeks[weekKey][key].accuracy;
            else accuracy[key] = weeks[weekKey][key].accuracy;
            items.push({
              type: key,
              advice: setAdviceValueHandler(key),
              skill: setSkillHandler(key),
              title: setTitleHandler(key),
              practiced: practiced[key].toFixed(0),
              cumulativeAccuracy: calculateCumulativeAccuracy(key),
              accuracy: (weeks[week][key].accuracy * 100).toFixed(0),
            });
          });
        });
      }
    }
    setModifiedData(items);
  }, [practiceProgress, recommendedPlan]);

  useEffect(() => {
    dispatch(
      fetchPracticeProgressAction({
        week,
        page: 1,
        per_page: 15,
      }),
    );
    dispatch(fetchStudyPlanAction());
  }, [week]);

  return (
    <Grid className={classes.root}>
      <Grid container item xs={12}>
        <Table
          useragent={useragent}
          data={modifiedData.filter((i) => {
            if (skill === 0) return i.skill === 'Speaking';
            if (skill === 1) return i.skill === 'Writing';
            if (skill === 2) return i.skill === 'Reading';
            if (skill === 3) return i.skill === 'Listening';
            return i.skill === 'Speaking';
          })}
        />
      </Grid>
    </Grid>
  );
};

Training.propTypes = {
  useragent: PropTypes.object,
};

Training.defaultProps = {
  t: () => null,
  onQuestionChange: () => {},
  useragent: {},
  sidebar: false,
};

export default Training;
