import {useState, useEffect} from 'react';
import {makeStyles, styled} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import debounce from 'lodash.debounce';

const useStepTwoStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 'normal',
  },
  header: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#0F4C8F',
  },
  placeholder: {
    display: 'inline-block',
    width: '25px',
  },
  numberRow: {
    marginBottom: '20px',
  },
}));

const Number = styled(TextField)({
  width: '95%',
  marginBottom: 15,
  marginBottom: 20,
  '& input': {
    padding: '10px',
  },
});
function StepTwo({sendData}) {
  const s = useStepTwoStyles();

  const [studyPlanData, setStudyPlanData] = useState({});

  useEffect(() => {
    sendData(studyPlanData);
  }, [studyPlanData]);

  return (
    <>
      <h4 className={s.title}>What was your score in the previous test?</h4>
      <h4 className={s.header}>Communicative Skills</h4>
      <Grid container>
        <Grid container item xs={6} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    speaking: value,
                    speakingError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, speaking: value, speakingError: null};
                else return {speaking: value};
              });
            }}
            error={studyPlanData?.speakingError}
            helperText={studyPlanData?.speakingError}
            label="Speaking"
            type="number"
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.speaking}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={s.placeholder} />
        </Grid>
        <Grid container item xs={6} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    writing: value,
                    writingError: 'Value must be between 23 & 90',
                  };
                if (prev) return {...prev, writing: value, writingError: null};
                else return {writing: value};
              });
            }}
            error={studyPlanData?.writingError}
            helperText={studyPlanData?.writingError}
            label="Writing"
            type="number"
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.writing}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={s.placeholder} />
        </Grid>
        <Grid container item xs={6} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    reading: value,
                    readingError: 'Value must be between 23 & 90',
                  };
                if (prev) return {...prev, reading: value, readingError: null};
                else return {reading: value};
              });
            }}
            error={studyPlanData?.readingError}
            helperText={studyPlanData?.readingError}
            label="Reading"
            type="number"
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.reading}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={s.placeholder} />
        </Grid>
        <Grid container item xs={6} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    listening: value,
                    listeningError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, listening: value, listeningError: null};
                else return {listening: value};
              });
            }}
            error={studyPlanData?.listeningError}
            helperText={studyPlanData?.listeningError}
            label="Listening"
            type="number"
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.listening}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <h4 className={s.header} style={{marginTop: 30}}>
        Enabling Skills
      </h4>
      <Grid container>
        <Grid container item xs={12} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    grammar: value,
                    grammarError: 'Value must be between 23 & 90',
                  };
                if (prev) return {...prev, grammar: value, grammarError: null};
                else return {grammar: value};
              });
            }}
            error={studyPlanData?.grammarError}
            helperText={studyPlanData?.grammarError}
            label="Grammar"
            type="number"
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.grammar}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={s.placeholder} />
        </Grid>
        <Grid container item xs={12} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    fluency: value,
                    fluencyError: 'Value must be between 23 & 90',
                  };
                if (prev) return {...prev, fluency: value, fluencyError: null};
                else return {fluency: value};
              });
            }}
            error={studyPlanData?.fluencyError}
            helperText={studyPlanData?.fluencyError}
            label="Oral Fluency"
            type="number"
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.fluency}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={s.placeholder} />
        </Grid>
        <Grid container item xs={12} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    pronunciation: value,
                    pronunciationError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {
                    ...prev,
                    pronunciation: value,
                    pronunciationError: null,
                  };
                else return {pronunciation: value};
              });
            }}
            error={studyPlanData?.pronunciationError}
            helperText={studyPlanData?.pronunciationError}
            label="Pronounciation"
            type="number"
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.pronunciation}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={s.placeholder} />
        </Grid>
        <Grid container item xs={12} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    spelling: value,
                    spellingError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, spelling: value, spellingError: null};
                else return {spelling: value};
              });
            }}
            error={studyPlanData?.spellingError}
            helperText={studyPlanData?.spellingError}
            label="Spelling"
            type="number"
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.spelling}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid container item xs={12} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    vocabulary: value,
                    vocabularyError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, vocabulary: value, vocabularyError: null};
                else return {vocabulary: value};
              });
            }}
            error={studyPlanData?.vocabularyError}
            helperText={studyPlanData?.vocabularyError}
            label="Vocabulary"
            type="number"
            style={{width: "98%"}}
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.vocabulary}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={s.placeholder} />
        </Grid>
        <Grid container item xs={12} lg={4}>
          <Number
            id="standard-number"
            onChange={(e) => {
              const {value} = e.target;
              setStudyPlanData((prev) => {
                if (+value < 23 || +value > 90)
                  return {
                    ...prev,
                    discourse: value,
                    discourseError: 'Value must be between 23 & 90',
                  };
                if (prev)
                  return {...prev, discourse: value, discourseError: null};
                else return {discourse: value};
              });
            }}
            error={studyPlanData?.discourseError}
            helperText={studyPlanData?.discourseError}
            label="Written Discourse"
            type="number"
            style={{width: "98%"}}
            InputProps={{inputProps: {min: 23, max: 90}}}
            value={studyPlanData?.discourse}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default StepTwo;
