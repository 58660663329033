import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  getNotificationPostListAction,
  getBannerPostListAction,
} from 'core/lib/adapters/redux/actions';
import HomeDashboardM from 'components/Icons/gen/homeDashboardM';
import CourseDashboardM from 'components/Icons/gen/courseDashboardM';
import PracticeDashboardM from 'components/Icons/gen/practiceDashboardM';
import ResourcesDashboardM from 'components/Icons/gen/resourcesDashboardM';
import UpgradeDashboardM from 'components/Icons/gen/upgradeDashboardM';
import HomeTab from './HomeTab';
import CourseTab from './CourseTab';
import PracticeTab from './PracticeTab';
import ResourcesTab from './ResourcesTab';
import UpgradeTab from './UpgradeTab';

const useStyles = makeStyles((theme) => ({
  footerMenu: {
    position: 'fixed',
    bottom: 0,
    // height: 114,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  footerMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
    padding: '10px 2px',
  },
  iconDeactive: {
    color: '#B4C2D0',
  },
  body: {
    marginBottom: 120,
  },
}));

const DashboardM = ({ useragent }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);

  const renderTab = () => {
    switch (tabIndex) {
      case 0:
        return <HomeTab useragent={useragent} />;
      case 1:
        return <CourseTab useragent={useragent} />;
      case 2:
        return <PracticeTab useragent={useragent} />;
      case 3:
        return <ResourcesTab useragent={useragent} />;
      case 4:
        return <UpgradeTab useragent={useragent} />;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getNotificationPostListAction({ category: 41 }));
    dispatch(getBannerPostListAction({ category: 42 }));
  }, []);

  useEffect(() => {
    const handleClickLogo = (e) => {
      if (e.target.parentElement && e.target.parentElement.id === 'app-logo') {
        e.preventDefault();
        setTabIndex(0);
      }
    };

    document.addEventListener('click', handleClickLogo);

    return () => {
      document.removeEventListener('click', handleClickLogo);
    };
  }, []);

  return (
    <Grid container direction="column">
      <Grid container className={classes.body}>
        {renderTab()}
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        className={classes.footerMenu}>
        <Grid
          item
          className={classes.footerMenuItem}
          onClick={() => setTabIndex(0)}>
          <HomeDashboardM
            width={24}
            height={24}
            color={tabIndex === 0 ? '#fff' : '#B4C2D0'}
          />
          <span
            style={{
              color: tabIndex === 0 ? '#fff' : '#B4C2D0',
              fontWeight: 600,
              fontSize: '0.9em',
            }}>
            {t('Home')}
          </span>
        </Grid>
        <Grid
          item
          className={classes.footerMenuItem}
          onClick={() => setTabIndex(1)}>
          <CourseDashboardM
            width={24}
            height={24}
            color={tabIndex === 1 ? '#fff' : '#B4C2D0'}
          />
          <span
            style={{
              color: tabIndex === 1 ? '#fff' : '#B4C2D0',
              fontWeight: 600,
              fontSize: '0.9em',
            }}>
            {t('Course')}
          </span>
        </Grid>
        <Grid
          item
          className={classes.footerMenuItem}
          onClick={() => setTabIndex(2)}>
          <PracticeDashboardM
            width={24}
            height={24}
            color={tabIndex === 2 ? '#fff' : '#B4C2D0'}
          />
          <span
            style={{
              color: tabIndex === 2 ? '#fff' : '#B4C2D0',
              fontWeight: 600,
              fontSize: '0.9em',
            }}>
            {t('Practice')}
          </span>
        </Grid>
        <Grid
          item
          className={classes.footerMenuItem}
          onClick={() => setTabIndex(3)}>
          <ResourcesDashboardM
            width={24}
            height={24}
            color={tabIndex === 3 ? '#fff' : '#B4C2D0'}
          />
          <span
            style={{
              color: tabIndex === 3 ? '#fff' : '#B4C2D0',
              fontWeight: 600,
              fontSize: '0.9em',
            }}>
            {t('Resource')}
          </span>
        </Grid>
        <Grid
          item
          className={classes.footerMenuItem}
          onClick={() => setTabIndex(4)}>
          <UpgradeDashboardM
            width={24}
            height={24}
            color={tabIndex === 4 ? '#fff' : '#B4C2D0'}
          />
          <span
            style={{
              color: tabIndex === 4 ? '#fff' : '#B4C2D0',
              fontWeight: 600,
              fontSize: '0.9em',
            }}>
            {t('Upgrade')}
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardM;
