import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'


export default withStyles((theme) => ({
  root: {
    borderBottom: '1px solid rgb(232, 232, 232,0.5);',
    width: "100%",
    justifyContent:"flex-start",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "6px 6px 0px 0px", 
    height: 3,
  },
}))(Tabs)
