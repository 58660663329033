import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {useRouter} from 'next/router';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PricingList from 'components/Package/Subscriptions';

const useStyles = makeStyles((theme) => ({
  studyPlanContainer: {
    height: 'auto',
    backgroundColor: '#e5e5e5',
    [theme.breakpoints.up('lg')]: {
      paddingRight: 20,
    },
    borderTop: '2px solid #0F4C8F',
    marginTop: theme.spacing(2),
  },
  Container: {
    maxHeight: 600,
    height: 600,
    overflowY: 'auto',
    marginBottom: theme.spacing(1),
  },
  studyPlanHeader: {
    backgroundColor: '#fff',
    padding: '35px 20px',
  },
  mainBoxTitle: {
    fontWeight: 800,
    fontSize: 17,
    color: '#303030',
  },
  gotoOtherPageBox: {
    backgroundColor: '#fff',
    paddingBottom: 20,
  },
  gotoOtherPage: {
    color: '#0F4C8F',
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 600,
  },
  comparePackagesContainer: {
    backgroundColor: '#fff',
  },
}));

const UpgradeTab = ({useragent}) => {
  const {t} = useTranslation();
  const router = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();

  const redirectToNewPage = (e, href) => {
    e.preventDefault();
    router.push(href);
  };
  return (
    <Grid
      item
      xs={12}
      lg={6}
      className={`${classes.studyPlanContainer} ${classes.Container}`}>
      <Grid item xs={12} container className={classes.comparePackagesContainer}>
        <PricingList useragent={useragent} />
      </Grid>
      <Grid
        xs={12}
        container
        className={classes.gotoOtherPageBox}
        item
        justify="center"
        alignItems="center"
        onClick={(e) => redirectToNewPage(e, '/subscribe')}>
        <Typography variant="subtitle1" className={classes.gotoOtherPage}>
          View Packages
        </Typography>
        <ArrowForwardIcon style={{color: '#0F4C8F'}} />
      </Grid>
    </Grid>
  );
};
export default UpgradeTab;
