import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'

export default withStyles((theme) => ({
  root: {
    color: '#999',
    textTransform: 'none',
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 600,
    fontSize: 13,
    marginRight: theme.spacing(2),
    '&:hover': {
      // color: theme.palette.primary.main,
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      // fontWeight: theme.typography.fontWeightMedium,
      // fontSize: 18
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)
