import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {useTheme, makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckIcon from '../../../Icons/gen/Check';
import CrossIcon from '../../../Icons/gen/Cross';
import Player from '../../../Player';
import RadioCheckedIcon from '../../../Icons/gen/RadioChecked';
import RadioUnCheckedIcon from '../../../Icons/gen/RadioUnChecked';

const useStyles = makeStyles((theme) => ({
  choiceContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  choiceContainerMock: {
    marginTop: theme.spacing(4),
    display: 'flex',
    marginLeft: '5px',
  },
  questionText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginLeft: theme.spacing(2),
  },
  rightAnswer: {
    background: '#EDF0F2',
    borderRadius: 4,
    paddingRight: theme.spacing(1),
  },
  wrongAnswer: {
    background: '#FAC3C3',
    borderRadius: 4,
    paddingRight: theme.spacing(1),
  },
  playerContainer: {
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& label': {
      '& span': {
        fontSize: '18px',
        fontWeight: 400,
        color: '#353535',
      },
    },
  },
}));
const SelectMissingWord = ({
  t,
  question,
  onAnswered,
  answer,
  questionAnswer,
  submittedQuestion,
  mockAnswer,
  useragent,
  pausePlay,
  mock,
  playerStartAfter,
  showStartTimer,
  setIsStopNextQuestion,
  resultComponent
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [practiceAnswer, setPracticeAnswer] = useState(null);
  const [startTimer, setStartTimer] = useState(false);
  const [waitingForPlayer, setWaitingForPlayer] = useState(true);

  useEffect(() => {
    onAnswered(practiceAnswer);
  }, [practiceAnswer]);

  const handleSingleSelectChange = (e) => {
    if (submittedQuestion) return;
    setPracticeAnswer(e.target.value);
  };

  useEffect(() => {
    if (mockAnswer && answer && answer.length > 0) setPracticeAnswer(answer);
  }, [answer]);

  const renderSingle = () => {
    return (
      <Grid direction="column" spacing={2}>
        {question.question_data.options.map((item, index) => (
          <Grid item key={index} className={classes.optionContainer}>
            <FormControlLabel
              value={item}
              className={clsx({
                [classes.wrongAnswer]:
                  submittedQuestion &&
                  questionAnswer &&
                  questionAnswer['acceptable-answers'] &&
                  questionAnswer['acceptable-answers'].data &&
                  practiceAnswer === question.question_data.options[index] &&
                  questionAnswer['acceptable-answers'].data[0].answer !==
                    practiceAnswer,
                [classes.rightAnswer]:
                  (submittedQuestion &&
                    questionAnswer &&
                    questionAnswer['acceptable-answers'] &&
                    questionAnswer['acceptable-answers'].data &&
                    practiceAnswer === question.question_data.options[index] &&
                    questionAnswer['acceptable-answers'].data[0].answer ===
                      practiceAnswer) ||
                  (submittedQuestion &&
                    questionAnswer &&
                    questionAnswer['acceptable-answers'] &&
                    questionAnswer['acceptable-answers'].data &&
                    questionAnswer['acceptable-answers'].data[0].answer ===
                      question.question_data.options[index]),
              })}
              control={
                <Radio
                  value={item}
                  onChange={handleSingleSelectChange}
                  checked={item === practiceAnswer}
                  name={item}
                  color="primary"
                  checkedIcon={<RadioCheckedIcon />}
                  icon={<RadioUnCheckedIcon />}
                />
              }
              label={item}
            />
            {submittedQuestion &&
              questionAnswer &&
              questionAnswer['acceptable-answers'] &&
              questionAnswer['acceptable-answers'].data &&
              (questionAnswer['acceptable-answers'].data[0].answer ===
              question.question_data.options[index] ? (
                <CheckIcon
                  color={theme.palette.primary.main}
                  className={classes.icon}
                />
              ) : (
                practiceAnswer === question.question_data.options[index] && (
                  <CrossIcon color="#f00" className={classes.icon} />
                )
              ))}
          </Grid>
        ))}
      </Grid>
    );
  };
  return (
    <Grid container>
      <Grid item xs={12} className={classes.textContainer}>
        <Grid
          item
          lg={5}
          md={6}
          sm={12}
          xs={12}
          className={!mockAnswer ? classes.playerContainer : null}>
          <Player
            currentStatus={true}
            setIsStopNextQuestion={setIsStopNextQuestion}
            isAnswer={false}
            t={t}
            src={
              question.question_media
                ? Object.values(question.question_media.audio)[
                    Object.values(question.question_media.audio).length - 1
                  ]
                : null
            }
            autoplayAfter={playerStartAfter}
            onPlayStart={() => {
              setWaitingForPlayer(false);
            }}
            onPlayFinish={() => {
              setStartTimer(true);
            }}
            pausePlay={pausePlay}
            useragent={useragent}
            mock={mock}
            showStartTimer={showStartTimer}
            justifyContentPlayer={resultComponent ? 'start' : null}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={
          !mockAnswer ? classes.choiceContainer : classes.choiceContainerMock
        }>
        {renderSingle()}
      </Grid>
    </Grid>
  );
};

SelectMissingWord.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_media: PropTypes.array.isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  submittedQuestion: PropTypes.bool.isRequired,
  questionAnswer: PropTypes.shape(),
  mockAnswer: PropTypes.bool,
  useragent: PropTypes.object,
  pausePlay: PropTypes.bool,
  mock: PropTypes.bool,
};
SelectMissingWord.defaultProps = {
  answer: null,
  questionAnswer: null,
  mockAnswer: false,
  useragent: {},
  pausePlay: false,
  mock: false,
};

export default SelectMissingWord;
