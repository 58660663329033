import * as React from 'react';

function CircleInfoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none">
      <path
        d="M6.99998 13.4167C3.44165 13.4167 0.583313 10.5583 0.583313 7.00001C0.583313 3.44168 3.44165 0.583344 6.99998 0.583344C10.5583 0.583344 13.4166 3.44168 13.4166 7.00001C13.4166 10.5583 10.5583 13.4167 6.99998 13.4167ZM6.99998 1.75001C4.08331 1.75001 1.74998 4.08334 1.74998 7.00001C1.74998 9.91668 4.08331 12.25 6.99998 12.25C9.91665 12.25 12.25 9.91668 12.25 7.00001C12.25 4.08334 9.91665 1.75001 6.99998 1.75001Z"
        fill={props.color || '#0F4C8F'}
      />
      <path
        d="M6.99998 7.58334C6.64998 7.58334 6.41665 7.35001 6.41665 7.00001V4.66668C6.41665 4.31668 6.64998 4.08334 6.99998 4.08334C7.34998 4.08334 7.58331 4.31668 7.58331 4.66668V7.00001C7.58331 7.35001 7.34998 7.58334 6.99998 7.58334Z"
        fill={props.color || '#0F4C8F'}
      />
      <path
        d="M6.99998 9.91668C6.82498 9.91668 6.70831 9.85834 6.59165 9.74168C6.47498 9.62501 6.41665 9.50834 6.41665 9.33334C6.41665 9.27501 6.41665 9.15834 6.47498 9.10001C6.53331 9.04168 6.53331 8.98334 6.59165 8.92501C6.76665 8.75001 6.99998 8.69168 7.23331 8.80834C7.29165 8.80834 7.29165 8.80834 7.34998 8.86668C7.34998 8.86668 7.40831 8.92501 7.46665 8.92501C7.52498 8.98334 7.58331 9.04168 7.58331 9.10001C7.58331 9.15834 7.58331 9.27501 7.58331 9.33334C7.58331 9.39168 7.58331 9.50834 7.52498 9.56668C7.46665 9.62501 7.46665 9.68334 7.40831 9.74168C7.29165 9.85834 7.17498 9.91668 6.99998 9.91668Z"
        fill={props.color || '#0F4C8F'}
      />
    </svg>
  );
}

export default CircleInfoIcon;
