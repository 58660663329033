import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Button, } from '@material-ui/core';
import freeTestTag from "../../../assets/icons/freeTestTag.svg"
import banner from '../../../assets/icons/freeBenchLessonBanner.svg'
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    '& .slick-slide': {
        position: 'relative',
    },
    main: {
        '& .MuiBox-root': {
            position: 'relative'
        },
        margin: '0px -6px'
    },
    imagebg: {
        position: 'relative'
    },
    innerContain: {
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: '10px',
        // margin: '6px',
        borderRadius: '6px'
        // position: 'absolute !important',
        // top: '8px',
        // left: '12px',
        // width: '75%'
    },
    titleText: {
        fontSize: '18px',
        fontWeight: '600',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        }
    },
    viewAll: {
        fontSize: '14px',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
        }
    },
    skeletonSlider: {
        margin: '0 -6px',
        '& .slick-slide': {
            padding: '0 6px'
        },
    },
    bennerText: {
        [theme.breakpoints.down('md')]: {
            fontSize: '14px'
        }
    },
    bannerContain: {
        [theme.breakpoints.down('md')]: {
            fontSize: '12px'
        }
    }
}));

const BenchmarkPTELessons = ({ dashboardVideoData }) => {
    const classes = useStyles();
    const router = useRouter();

    dashboardVideoData?.forEach(function (item) {
        // Extract the src attribute using regular expression
        var srcMatches = item.content.match(/src=["'](https:\/\/www\.youtube\.com\/embed\/[^"']+)["']/);

        // Display the extracted URL
        if (srcMatches && srcMatches.length > 1) {
            var videoUrl = srcMatches[1];
            console.log('YouTube Video URL:', videoUrl);
        } else {
            console.error('Unable to extract YouTube Video URL from:', item.content);
        }
    });
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: true,
                    centerPadding: '33.33%',
                    slidesToShow: 0.5
                },
            },
        ],
    };

    return (
        <>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'space-between', marginBottom: '4px', flexWrap: 'wrap', marginTop: '24px' }}>
                <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <img src={freeTestTag} alt='free' />
                    <Typography variant="h6" className={classes.titleText}>Free Benchmark PTE Lessons</Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }}>
                    <Typography variant='subtitle2' className={classes.viewAll}
                        onClick={() =>
                            router.push('/free-pte-lessons')
                        }
                    > View All </Typography>
                    <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
                </Box>
            </Box>
            {(!dashboardVideoData || dashboardVideoData.length === 0) ? (
                <Box className={classes.skeletonSlider} >
                    <Slider {...settings}>
                        {[1, 2, 3, 4].map((index) => (
                            <Skeleton key={index} variant="rect" width="100%" height={"100px"} animation="wave" style={{ borderRadius: '6px', margin: '6px' }} />
                        ))}
                    </Slider>
                </Box>
            ) : (
                <Box className={classes.main}>
                    <Slider {...settings}>
                        {dashboardVideoData?.map((item, index) => {
                            const srcMatches = item?.content?.match(/src=["'](https:\/\/www\.youtube\.com\/embed\/[^"']+)["']/);
                            const videoUrl = srcMatches && srcMatches.length > 1 ? srcMatches[1] : '';
                            const baseYouTubeUrl = videoUrl.split('/embed')[0];
                            const videoId = videoUrl.replace(baseYouTubeUrl, '');

                            return (
                                // <Grid item xl={3} lg={3} md={3} sm={6} xs={12} key={index}>
                                <a
                                    onClick={() => {
                                        const newTab = window.open("/lessons-video?id=" + videoId, "_blank");
                                        if (newTab) {
                                            newTab.focus();
                                        }
                                    }}
                                    target="_blank">
                                    <Box style={{ position: 'relative', padding: '0 6px', cursor: 'pointer' }} key={index}>
                                        <Box className={classes.innerContain}>
                                            <Typography className={classes.bennerText} variant='body1' style={{ fontWeight: '500' }}>
                                                Write from Dication
                                            </Typography>
                                            <Typography className={classes.bannerContain} variant='subtitle2' style={{ lineHeight: 'normal', color: '#808080', fontWeight: '400', width: '80%' }} >
                                                Tips & Exam Strategies by Benchmark
                                            </Typography>
                                            <Box style={{ display: 'flex', alignItems: 'center', gap: '6px', marginTop: '6px' }}>
                                                <div style={{ cursor: 'pointer', backgroundColor: '#0F4C8F', borderRadius: '50%', width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <PlayArrowIcon style={{ fontSize: '10px', color: '#ffffff' }} />
                                                </div>
                                                <Button style={{ fontSize: '12px', height: '20px', width: 'auto', padding: '2px 8px', boxShadow: 'none' }} variant="contained">Play Lession</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </a>
                                // </Grid>
                            )
                        }
                        )}
                    </Slider>
                </Box>
            )}
        </>
    );
};

BenchmarkPTELessons.propTypes = {
    useragent: PropTypes.object,
    isInDashboard: PropTypes.bool,
};

BenchmarkPTELessons.defaultProps = {
    useragent: {},
    isInDashboard: false,
};

export default BenchmarkPTELessons;
