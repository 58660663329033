import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import SvgHelpMark from '../../Icons/gen/HelpMark';

const useStyles = makeStyles((theme) => ({
  detail: {
    color: '#808080',
    fontSize: '16px',
  },
  item: {
    width: '670px',
    borderBottom: '1px solid rgba(228, 228, 228, 0.6)',
    paddingBottom: '10px',
    marginTop: '17px',
    '@media (max-width: 800px)': {
      width: 'auto',
    },
  },
  itemTitle: {
    fontSize: '16px',
    fontWeight: 600,
    marginRight: 'auto',
    // marginLeft: '33px'
  },
  itemDetail: {
    fontSize: '15px',
    fontWeight: 600,
    color: '#0F4C8F',
  },
  iconWrapper: {
    background: '#E5E5E5',
    height: '47px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '47px',
    borderRadius: '10px',
  },
  fixFlex: {
    // '@media (max-width: 800px)': {
    //   justifyContent: 'center',
    // },
  },
}));

const PracticeQuestions = ({useragent = {}, data = [], week = 1}) => {
  const classes = useStyles();

  return data?.map((i) => (
    <div className={classes.item}>
      <Grid container justify="space-between" alignItems="center">
        <Grid
          xs={useragent?.isMobile || useragent?.isTablet ? 2 : 1}
          item
          container
          className={classes.fixFlex}>
          <div className={classes.iconWrapper}>
            <SvgHelpMark />
          </div>
        </Grid>

        {useragent?.isMobile || useragent?.isTablet ? (
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            xs={10}
            justifyContent="flex-start">
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-start"
              className={`${classes.itemTitle}`}>
              {i.qt}
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-start"
              className={`${classes.itemDetail}`}>
              {Math.ceil(i.a / week)} questions / week
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={5}
              className={`${classes.itemTitle}`}>
              {i.qt}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={5}
              className={`${classes.itemDetail}`}>
              {Math.ceil(i.a / week)} questions / week
            </Grid>
          </>
        )}
      </Grid>
    </div>
  ));
};

PracticeQuestions.propTypes = {};

export default PracticeQuestions;
