import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Typography } from '@material-ui/core';
const useStyle = makeStyles((theme) => ({
    footerBox: {
        position: 'relative',
        bottom: '0',
        width: '-webkit-fill-available',
        backgroundColor: '#E7EEF4',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '12px',
        padding: '12px 24px',
        flexDirection: 'row',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
        }
    },
    footerLinkBox: {
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        }
    }
}));

const Footer = () => {
    const classes = useStyle();

    return (
        <>
            {/* <Box style={{ width: '-webkit-fill-available', position: 'absolute', bottom: 0 }}> */}
            <Box className={classes.footerBox} >
                <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                    2024 Benchmark. All Rights Reserved
                </Typography>
                <Box className={classes.footerLinkBox}  >
                    <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                        Privacy Policy
                    </Typography>
                    <Divider orientation="vertical" style={{ height: '15px' }} />
                    <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
                        Terms and Services
                    </Typography>
                </Box>
            </Box>
            {/* </Box> */}
        </>
    )
}

export default Footer