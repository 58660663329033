import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {useRouter} from 'next/router';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SvgHelpMark from '../../Icons/gen/HelpMark';
import SvgPlayCircle from '../../Icons/gen/PlayCircle';

const useStyles = makeStyles((theme) => ({
  detail: {
    color: '#808080',
    fontSize: '16px',
  },
  item: {
    borderBottom: '1px solid rgba(228, 228, 228, 0.6)',
    paddingBottom: '10px',
    marginTop: '17px',
    width: '100%',
  },
  iconBox: {
    // width: '16%',
    width: '45px',
    marginRight: 5,
  },
  titleBox: {
    // width: '50%',
    width: 'auto',
  },
  mainTitle: {
    fontSize: '1em',
    fontWeight: 600,
  },
  subTitle: {
    fontSize: '0.9em',
    fontWeight: 500,
  },
  btnBox: {
    width: '30%',
    // width: '95px',
  },
  bothContainer: {
    width: '70%',
  },
  iconWrapper: {
    background: '#E5E5E5',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    padding: 10,
  },
  upgradeBtn: {
    textTransform: 'none',
    fontWeight: 600,
    color: '#808080',
    boxShadow: 'none',
    width: 94,
    height: 40,
  },
  viewBtn: {
    textTransform: 'none',
    fontWeight: 600,
    color: '#0F4C8F',
    backgroundColor: 'rgba(15, 76, 143, 0.15)',
    boxShadow: 'none',
    width: 94,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(15, 76, 143, 0.15)',
    },
    '&$selected': {
      backgroundColor: 'rgba(15, 76, 143, 0.15)',
    },
    '&:focus': {
      backgroundColor: 'rgba(15, 76, 143, 0.15)',
    },
  },
  highBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FF3C3C',
    fontSize: '12px',
    background: 'rgba(117, 117, 117, 0.05)',
    padding: '2px 8px',
    borderRadius: '5px',
    fontWeight: 600,
  },
  mediumBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#B6A82D',
    fontSize: '12px !important',
    background: 'rgba(117, 117, 117, 0.05)',
    padding: '2px 8px',
    borderRadius: '5px',
    fontWeight: 600,
  },
  lowBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0F4C8F',
    fontSize: '12px',
    background: 'rgba(117, 117, 117, 0.05)',
    padding: '2px 8px',
    borderRadius: '5px',
    fontWeight: 600,
  },
}));

const mapped = {
  'Read Loud': 'read-aloud',
  'Describes Images': 'describe-image',
  'Repeat Sentence': 'repeat-sentence',
  'Re-tell Lecture': 'retell-lecture',
  'Answer short question': 'answer-short-question',
  'Sumarize writen text': 'summarize-written-text',
  'Write essay': 'write-essay',
  'R: Multiple-choice, choose single answer':
    'reading-multiple-choice-single-answer',
  'R: Multiple-choice, choose multiple answer':
    'reading-multiple-choice-multiple-answer',
  'Re-order paragraphs': 'reorder-paragraph',
  'Reading : Fill in the blanks': 'reading-fill-in-the-blanks',
  'Reading & writing : Fill in the blanks':
    'reading-and-writing-fill-in-the-blanks',
  'Sumarize spoken text': 'summarize-spoken-text',
  'L: Multiple-choice, choose single answer':
    'listening-multiple-choice-single-answer',
  'L: Multiple-choice, choose multiple answer':
    'listening-multiple-choice-multiple-answer',
  'Fill in the blanks': 'listening-fill-in-the-blanks',
  'Highlight incorrect words': 'highlight-incorrect-words',
  'Highlight correct summary': 'highlight-correct-summary',
  'Select missing words': 'select-missing-word',
  'Write from dictation': 'write-from-dictation',
};

const Skill = ({data}) => {
  const classes = useStyles();
  const router = useRouter();
  const setPriorityClass = (priority) => {
    switch (priority) {
      case 'High':
        return classes.highBadge;
      case 'Medium':
        return classes.mediumBadge;
      case 'Low':
        return classes.lowBadge;
      default:
        return classes.mediumBadge;
    }
  };

  const formatTitle = (title) => {
    if (!mapped[title]) return title;
    return mapped[title]
      .replace(/-/g, ' ')
      .split(' ')
      .map((item) => item[0].toUpperCase() + item.slice(1))
      .join(' ');
  };

  return (
    <div style={{width: '100%'}}>
      <div className={classes.item}>
        <Grid container justify="space-between" alignItems="center" xs={12}>
          <Grid
            container
            item
            justify="flex-start"
            alignItems="center"
            className={classes.bothContainer}>
            <Grid
              item
              sm={2}
              xs={3}
              container
              justify="flex-start"
              alignItems="center"
              className={classes.iconBox}>
              <div className={classes.iconWrapper}>
                <SvgPlayCircle />
              </div>
            </Grid>
            <Grid
              item
              sm={9}
              xs={9}
              container
              // justify={`${useragent?.isMobile ? 'center' : 'flex-start'}`}
              alignItems="flex-start"
              direction="column"
              className={`${classes.titleBox}`}>
              <Grid container className={classes.mainTitle}>
                {data && data.Title}
                <div className={data && setPriorityClass(data.Priority)}>
                  {data && data.Priority}
                  </div>
              </Grid>
              <span className={classes.subTitle}>
                {data && formatTitle(data['Question Type'])}
              </span>
            </Grid>
          </Grid>
          <Grid
            sm={2}
            xs={1}
            item
            container
            justify="flex-end"
            alignItems="center"
            className={`${classes.btnBox}`}>
            <Button
              onClick={() =>
                router.push({
                  pathname: '/pte-course',
                  query: {
                    type: mapped[data['Question Type']],
                  },
                })
              }
              variant="contained"
              className={classes.upgradeBtn}
              elevation={0}>
              {data && data.Access}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Skill.propTypes = {};

export default Skill;
