import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import CourseHoc from '../ModalComponents/CourseHoc';
import ChooseQuestionTypeHoc from '../ModalComponents/ChooseQuestionTypeHoc';

function SingleQuestionModal(props) {
  const {t} = useTranslation();

  const question = props && props.question && props.question[0];

  return (
    <CourseHoc
      t={t}
      title={question?.question_name}
      hint={question?.description}
      rightTitle={`${
        props.ua.isMobile || props.ua.isTablet
          // ? `P${question?.id}`
          ? `P${props.indexCourse}`
          // : `Practice Question ${question?.id}`
          : `Practice Question ${props.indexCourse}`
      }  `}
      type={question?.category}
      useragent={props.ua}
      navType="singleQuestion">
      <ChooseQuestionTypeHoc
        navigatorType="singleQuestion"
        question={question}
        id={question?.id}
        useragent={props.ua}
        onExitNav={props.onExit}
      />
    </CourseHoc>
  );
}

SingleQuestionModal.propTypes = {
  question: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired,
  ua: PropTypes.object.isRequired,
};

export default SingleQuestionModal;
