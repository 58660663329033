import * as React from 'react'

function SvgBell(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.8 18A51.736 51.736 0 0012 19a51.74 51.74 0 0010.2-1.04 1 1 0 00.78-.79 1 1 0 00-.43-1A5.75 5.75 0 0120 11.39V10a8 8 0 10-16 0v1.39a5.75 5.75 0 01-2.57 4.8 1 1 0 00-.43 1 1 1 0 00.8.81zm18.02-1.62a50 50 0 01-15.64 0A7.73 7.73 0 006 11.39V10a6 6 0 1112 0v1.38a7.73 7.73 0 001.82 5z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 21a3 3 0 106 0 3 3 0 00-6 0zm2 0a1 1 0 112 0 1 1 0 01-2 0z"
        fill={props.color}
      />
      <path
        d="M12 4a1 1 0 01-1-1V1a1 1 0 112 0v2a1 1 0 01-1 1z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgBell
