import * as React from 'react'

function SvgListening(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a8 8 0 00-8 8v6a1 1 0 11-2 0v-6a10 10 0 0120 0v6a1 1 0 11-2 0v-6a8 8 0 00-8-8z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12a1 1 0 011-1h3a3 3 0 013 3v3a3 3 0 01-3 3H3a3 3 0 01-3-3v-5zm2 1v4a1 1 0 001 1h1a1 1 0 001-1v-3a1 1 0 00-1-1H2zm14 0a1 1 0 00-1 1v3a1 1 0 001 1h1a1 1 0 001-1v-4h-2zm-2.121-1.121A3 3 0 0116 11h3a1 1 0 011 1v5a3 3 0 01-3 3h-1a3 3 0 01-3-3v-3a3 3 0 01.879-2.121z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgListening
