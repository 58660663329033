import * as React from 'react'

function SvgTelegram(props) {
  return (
    <svg width="1em" height="1em" fill="currentColor" {...props}>
      <g fill="#A2A2A3">
        <path d="M30.667 12.167c-.222 1.111-.5 2.222-.722 3.333-.889 4.333-1.778 8.722-2.722 13.056-.222 1.111-1.056 1.389-1.944.722-1.5-1.111-3.056-2.222-4.556-3.333-.111-.111-.278-.167-.389-.278-.111.111-.222.167-.333.278-.778.722-1.5 1.444-2.278 2.167-.778.723-1.333.556-1.611-.389-.611-1.833-1.222-3.611-1.833-5.444a.8.8 0 00-.556-.556c-1.444-.444-2.944-.889-4.389-1.333-.389-.111-.722-.278-.778-.778 0-.5.333-.667.722-.833 6.722-2.5 13.5-5 20.222-7.556.611-.222.778-.167 1.222.333-.043.2-.062.406-.055.611zM16.889 26.389c.111-.944.222-1.778.278-2.611a.979.979 0 01.444-.833c2.944-2.667 5.889-5.278 8.833-7.944.107-.15.182-.32.222-.5a1.89 1.89 0 00-.556.056l-10.5 6.667c-.222.167-.278.278-.167.5.444 1.444.889 2.833 1.278 4.278a.739.739 0 00.169.387h-.001z" />
        <path d="M20 40A19.988 19.988 0 1120.025.024 19.988 19.988 0 0120 40zm0-38.72C9.661 1.28 1.28 9.661 1.28 20c0 10.339 8.381 18.72 18.72 18.72 10.339 0 18.72-8.381 18.72-18.72C38.72 9.661 30.339 1.28 20 1.28z" />
      </g>
    </svg>
  )
}

export default SvgTelegram
