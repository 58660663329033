import * as React from 'react'

function SvgPlusCircle(props) {
  return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9999 4.00004C9.3725 4.00004 3.99992 9.37262 3.99992 16C3.99992 22.6275 9.3725 28 15.9999 28C22.6273 28 27.9999 22.6275 27.9999 16C27.9999 9.37262 22.6273 4.00004 15.9999 4.00004ZM1.33325 16C1.33325 7.89986 7.89974 1.33337 15.9999 1.33337C24.1001 1.33337 30.6666 7.89986 30.6666 16C30.6666 24.1002 24.1001 30.6667 15.9999 30.6667C7.89974 30.6667 1.33325 24.1002 1.33325 16Z" fill="#0F4C8F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.0001 9.33337C16.7365 9.33337 17.3334 9.93033 17.3334 10.6667V21.3334C17.3334 22.0698 16.7365 22.6667 16.0001 22.6667C15.2637 22.6667 14.6667 22.0698 14.6667 21.3334V10.6667C14.6667 9.93033 15.2637 9.33337 16.0001 9.33337Z" fill="#0F4C8F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.33325 16C9.33325 15.2636 9.93021 14.6666 10.6666 14.6666H21.3333C22.0696 14.6666 22.6666 15.2636 22.6666 16C22.6666 16.7363 22.0696 17.3333 21.3333 17.3333H10.6666C9.93021 17.3333 9.33325 16.7363 9.33325 16Z" fill="#0F4C8F"/>
        </svg>
  )
}

export default SvgPlusCircle
