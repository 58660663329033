import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Recorder from '../../../Recorder';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    '& p': {
      lineHeight: 1.5,
      fontSize: 18,
      fontWeight: 400,
      color: '#353535',
    },
  },
  textContainerMobile: {
    '& p': {
      fontSize: 18,
      // lineHeight: '22px',
    },
  },
  hintTypography: {
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    }
  },
}));

const ReadAloud = ({ t, question, onSaveFinish, answer, mockAnswer, useragent, mock, onRecordStart, startIn, isStopNextQuestion, setIsStopNextQuestion, isRecordingStop, timeTaken, setNextBtnrecordingStop }) => {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item lg={4} md={6} sm={12} xs={12} className={classes.recordContainer}>
        {!mockAnswer ? (
          <Recorder
            question={question}
            isRecordingStop={isRecordingStop}
            setIsStopNextQuestion={setIsStopNextQuestion}
            isStopNextQuestion={isStopNextQuestion}
            startIn={startIn || 40}
            endIn={40}
            onSaveFinish={onSaveFinish}
            answer={answer}
            t={t}
            useragent={useragent}
            mock={mock}
            onRecordStart={() => { onRecordStart() }}
            playBeepSound
            setNextBtnrecordingStop={setNextBtnrecordingStop}
          />
        ) : null}
      </Grid>
      <Grid item xs={12} className={`${classes.textContainer} ${useragent.isMobile ? classes.textContainerMobile : ''}`}>
        <Typography className={classes.hintTypography}>{question?.question_data?.text}</Typography>
      </Grid>
    </Grid>
  );
};

ReadAloud.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.string,
  mockAnswer: PropTypes.bool,
  useragent: PropTypes.object,
  mock: PropTypes.bool,
  onRecordStart: PropTypes.func,

  setNextBtnrecordingStop: PropTypes.func,
};
ReadAloud.defaultProps = {
  answer: null,
  mockAnswer: false,
  useragent: {},
  mock: false,
  onRecordStart: () => { },
};

export default ReadAloud;
