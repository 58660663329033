import * as React from 'react'

function SvgMinusCircle(props) {
  return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9999 3.99999C9.3725 3.99999 3.99992 9.37258 3.99992 16C3.99992 22.6274 9.3725 28 15.9999 28C22.6273 28 27.9999 22.6274 27.9999 16C27.9999 9.37258 22.6273 3.99999 15.9999 3.99999ZM1.33325 16C1.33325 7.89982 7.89974 1.33333 15.9999 1.33333C24.1001 1.33333 30.6666 7.89982 30.6666 16C30.6666 24.1002 24.1001 30.6667 15.9999 30.6667C7.89974 30.6667 1.33325 24.1002 1.33325 16Z" fill="#0F4C8F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.33325 16C9.33325 15.2636 9.93021 14.6667 10.6666 14.6667H21.3333C22.0696 14.6667 22.6666 15.2636 22.6666 16C22.6666 16.7364 22.0696 17.3333 21.3333 17.3333H10.6666C9.93021 17.3333 9.33325 16.7364 9.33325 16Z" fill="#0F4C8F"/>
        </svg>

  )
}

export default SvgMinusCircle
