import React from 'react';
import { useRouter } from 'next/router';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import roboIcon from '../../assets/icons/AIRobo.svg'
const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 99,
        transitionDelay: '1000ms',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    bgBox: {
        backgroundColor: "#ffffff",
        borderRadius: "160px 160px 20px 20px",
        minWidth: '300px',
        maxWidth: '320px',
        padding: "24px",
        textAlign: "center",
    },
    goAnBtn: {
        // backgroundColor: '#0F4C8F',
        // color: '#FFFFFF',
        // borderRadius: '50px',
        width: '100%',
        // padding: '8px',
        // textTransform: 'uppercase',
        // fontWeight: 600,
        marginTop: '30px'
    }
}));

export default function AIAnalysisRobo(props) {
    const { setaiAnalysis } = props
    const router = useRouter();

    const classes = useStyles();
    const [checked, setChecked] = React.useState(true);
    // const [open, setOpen] = useState(true);

    // const handleClose = () => {
    //   setOpen(false);
    // };
    const handleChange = () => {
        setChecked((prev) => prev);
    };

    return (

        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Slide direction="up" in={checked} mountOnEnter unmountOnExit timeout={{ enter: 1000 }}  >
                    {/* <Paper elevation={4} className={classes.paper}> */}
                    <Box className={classes.bgBox}>
                        <Box onClick={() => {
                            localStorage?.setItem("isLogin", true)

                            setaiAnalysis(false)
                        }} style={{ cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'center', backgroundColor: '#fff', borderRadius: '50%', position: 'absolute', right: '0', top: "-10%", width: "34px", height: '34px' }}>
                            <CloseIcon style={{ fontSize: '18px' }} />
                        </Box>
                        <img src={roboIcon} width={'100%'} height={'auto'} style={{ marginTop: '-30%' }} />
                        <Box>
                            <Typography variant='h5' style={{ color: '#0F4C8F', fontWeight: '600', marginTop: '20px' }} >
                                AI Analysis
                            </Typography>
                            <Typography variant='subtitle1'>
                                Understand what your score reports tell you about your weakness and strengths
                            </Typography>
                            <Button className={classes.goAnBtn} variant="contained" onClick={() => {
                                localStorage?.setItem("isLogin", true)
                                router.push('/analytics')
                            }}> Go to Analysis </Button>
                        </Box>
                    </Box>
                </Slide>
            </div>
        </div>
    );
}