import * as React from 'react'

function SvgDownload(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M11.29 17.71c.095.091.207.162.33.21a.94.94 0 00.76 0 1 1 0 00.33-.21l3-3a1.004 1.004 0 10-1.42-1.42L13 14.59V2a1 1 0 10-2 0v12.59l-1.29-1.3a1.004 1.004 0 00-1.42 1.42l3 3z"
        fill={props.color}
      />
      <path
        d="M23 13a1 1 0 00-1 1v6a1 1 0 01-1 1H3a1 1 0 01-1-1v-6a1 1 0 10-2 0v6a3 3 0 003 3h18a3 3 0 003-3v-6a1 1 0 00-1-1z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgDownload
