import React, {useState, useEffect} from 'react';
import {withUserAgent} from 'next-useragent';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {
  getCategoriesAction,
  getUserPackageIndexAction,
} from 'core/lib/adapters/redux/actions';
import CourseMain from 'components/PTCourse/CourseMain';
import DashboardHoc from 'components/DashboardHoc';
import SvgBookIcon from 'components/Icons/gen/Book';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '37px 58px',
    borderTop: '3px solid #0F4C8F',
    borderRadius: 0,
  },
  rootMobile: {
    padding: 0,
    borderRadius: 0,
    width:"100%"
  },
  mockHeader: {
    marginBottom: '57px',
  },
  mockHeaderMobile: {
    padding: 15,
  },
  header: {
    fontWeight: '700',
    fontSize: '18px',
    fontStyle: 'normal',
  },
  mockIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: '#EDF0F2',
    padding: theme.spacing(0.7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  },
  tabItem: {
    textDecoration: 'none',
    color: '#808080',
    fontSize: '16px',
    cursor: 'pointer',
    paddingRight: 25,
  },
  tabItemMobile: {
    fontSize: 14,
    fontWeight: 500,
  },
  activeTabItem: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    textUnderlineOffset: '5px',
    textDecoration: 'underline solid #0F4C8F 4px',
  },
  itemsContainer: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
    paddingBottom: '5px',
    scrollbarWidth: 'thin',
  },
  itemsContainerMobile: {
    padding: 15,
  },
}));

const CourseTab = ({useragent}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [pageName, setPageName] = useState(null);
  const [pageId, setPageId] = useState(null);
  const userPackages = useSelector((state) => state.package.userPackages) || {};
  const userPackagesData = userPackages?.data;

  const setPageData = (name, id) => {
    setPageName(name);
    setPageId(id);
  };

  const [categories, setCatergories] = useState([]);
  const fetchedCategories =
    useSelector((state) => state.course.categories) || [];
  useEffect(() => {
    if (fetchedCategories.data) {
      // setCatergories(fetchedCategories.data.filter(item => item.parent_id === null))
      setCatergories(fetchedCategories.data);
      // setting first category to load
      const filtered = fetchedCategories.data;
      setPageData(filtered[0].name, filtered[0].id);
    }
  }, [fetchedCategories]);

  const checkActiveClass = (name) => {
    if (pageName === name) {
      return classes.activeTabItem;
    }
  };

  useEffect(() => {
    dispatch(getCategoriesAction());
    dispatch(getUserPackageIndexAction({page: 1, per_page: 100}));
  }, []);

  return (
    <Paper className={useragent.isDesktop ? classes.root : classes.rootMobile}>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        className={
          useragent.isDesktop ? classes.mockHeader : classes.mockHeaderMobile
        }>
        <Avatar className={classes.mockIcon}>
          <SvgBookIcon color={theme.palette.primary.main} />
        </Avatar>
        <Typography
          variant="h5"
          display="inline"
          color="primary"
          className={classes.header}>
          PTE Course
        </Typography>
      </Grid>
      <Grid
        container
        className={`${classes.itemsContainer} ${
          !useragent.isDesktop ? classes.itemsContainerMobile : ''
        }`}>
        {categories.map((cat) => (
          <span
            key={cat.id}
            onClick={() => setPageData(cat.name, cat.id)}
            className={`${classes.tabItem} ${
              !useragent.isDesktop ? classes.tabItemMobile : ''
            } ${checkActiveClass(cat.name)}`}>
            {cat.name}
          </span>
        ))}
      </Grid>
      {pageId && (
        <CourseMain
          page={pageName}
          subCatId={pageId}
          ua={useragent}
          userPackagesData={userPackagesData}
          mainCategory={pageName}
        />
      )}
    </Paper>
  );
};

export default CourseTab;
